/* 12px */
import Remove from './12px/Remove.svg';
import Tick from './12px/Tick.svg';
/* Actions */
import AddToMyList from './Actions/AddToMyList.svg';
import OnMyList from './Actions/OnMyList.svg';
import BookTickets from './Actions/BookTickets.svg';
import ChooseDates from './Actions/ChooseDates.svg';
import EditMyList from './Actions/EditMyList.svg';
import Favourite from './Actions/Favourite.svg';
import FavouriteFull from './Actions/FavouriteFull.svg';
import Filter from './Actions/Filter.svg';
import FindAScreening from './Actions/FindAScreening.svg';
import Reminder from './Actions/Reminder.svg';
import Subscribe from './Actions/Subscribe.svg';
import Watch from './Actions/Watch.svg';
/* Arrows */
import Arrow from './Arrows/Arrow.svg';
import CarouselArrow from './Arrows/CarouselArrow.svg';
import DropdownArrow from './Arrows/DropdownArrow.svg';
/* Descriptors */
import Cinema from './Descriptors/Cinema.svg';
import Clock from './Descriptors/Clock.svg';
import CreditCard from './Descriptors/CreditCard.svg';
import Email from './Descriptors/Email.svg';
import Info from './Descriptors/Info.svg';
import List from './Descriptors/List.svg';
import Location from './Descriptors/Location.svg';
import PDF from './Descriptors/PDF.svg';
import Phone from './Descriptors/Phone.svg';
import Print from './Descriptors/Print.svg';
import Secure from './Descriptors/Secure.svg';
import Tickets from './Descriptors/Tickets.svg';
/* Navigation */
import BalletAndDance from './Navigation/BalletAndDance.svg';
import Basket from './Navigation/Basket.svg';
import BasketFull from './Navigation/BasketFull.svg';
import Home from './Navigation/Home.svg';
import LiveStream from './Navigation/LiveStream.svg';
import Menu from './Navigation/Menu.svg';
import OperaAndMusic from './Navigation/OperaAndMusic.svg';
import Search from './Navigation/Search.svg';
import Settings from './Navigation/Settings.svg';
import User from './Navigation/User.svg';
import UserSignedIn from './Navigation/UserSignedIn.svg';
/* Social */
import Facebook from './Logos/Social/Facebook.svg';
import Instagram from './Logos/Social/Instagram.svg';
import Tiktok from './Logos/Social/Tiktok.svg';
import Twitter from './Logos/Social/Twitter.svg';
import Youtube from './Logos/Social/Youtube.svg';
/* Text */
import Delete from './Text/Delete.svg';
import Shift from './Text/Shift.svg';
import Space from './Text/Space.svg';
/* Utility */
import Close from './Utility/Close.svg';
import Confirm from './Utility/Confirm.svg';
import Detract from './Utility/Detract.svg';
import Expand from './Utility/Expand.svg';
import ExternalLink from './Utility/ExternalLink.svg';
import GridView from './Utility/GridView.svg';
import ListView from './Utility/ListView.svg';
import Refresh from './Utility/Refresh.svg';
import Star from './Utility/Star.svg';
/* Video */
import AudioDescription from './Video/AudioDescription.svg';
import Back10 from './Video/Back10.svg';
import Clips from './Video/Clips.svg';
import Forward10 from './Video/Forward10.svg';
import FullScreen from './Video/FullScreen.svg';
import Minimise from './Video/Minimise.svg';
import Muted from './Video/Muted.svg';
import Pause from './Video/Pause.svg';
import Play from './Video/Play.svg';
import Restart from './Video/Restart.svg';
import Subtitles from './Video/Subtitles.svg';
import Trailer from './Video/Trailer.svg';
import Volume from './Video/Volume.svg';

export const IconLibrary = {
  AddToMyList,
  Arrow,
  AudioDescription,
  Back10,
  BalletAndDance,
  Basket,
  BasketFull,
  BookTickets,
  CarouselArrow,
  ChooseDates,
  Cinema,
  Clips,
  Clock,
  Close,
  Confirm,
  CreditCard,
  Delete,
  Detract,
  DropdownArrow,
  EditMyList,
  Email,
  Expand,
  ExternalLink,
  Facebook,
  Favourite,
  FavouriteFull,
  Filter,
  FindAScreening,
  Forward10,
  FullScreen,
  GridView,
  Home,
  Info,
  Instagram,
  List,
  ListView,
  LiveStream,
  Location,
  Menu,
  Minimise,
  Muted,
  OnMyList,
  OperaAndMusic,
  Pause,
  PDF,
  Phone,
  Play,
  Print,
  Refresh,
  Reminder,
  Remove,
  Restart,
  Search,
  Secure,
  Settings,
  Shift,
  Space,
  Star,
  Subscribe,
  Subtitles,
  Tick,
  Tickets,
  Tiktok,
  Trailer,
  Twitter,
  User,
  UserSignedIn,
  Volume,
  Watch,
  Youtube,
};
