import styled from 'styled-components';
import { ITertiaryButtonProps } from '../../../../types/buttonTypes';

const TertiaryButtonWrapper = styled.a<ITertiaryButtonProps>`
  padding: 0;
  height: var(--button-height);
  font-family: var(--font-family-buttons);
  font-size: var(--button-font-size);
  font-weight: var(--button-font-weight);
  color: var(--base-color-${({ color }) => color});
  width: fit-content;
  background: none;
  border-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-decoration: none;
  box-sizing: border-box;
`;

const TertiaryIconWrapper = styled.span`
  width: var(--button-icon-width);
  min-width: 20px;
  height: var(--button-icon-height);
  margin-left: var(--button-icon-margin);
  display: inline-block;
`;

export { TertiaryIconWrapper, TertiaryButtonWrapper };
