import styled from 'styled-components';
import { BackgroundColour, CtaVariant } from '../../../types/information';
import { Grid } from '../../atoms/Grid';

export const InfoContent = styled('div')`
  font-family: var(--font-family-altHeader);
  font-feature-settings: var(--font-feature-settings-altHeader);
  font-size: var(--font-size-altHeader-6);
  font-weight: var(--font-weight-altHeader-6);
  -webkit-letter-spacing: var(--letter-spacing-altHeader-6);
  -moz-letter-spacing: var(--letter-spacing-altHeader-6);
  -ms-letter-spacing: var(--letter-spacing-altHeader-6);
  letter-spacing: var(--letter-spacing-altHeader-6);
  line-height: var(--line-height-altHeader-6);
  word-break: var(--word-break-altHeader);
`;

export const InfoTitle = styled('div')`
  * {
    margin-block: 0;
  }
`;

export const InfoCTAWrapper = styled('div')<{ variant: CtaVariant; infoThemed: boolean }>`
  margin: 32px 0;

  ${(props) => {
    if (!props.infoThemed) {
      return;
    } else {
      if (props.variant === CtaVariant.Primary || props.variant === CtaVariant.Secondary) {
        return `a {
              color: var(--base-color-black);
              background: var(--base-color-white);
              border-color: var(--base-color-transparent);
            }`;
      } else {
        return `a {
              color: var(--base-color-white);
              background: var(--base-color-transparent});
            }
              svg > path {
                fill: var(--base-color-white);
                stroke: var(--base-color-white);
                stroke-width: 1;
              }
            `;
      }
    }
  }}}
`;

export const InfoWrapper = styled(Grid)<{ background: BackgroundColour }>`
  padding: 60px 0;
  color: ${(props) =>
    props.background !== BackgroundColour.White ? 'var(--base-color-white)' : 'var(--base-color-black)'};
  background: ${(props) => `var(--base-color-${props.background})`}
  }};
`;
