import * as React from 'react';
import { IconSVGProps } from '../../../../../types/iconTypes';

export default ({ color = '#1A1A1A' }: IconSVGProps): React.ReactElement => (
  <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.5 14H5.5C5.2 14 5 14.2 5 14.5C5 14.8 5.2 15 5.5 15H10.5C10.8 15 11 14.8 11 14.5C11 14.2 10.8 14 10.5 14Z"
      fill={color}
    />
    <path
      d="M8 16H5.5C5.2 16 5 16.2 5 16.5C5 16.8 5.2 17 5.5 17H8C8.3 17 8.5 16.8 8.5 16.5C8.5 16.2 8.3 16 8 16Z"
      fill={color}
    />
    <path
      d="M20 4H4C2.9 4 2 4.9 2 6V18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6C22 4.9 21.1 4 20 4ZM4 5H20C20.6 5 21 5.4 21 6V8H3V6C3 5.4 3.4 5 4 5ZM21 9V10.5H3V9H21ZM20 19H4C3.4 19 3 18.6 3 18V11.5H21V18C21 18.6 20.6 19 20 19Z"
      fill={color}
    />
  </svg>
);
