import { DirectionType, IconNameType } from './iconTypes';

export enum CtaVariant {
  Primary = 'Primary',
  Secondary = 'Secondary',
  Tertiary = 'Tertiary',
  TextLink = 'TextLink',
}

export enum CtaTheme {
  Cinema = 'Cinema',
  Core = 'Core',
  Stream = 'Stream',
}

export enum TitleVariant {
  Header = 'Header',
  AltHeader = 'AltHeader',
}

export enum BackgroundColour {
  Cinema = 'cinema',
  Core = 'core',
  Stream = 'stream',
  White = 'white',
}

export type TitleTextSize = 3 | 4 | 5 | 6;

export interface CTAProps {
  /**
   * Text to display on the CTA
   */
  text: string;
  /**
   * Link for the CTA
   */
  link: string;
  /**
   * Style of the CTA. Choices are "Primary" (solid button background), "Secondary" (transparent button background) or "Tertiary" (text only)
   */
  variant?: CtaVariant;
  /**
   * Theme to use for the CTA. Choices are "Core" (red), "Stream" (blue), or "Cinema" (black)
   */
  theme?: CtaTheme;
  /**
   * Flag to decide whether to adjust the CTA colours for display on coloured backgrounds
   */
  infoThemed: boolean;
  /**
   * Name of the icon to be used in the button
   */
  iconName?: IconNameType;
  /**
   * Direction of the icon used in the button
   */
  iconDirection?: DirectionType;
  /**
   * Color of text and icon
   */
}

export interface TitleProps {
  /**
   * Title text to display
   */
  text: string;
  /**
   * Title typography type. Choices are Heading or AltHeader
   */
  variant: TitleVariant;
  /**
   * The size of the title text
   */
  textSize: TitleTextSize;
}

export interface IInformationProps {
  /**
   * Main body text to display in the Information Component
   */
  body: string;
  /**
   * Optional CTA to display underneath the body text
   */
  cta?: CTAProps;
  /**
   * Title options for the component
   */
  title: TitleProps;
  /**
   * Background Colour. Choices: None, Core, Cinema, Stream
   */
  background?: BackgroundColour;
}
