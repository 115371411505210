import * as React from 'react';
import { IconSVGProps } from '../../../../../types/iconTypes';

export default ({ color = '#1A1A1A' }: IconSVGProps): React.ReactElement => (
  <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20.8996 3.9001L20.0996 3.1001L11.9996 11.3001L3.89961 3.1001L3.09961 3.9001L11.2996 12.0001L3.09961 20.1001L3.89961 20.9001L11.9996 12.7001L20.0996 20.9001L20.8996 20.1001L12.6996 12.0001L20.8996 3.9001Z"
      fill={color}
    />
  </svg>
);
