import React from 'react';
import { ThemeProvider } from 'styled-components';
import { themes } from './themes';
import { IThemeProviderProps } from '../types/types';

const Theme = ({ children, theme }: IThemeProviderProps) => {
  const chosenTheme = themes[theme];
  return <ThemeProvider theme={chosenTheme}>{children}</ThemeProvider>;
};

export default Theme;
