import styled from 'styled-components';
import { ITabLinkProps } from '../../../types/navigation';
import { devices } from '../../../styles/viewports';

export const TabLinkWrapper = styled.a<ITabLinkProps>`
  padding: ${({ iconName }) =>
    iconName ? 'calc(var(--text-link-underline-offset) - 4px) 0' : 'var(--text-link-underline-offset) 0'};
  font-family: var(--font-family-navigation);
  font-size: var(--font-size-navigation);
  font-weight: var(--font-weight-navigation);
  line-height: var(--line-height-navigation);
  letter-spacing: var(--letter-spacing-navigation);
  text-transform: var(--text-transform-navigation);
  color: ${({ selected, color, hoverColor }) =>
    selected ? `var(--base-color-${hoverColor})` : `var(--base-color-${color})`};
  width: fit-content;
  display: flex;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  border-bottom: ${({ selected, hoverColor }) =>
    selected ? `1px solid var(--base-color-${hoverColor})` : '1px solid transparent'};
  ${({ selected, hoverColor }) =>
    selected
      ? `&& svg path {
    fill: var(--base-color-${hoverColor});
  }`
      : ''}

  @media ${devices.desktop}, ${devices.largeDesktop} {
    &:hover {
      color: var(--base-color-${({ hoverColor }) => hoverColor});
      border-bottom: 1px solid var(--base-color-${({ hoverColor }) => hoverColor});

      && svg path {
        fill: var(--base-color-${({ hoverColor }) => hoverColor});
      }
    }
  }
`;

export const TabLinkIconWrapper = styled.span`
  width: var(--button-icon-width);
  height: var(--button-icon-height);
  margin-right: var(--button-icon-margin);
`;
