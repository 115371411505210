import styled from 'styled-components';
import { devices } from '../../../styles/viewports';

export const NavTopContainer = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 16px;
  justify-content: flex-end;
  height: 26px;

  @media ${devices.mobile} {
    column-gap: 8px;
  }
`;
