import styled from 'styled-components';
import { devices } from '../../../styles/viewports';

export const PolicyLinksList = styled.ul`
  margin: 0 auto;
  padding: 0;
  list-style: none;
  display: flex;
  gap: var(--footer-items-gap);

  @media ${devices.mobile} {
    & {
      margin: 0;
      flex-direction: column;
    }
  }
`;

export const PolicyLinkItem = styled.li`
  font-family: var(--font-family-overline);
  font-size: var(--font-size-overline-1);
  font-weight: var(--font-weight-overline-1);
  line-height: var(--line-height-overline-1);
  letter-spacing: var(--letter-spacing-overline-1);
  text-transform: var(--text-transform-overline);
  text-align: center;

  @media ${devices.mobile} {
    & {
      text-align: left;
    }
  }
`;

export const PolicyLink = styled.a`
  width: fit-content;
  text-decoration: none;
  text-transform: uppercase;
  color: var(--base-color-white);
  cursor: pointer;
`;
