import * as React from 'react';
import { IconSVGProps } from '../../../../../../types/iconTypes';

export default ({ color = '#1A1A1A' }: IconSVGProps): React.ReactElement => (
  <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.4398 18.8999L8.33978 18.8199C7.00184 18.8681 5.66237 18.7877 4.33978 18.5799C2.33978 18.1699 2.21978 16.1899 2.06978 14.5799C1.86519 12.2448 1.95253 9.89335 2.32978 7.57989C2.3868 6.99013 2.66085 6.44256 3.09878 6.04344C3.5367 5.64432 4.10727 5.4221 4.69978 5.41989C9.1314 5.15166 13.5738 5.10827 18.0098 5.28989C18.4804 5.30645 18.9488 5.36332 19.4098 5.45989C21.6798 5.84989 21.7398 8.09989 21.8798 9.99989C22.0316 11.911 21.9679 13.8331 21.6898 15.7299C21.4598 17.3099 21.0298 18.6199 19.2098 18.7299C16.9298 18.8999 14.7098 19.0299 12.4198 18.9899C12.4598 18.8999 12.4498 18.8999 12.4398 18.8999ZM10.0298 14.8999L15.1398 11.9999L9.99978 9.04989L10.0298 14.8999Z"
      fill={color}
    />
  </svg>
);
