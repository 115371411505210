import styled from 'styled-components';
import { devices } from '../../../styles/viewports';
import { IGridItemProps } from '../../../types/types';

const GridItem = styled.div<IGridItemProps>`
  grid-column: ${({ columnStartDesktop }) => columnStartDesktop} / span
    ${({ columnSpanDesktop = 1 }) => columnSpanDesktop};

  @media ${devices.mobile} {
    & {
      grid-column: ${({ columnStartDevice }) => columnStartDevice} / span
        ${({ columnSpanDevice = 1 }) => columnSpanDevice};
    }
  }

  @media ${devices.tablet} {
    & {
      grid-column: ${({ columnStartDevice }) => columnStartDevice} / span
        ${({ columnSpanDevice = 1 }) => columnSpanDevice};
    }
  }
`;

export default GridItem;
