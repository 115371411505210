import * as React from 'react';
import { IconSVGProps } from '../../../../../types/iconTypes';

export default ({ color = '#1A1A1A' }: IconSVGProps): React.ReactElement => (
  <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M21 3H3V4H21V3Z" fill={color} />
    <path d="M21 6.5H3V7.5H21V6.5Z" fill={color} />
    <path d="M21 10H3V11H21V10Z" fill={color} />
    <path d="M13.5 13.5H3V14.5H13.5V13.5Z" fill={color} />
    <path d="M11 17H3V18H11V17Z" fill={color} />
    <path
      d="M17.0004 13.6999L16.0004 14.6999L15.5004 15.1999L14.5004 16.1999L12.7004 17.9999L11.9004 20.9999L15.0004 20.0999L15.6004 19.4999L17.0004 17.9999L21.0004 13.9999L18.9004 11.8999L17.0004 13.6999ZM17.0004 16.4999L16.0004 17.4999L15.0004 18.4999L14.3004 19.1999L13.3004 19.4999L13.6004 18.4999L15.9004 16.1999L16.9004 15.1999L17.0004 15.0999L17.7004 15.7999L17.0004 16.4999ZM18.5004 14.9999L17.8004 14.2999L18.9004 13.1999L19.6004 13.8999L18.5004 14.9999Z"
      fill={color}
    />
  </svg>
);
