import * as React from 'react';
import { IconSVGProps } from '../../../../../types/iconTypes';

export default ({ color = '#1A1A1A' }: IconSVGProps): React.ReactElement => (
  <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.6 7.4H5.9L9.6 3.7L8.9 3L4 7.9L8.9 12.8L9.6 12.1L5.9 8.4H12.6C16.1 8.4 18.9 11.2 18.9 14.7C18.9 18.2 16 21 12.6 21C9.1 21 6.3 18.2 6.3 14.7H5.3C5.3 18.7 8.6 22 12.6 22C16.6 22 19.9 18.7 19.9 14.7C19.8 10.7 16.6 7.4 12.6 7.4Z"
      fill={color}
    />
  </svg>
);
