import styled from 'styled-components';

export const TypeTagsContainer = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;

  & li {
    color: var(--base-color-black);
    display: inline-block;
    margin: 0;
  }

  & li:before {
    content: '—';
    margin: 0 0.5em;
  }

  & li:first-child:before {
    display: none;
  }
`;
