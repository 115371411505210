import styled from 'styled-components';
import { ITabProps } from '../../../types/types';
import { devices } from '../../../styles/viewports';

export const TabText = styled.a<ITabProps>`
  font-size: var(--font-size-navigation);
  font-family: var(--font-family-navigation);
  font-weight: var(--font-weight-navigation);
  letter-spacing: var(--letter-spacing-navigation);
  color: var(--base-color-black);
  margin-right: 0px;
  margin-top: 5px;
  text-decoration: none;
  text-transform: uppercase;

  ${(props) => {
    if (props.withIcon === 'left') {
      return `margin-left: 5px;`;
    }

    return ``;
  }}

  @media ${devices.mobileAndTablet} {
    ${(props) => {
      if (!props.withTextInMobile) {
        return `
            display: none;
          `;
      }

      return ``;
    }}
  }
`;

export const TitleContainer = styled.div<ITabProps>`
  display: flex;
  flex-direction: row;
  width: fit-content;
  border-bottom: 1px solid transparent;

  ${(props) => {
    if (props.withIcon === 'left') {
      return `
      flex-direction: row-reverse;
      svg {
        margin-right: 6px;
      }`;
    }

    return `
      svg {
          margin-left: 6px;
      }`;
  }}

  svg {
    width: var(--navigation-large-gap);
    height: var(--navigation-large-gap);
  }

  ${({ selected, colorPrimary }) => {
    if (selected) {
      return `
        cursor: pointer;
        border-bottom: 1px solid var(--base-color-${colorPrimary});
        && a {
          color: var(--base-color-${colorPrimary});
        } 
        && svg path {
          fill: var(--base-color-${colorPrimary});
        } 
      `;
    }

    return '';
  }}

  ${(props) => {
    if (!props.withTextInMobile) {
      return `
        svg {
          margin-right: 6px;
        }
      `;
    }
    return ``;
  }}

  @media ${devices.mobileAndTablet} {
    svg {
      margin-right: 0px;
    }
  }

  :hover {
    cursor: pointer;
    border-bottom: 1px solid var(--base-color-${({ colorPrimary }) => colorPrimary});
    && a {
      color: var(--base-color-${({ colorPrimary }) => colorPrimary});
    }
    && svg path {
      fill: var(--base-color-${({ colorPrimary }) => colorPrimary});
    }
  }
`;
