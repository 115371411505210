import * as React from 'react';
import { IconSVGProps } from '../../../../../types/iconTypes';

export default ({ color = '#1A1A1A' }: IconSVGProps): React.ReactElement => (
  <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.4157 12.1133C19.4157 11.9133 19.4157 11.7133 19.4157 11.4133L22.1992 8.81328L20.1374 5.31328L16.426 6.31328C16.0137 6.11328 15.6013 5.91328 15.1889 5.71328L14.2611 2.11328H10.1374L9.20953 5.71328C8.79716 5.91328 8.38479 6.11328 8.07551 6.41328L4.36417 5.41328L2.30231 8.91328L4.98272 11.5133C4.98272 11.7133 4.98272 11.9133 4.98272 12.2133C4.98272 12.5133 4.98272 12.6133 4.98272 12.9133L2.19922 15.4133L4.26107 18.9133L8.07551 17.9133C8.38479 18.1133 8.79716 18.3133 9.20953 18.5133L10.1374 22.1133H14.2611L15.1889 18.5133C15.6013 18.3133 16.0137 18.1133 16.3229 17.8133L20.0343 18.8133L22.0961 15.3133L19.3126 12.7133C19.4157 12.5133 19.4157 12.3133 19.4157 12.1133ZM20.859 15.5133L19.6219 17.6133L16.6322 16.8133L16.1167 16.7133L15.7044 17.0133C15.3951 17.2133 14.9827 17.4133 14.6734 17.6133L14.2611 17.8133L14.158 18.3133L13.4363 21.1133H10.859L10.1374 18.2133L10.0343 17.7133L9.6219 17.5133C9.31262 17.3133 8.90025 17.2133 8.59097 16.9133L8.1786 16.6133L7.66314 16.7133L4.77654 17.5133L3.53943 15.4133L5.70437 13.3133L6.01365 13.1133V12.6133C6.01365 12.2133 6.01365 11.8133 6.01365 11.5133V11.0133L5.70437 10.7133L3.53943 8.71328L4.77654 6.61328L7.66314 7.41328L8.1786 7.51328L8.59097 7.21328C8.90025 7.01328 9.31262 6.81328 9.6219 6.61328L10.0343 6.41328L10.1374 5.91328L10.859 3.11328H13.3332L14.0549 6.01328L14.158 6.51328L14.5704 6.71328C14.8796 6.91328 15.292 7.01328 15.6013 7.31328L16.0137 7.61328L16.6322 7.41328L19.6219 6.61328L20.859 8.71328L18.6941 10.8133L18.2817 11.1133V11.6133C18.2817 12.0133 18.2817 12.4133 18.2817 12.7133V13.2133L18.591 13.5133L20.859 15.5133Z"
      fill={color}
    />
    <path
      d="M12.1999 8.11328C9.93184 8.11328 8.07617 9.91328 8.07617 12.1133C8.07617 14.3133 9.93184 16.1133 12.1999 16.1133C14.4679 16.1133 16.3236 14.3133 16.3236 12.1133C16.3236 9.91328 14.4679 8.11328 12.1999 8.11328ZM12.1999 15.1133C10.4473 15.1133 9.1071 13.8133 9.1071 12.1133C9.1071 10.4133 10.4473 9.11328 12.1999 9.11328C13.9525 9.11328 15.2927 10.4133 15.2927 12.1133C15.2927 13.8133 13.8494 15.1133 12.1999 15.1133Z"
      fill={color}
    />
  </svg>
);
