import styled from 'styled-components';
import { devices } from '../../../styles/viewports';
import { IDropdowItemProps, IDropdownProps } from '../../../types/types';
import zIndexes from '../../../styles/zIndexes';

export const TabContainer = styled.div`
  display: inline-flex;
  flex-direction: column;
  position: relative;
`;

export const OptionItem = styled.a<IDropdowItemProps>`
  font-size: var(--font-size-navigation);
  font-family: var(--font-family-navigation);
  font-weight: var(--font-weight-navigation);
  letter-spacing: var(--letter-spacing-navigation);
  color: var(--base-color-black);
  text-decoration: none;
  cursor: pointer;
  text-transform: uppercase;

  :hover,
  :focus {
    color: var(--base-color-${({ colorPrimary }) => colorPrimary});
  }
`;

export const OptionsContainer = styled.div<IDropdownProps>`
  background-color: var(--base-color-white);
  border: 1px solid var(--base-color-light-grey);
  border-top-color: var(--base-color-${({ colorPrimary }) => colorPrimary});
  display: flex;
  flex-direction: column;
  row-gap: var(--navigation-small-gap);
  padding: var(--navigation-middle-gap);
  position: absolute;
  top: 24px;
  min-width: calc(100% - calc(2 * var(--navigation-middle-gap)));
  max-width: max-content;
  white-space: nowrap;
  z-index: ${zIndexes.menu};

  ${(props) => {
    if (props.withIcon === 'none') {
      return `
        top: 21px;
    `;
    }
    return ``;
  }}

  @media ${devices.mobileAndTablet} {
    padding-left: 0px;
    padding-bottom: 0px;
    row-gap: var(--navigation-large-gap);
    border: 0;
    background-color: none;

    ${(props) => {
      if (props.withOptionsInMobile) {
        return `
          position: relative;
          top: 0px;
      `;
      }
      return `
        display: none;
    `;
    }}
  }
`;
