import styled from 'styled-components';
import { Grid, GridItem } from '../../atoms/Grid';
import { PrimaryButton } from '../../atoms/Buttons';
import { ITitleCTAProps, ITitleCTAGridItemProps } from '../../../types/navigation';
import { devices } from '../../../styles/viewports';
import zIndexes from '../../../styles/zIndexes';

export const TitleCTAGridWrapper = styled.div<ITitleCTAProps>`
  position: ${({ sticky }) => (sticky ? 'sticky' : 'initial')};
  top: -1px;
  background-color: var(--base-color-white);
  z-index: ${zIndexes.anchor};
`;

export const TitleCTAGrid = styled(Grid)`
  border-bottom: solid 2px var(--base-color-lightgrey);
`;

export const TitleCTAGridItem = styled.div<ITitleCTAGridItemProps>`
  grid-column: 2 / span 15;
  display: flex;
  align-items: center;
  flex-direction: ${({ title }) => (title ? 'row' : 'row-reverse')};
  gap: 40px;
  justify-content: space-between;

  @media ${devices.tablet} {
    & {
      grid-column: 2 / span 13;
      gap: 20px;
    }
  }

  @media ${devices.mobile} {
    & {
      grid-column: 2 / span 12;
    }
  }
`;

export const AnchorTitle = styled.div`
  display: flex;
  align-items: center;
  min-height: var(--button-height);

  h5 {
    padding: 0;
    margin: 0;
  }

  @media ${devices.mobile} {
    & {
      min-height: 50px;
    }
  }
`;

export const GridItemTitleWrapper = styled(GridItem)`
  @media ${devices.tablet} {
    grid-column: 2 / span 9;
  }
`;

export const ButtonsDesktopWrapper = styled.div`
  display: flex;
  justify-content: flex-end;

  @media ${devices.mobile} {
    display: none;
  }

  a {
    width: max-content;
  }
`;

export const ButtonsMobileWrapper = styled.div`
  display: none;

  @media ${devices.mobile} {
    & {
      display: block;
    }
  }
`;

export const PrimaryButtonReverse = styled(PrimaryButton)`
  && {
    background-color: ${({ theme }) => theme.colors.primaryButtonReverseBg};
    border-color: ${({ theme }) => theme.colors.primaryButtonReverseBg};
    color: ${({ theme }) => theme.colors.primaryButtonReverse};
    svg path {
      fill: ${({ theme }) => theme.colors.primaryButtonReverse};
    }
  }
`;

export const MessageWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: var(--grid-outer-margin);
  height: var(--button-height);

  h6 {
    margin: 0;
    padding: 0;
  }

  @media ${devices.tablet} {
    & {
      margin-right: var(--grid-margin);
    }
  }

  @media ${devices.mobile} {
    & {
      display: none;
    }
  }
`;

export const MessageWrapperMobile = styled.div`
  h6 {
    margin: 0;
    padding: 0;
  }
  display: none;

  @media ${devices.mobile} {
    & {
      display: flex;
      align-items: center;
      margin-left: var(--grid-margin);
      padding: 12px 0;
    }
  }
`;
