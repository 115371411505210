import * as React from 'react';
import { IconSVGProps } from '../../../../../types/iconTypes';

export default ({ color = '#1A1A1A' }: IconSVGProps): React.ReactElement => (
  <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3 3V7H21V3H3ZM20 6H4V4H20V6Z" fill={color} />
    <path d="M3 21H21V17H3V21ZM4 18H20V20H4V18Z" fill={color} />
    <path d="M3 14H21V10H3V14ZM4 11H20V13H4V11Z" fill={color} />
  </svg>
);
