import * as React from 'react';
import { IconSVGProps } from '../../../../../types/iconTypes';

export default ({ color = '#1A1A1A' }: IconSVGProps): React.ReactElement => (
  <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3 9H4V4H9V3H3V9Z" fill={color} />
    <path d="M20 20H15V21H21V15H20V20Z" fill={color} />
    <path d="M15 3V4H20V9H21V3H15Z" fill={color} />
    <path d="M4 15H3V21H9V20H4V15Z" fill={color} />
  </svg>
);
