import styled from 'styled-components';
import { devices } from '../../../styles/viewports';

export const ContactNewsletterWrapper = styled.div`
  display: grid;
  grid-template-columns: calc(50% - 1px) 2px calc(50% - 1px);
  grid-template-rows: 100px;
  gap: 0;

  @media ${devices.mobile} {
    & {
      display: grid;
      grid-template-columns: 100%;
      grid-template-rows: min-content min-content;
      gap: var(--footer-vertical-spacing-lg);
    }
  }
`;

export const ContactNewsletterSeparator = styled.div`
  grid-column: 2;
  justify-self: center;
  height: 100%;
  border: 1px solid var(--base-color-white);

  @media ${devices.mobile} {
    & {
      grid-column: auto;
      display: none;
    }
  }
`;

export const SignUpWrapper = styled.div`
  grid-column: 1;
  padding-top: 9px;
  justify-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${devices.mobile} {
    & {
      grid-column: auto;
      align-items: start;
      justify-self: start;
      padding-top: 0;
    }
  }
`;

export const SignUpText = styled.p`
  font-family: var(--font-family-body);
  font-size: var(--font-size-body-2);
  line-height: var(--line-height-body-2);
  width: 60%;
  margin: 0 auto;
  height: 60px;
  text-align: center;

  @media ${devices.mobile} {
    & {
      width: 100%;
      height: auto;
      text-align: left;
      font-size: 14px;
      margin-bottom: var(--footer-vertical-spacing-lg);
    }
  }
`;

export const ContactWrapper = styled.div`
  grid-column: 3;
  justify-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 9px;

  @media ${devices.mobile} {
    & {
      grid-column: auto;
      align-items: start;
      justify-self: start;
      padding-top: 0;
    }
  }
`;

export const MediaLinksWrapper = styled.div`
  display: flex;
  gap: var(--footer-media-gap);
  height: 60px;

  @media ${devices.mobile} {
    & {
      height: auto;
      margin-bottom: var(--footer-vertical-spacing-lg);
    }
  }
`;

export const MediaIconWrapper = styled.a`
  height: var(--footer-media-icon-height);
  width: var(--footer-media-icon-width);
`;

export const TextLinkWrapper = styled.div`
  &&& {
    font-family: var(--font-family-overline);
    font-size: var(--font-size-overline-1);
    font-weight: var(--font-weight-overline-1);
    line-height: var(--line-height-overline-1);
    letter-spacing: var(--letter-spacing-overline-1);
    text-transform: var(--text-transform-overline);
  }
`;
