import styled from 'styled-components';
import { devices } from '../../../styles/viewports';

export const AccordionContainer = styled.div`
  display: flex;
  flex-direction: column;
  div {
    overflow: hidden;
  }
`;

export const LineContainer = styled.div`
  height: 2px;
  background-color: var(--base-color-lightgrey);
  width: 100%;
`;

export const ChildrenContainer = styled.div`
  margin: 0px 20px 28px 20px;

  @media ${devices.mobile} {
    margin: 12px 0px 24px 0px;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  && a {
    font-size: var(--font-size-subtitle-1);
    font-family: var(--font-family-subtitle);
    font-weight: var(--font-weight-subtitle-1);
    letter-spacing: var(--letter-spacing-subtitle-1);
    line-height: var(--line-height-subtitle-1);
    text-transform: var(--text-transform-subtitle);
    white-space: break-spaces;
    overflow-wrap: break-word;
    margin: 28px 20px;

    @media ${devices.mobile} {
      margin: 12px 20px 12px 0px;
    }
  }

  && svg {
    width: var(--navigation-middle-gap);
    height: var(--navigation-middle-gap);
    margin-right: 20px;

    @media ${devices.mobile} {
      margin-right: 0px;
    }
  }

  :hover {
    cursor: pointer;
  }
`;

export const ContentContainer = styled.div`
  overflow: auto;
  transition: max-height 0.2s ease;
  font-size: var(--font-size-body-1);
  font-family: var(--font-family-body);
  font-feature-settings: var(--font-feature-settings-body);
  font-weight: var(--font-weight-body-1);
  letter-spacing: var(--letter-spacing-body-1);
  line-height: var(--line-height-body-1);
  text-transform: var(--text-transform-body);

  & h1 {
    font-size: var(--font-size-header-4);
    font-family: var(--font-family-header);
    font-feature-settings: var(--font-feature-settings-header);
    font-weight: var(--font-weight-header-4);
    letter-spacing: var(--letter-spacing-header-4);
    line-height: var(--line-height-header-4);
    text-transform: var(--text-transform-header);
    white-space: break-spaces;
    overflow-wrap: break-word;
  }

  & h2 {
    font-size: var(--font-size-subtitle-1);
    font-family: var(--font-family-subtitle);
    font-feature-settings: var(--font-feature-settings-subtitle);
    font-weight: var(--font-weight-subtitle-1);
    letter-spacing: var(--letter-spacing-subtitle-1);
    line-height: var(--line-height-subtitle-1);
    text-transform: var(--text-transform-subtitle);
    white-space: break-spaces;
    overflow-wrap: break-word;
  }

  & em {
    font-family: var(--font-family-body-italics);
  }

  & u {
    font-size: var(--font-size-body-1);
    font-family: var(--font-family-body);
  }
`;
