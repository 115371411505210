import * as React from 'react';
import { IconSVGProps } from '../../../../../types/iconTypes';

export default ({ color = '#1A1A1A' }: IconSVGProps): React.ReactElement => (
  <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.8 3H15.8H12.5H11.5H8.2H7.2H3V7.5V8.3V15.7V16.5V21H7.2H8.2H11.4H12.4H15.6H16.6H21V17V15.7V8.3V8V3H16.8ZM16.8 4H20V7.3H16.8V4ZM12.5 4H15.7V7.3H12.5V4ZM8.2 4H11.4V7.3H8.2V4ZM4 4H7.2V7.3H4V4ZM7.2 20H4V16.7H7.2V20ZM11.5 20H8.2V16.7H11.4V20H11.5ZM15.8 20H12.5V16.7H15.7V20H15.8ZM20 20H16.8V16.7H20V17V20ZM20 15.7H16.8H15.8H12.5H11.5H8.2H7.2H4V8.3H7.2H8.2H11.4H12.4H15.6H16.6H20V15.7Z"
      fill={color}
    />
    <path d="M10 14.5999L15 11.9999L10 9.3999V14.5999Z" fill={color} />
  </svg>
);
