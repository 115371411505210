import * as React from 'react';
import { IconSVGProps } from '../../../../../types/iconTypes';

export default ({ color = '#1A1A1A' }: IconSVGProps): React.ReactElement => (
  <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 2C9.7381 2.00427 7.54447 2.77523 5.77725 4.18702C4.01004 5.59881 2.77365 7.56802 2.26996 9.77313C1.76627 11.9782 2.02503 14.289 3.00399 16.328C3.98295 18.3671 5.62429 20.0141 7.66 21H2V22H12C14.6522 22 17.1957 20.9464 19.0711 19.0711C20.9464 17.1957 22 14.6522 22 12C22 9.34784 20.9464 6.8043 19.0711 4.92893C17.1957 3.05357 14.6522 2 12 2V2ZM12 21C10.22 21 8.47991 20.4722 6.99987 19.4832C5.51983 18.4943 4.36627 17.0887 3.68508 15.4442C3.0039 13.7996 2.82567 11.99 3.17293 10.2442C3.5202 8.49836 4.37737 6.89471 5.63604 5.63604C6.89471 4.37737 8.49836 3.5202 10.2442 3.17293C11.99 2.82567 13.7996 3.0039 15.4442 3.68508C17.0887 4.36627 18.4943 5.51983 19.4832 6.99987C20.4722 8.47991 21 10.22 21 12C21 14.3869 20.0518 16.6761 18.364 18.364C16.6761 20.0518 14.3869 21 12 21V21Z"
      fill={color}
    />
    <path
      d="M8.10954 13.3902C7.78094 13.389 7.45536 13.4529 7.15157 13.5782C6.84779 13.7035 6.57181 13.8877 6.33954 14.1202C5.87419 14.5917 5.61328 15.2276 5.61328 15.8902C5.61328 16.5527 5.87419 17.1886 6.33954 17.6602C6.80986 18.1279 7.44622 18.3905 8.10954 18.3905C8.77287 18.3905 9.40922 18.1279 9.87954 17.6602C10.3449 17.1886 10.6058 16.5527 10.6058 15.8902C10.6058 15.2276 10.3449 14.5917 9.87954 14.1202C9.64728 13.8877 9.3713 13.7035 9.06751 13.5782C8.76373 13.4529 8.43815 13.389 8.10954 13.3902V13.3902ZM9.16954 17.0002C8.92399 17.2454 8.60099 17.3979 8.25559 17.4317C7.91019 17.4655 7.56376 17.3785 7.27533 17.1855C6.9869 16.9925 6.77432 16.7054 6.67382 16.3732C6.57332 16.041 6.5911 15.6843 6.72416 15.3638C6.85721 15.0432 7.09729 14.7788 7.40348 14.6154C7.70968 14.452 8.06305 14.3999 8.40338 14.4679C8.7437 14.5359 9.04993 14.7198 9.26987 14.9882C9.4898 15.2567 9.60985 15.5931 9.60954 15.9402C9.60919 16.3378 9.45093 16.7191 9.16954 17.0002Z"
      fill={color}
    />
    <path
      d="M15.8908 10.6102C16.2194 10.6113 16.545 10.5474 16.8488 10.4221C17.1525 10.2968 17.4285 10.1127 17.6608 9.8802C18.1261 9.40861 18.3871 8.77273 18.3871 8.1102C18.3871 7.44767 18.1261 6.81179 17.6608 6.3402C17.1905 5.87244 16.5541 5.60986 15.8908 5.60986C15.2275 5.60986 14.5911 5.87244 14.1208 6.3402C13.6554 6.81179 13.3945 7.44767 13.3945 8.1102C13.3945 8.77273 13.6554 9.40861 14.1208 9.8802C14.3531 10.1127 14.629 10.2968 14.9328 10.4221C15.2366 10.5474 15.5622 10.6113 15.8908 10.6102V10.6102ZM14.8308 7.0502C15.0763 6.80495 15.3993 6.65244 15.7447 6.61865C16.0901 6.58486 16.4366 6.67188 16.725 6.86489C17.0134 7.0579 17.226 7.34495 17.3265 7.67713C17.427 8.0093 17.4092 8.36606 17.2762 8.68659C17.1431 9.00712 16.903 9.2716 16.5969 9.43496C16.2907 9.59831 15.9373 9.65044 15.597 9.58246C15.2566 9.51448 14.9504 9.33059 14.7305 9.06213C14.5105 8.79367 14.3905 8.45725 14.3908 8.1102C14.3911 7.71251 14.5494 7.33124 14.8308 7.0502Z"
      fill={color}
    />
    <path
      d="M8.10954 5.60988C7.78094 5.60874 7.45536 5.67266 7.15157 5.79795C6.84779 5.92324 6.57181 6.10742 6.33954 6.33988C5.87419 6.81147 5.61328 7.44735 5.61328 8.10988C5.61328 8.77241 5.87419 9.40829 6.33954 9.87988C6.80986 10.3476 7.44622 10.6102 8.10954 10.6102C8.77287 10.6102 9.40922 10.3476 9.87954 9.87988C10.3449 9.40829 10.6058 8.77241 10.6058 8.10988C10.6058 7.44735 10.3449 6.81147 9.87954 6.33988C9.64728 6.10742 9.3713 5.92324 9.06751 5.79795C8.76373 5.67266 8.43815 5.60874 8.10954 5.60988V5.60988ZM9.16954 9.16988C8.92399 9.41513 8.60099 9.56764 8.25559 9.60143C7.91019 9.63522 7.56376 9.5482 7.27533 9.35519C6.9869 9.16218 6.77432 8.87513 6.67382 8.54295C6.57332 8.21077 6.5911 7.85402 6.72416 7.53349C6.85721 7.21296 7.09729 6.94848 7.40348 6.78512C7.70968 6.62176 8.06305 6.56963 8.40338 6.63762C8.7437 6.7056 9.04993 6.88949 9.26987 7.15795C9.4898 7.42641 9.60985 7.76283 9.60954 8.10988C9.60919 8.50757 9.45093 8.88884 9.16954 9.16988V9.16988Z"
      fill={color}
    />
    <path
      d="M15.8908 13.3902C15.5622 13.389 15.2366 13.4529 14.9328 13.5782C14.629 13.7035 14.3531 13.8877 14.1208 14.1202C13.6554 14.5917 13.3945 15.2276 13.3945 15.8902C13.3945 16.5527 13.6554 17.1886 14.1208 17.6602C14.5911 18.1279 15.2275 18.3905 15.8908 18.3905C16.5541 18.3905 17.1905 18.1279 17.6608 17.6602C18.1261 17.1886 18.3871 16.5527 18.3871 15.8902C18.3871 15.2276 18.1261 14.5917 17.6608 14.1202C17.4285 13.8877 17.1525 13.7035 16.8488 13.5782C16.545 13.4529 16.2194 13.389 15.8908 13.3902V13.3902ZM17.0008 17.0002C16.7911 17.2101 16.5238 17.3532 16.2328 17.4112C15.9418 17.4692 15.6401 17.4396 15.3659 17.3261C15.0917 17.2127 14.8573 17.0204 14.6924 16.7737C14.5275 16.527 14.4395 16.2369 14.4395 15.9402C14.4395 15.6434 14.5275 15.3533 14.6924 15.1066C14.8573 14.8599 15.0917 14.6676 15.3659 14.5542C15.6401 14.4407 15.9418 14.4111 16.2328 14.4691C16.5238 14.5271 16.7911 14.6702 17.0008 14.8802C17.2817 15.1614 17.4395 15.5426 17.4395 15.9402C17.4395 16.3377 17.2817 16.7189 17.0008 17.0002V17.0002Z"
      fill={color}
    />
    <path
      d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z"
      fill={color}
    />
  </svg>
);
