import * as React from 'react';
import { core, fontFamilies } from '../../../styles/themes';

// Below data retrieved from royaloperahouse.mytalkdesk.com
const SCRIPT_SRC = 'https://talkdeskchatsdk.talkdeskapp.com/talkdeskchatsdk.js?t=';
const DATA_BIND = '5ea4b1ad-1a17-11ec-af32-06f09e0065e0';
const DATA_ORG = '';
const DIV_ID = 'tdWebchat';
const THEME_ICON = 'https://static.roh.org.uk/logos/crest.svg';
const THEME_FONT_BODY = fontFamilies.sansSerifFallback;
const THEME_COLOR = core.colors.core;
const THEME_COLOR_LIGHT = core.colors.core + '80';
const THEME_COLOR_BLACK = core.colors.black;
const THEME_COLOR_LIGHTGREY = core.colors.lightgrey;
const THEME_COLOR_WHITE = core.colors.white;
const THEME_COLOR_MIDGREY = core.colors.midgrey;
const SCRIPT_CONTENT = `var webchat;
((window, document, node, props, configs) => {
  if (window.TalkdeskChatSDK) {
    console.error("TalkdeskChatSDK already included");
    return;
  }
  var divContainer = document.createElement("div");
  divContainer.id = node;
  document.body.appendChild(divContainer);
  var currentDate = new Date().getTime();
  var src = "${SCRIPT_SRC}" + currentDate;
  var script = document.createElement("script");
  var firstScriptTag = document.getElementsByTagName("script")[0];
  script.type = "text/javascript";
  script.charset = "UTF-8";
  script.id = "tdwebchatscript";
  script.src = src;
  script.async = true;
  firstScriptTag.parentNode.insertBefore(script, firstScriptTag);
  script.onload = () => {
    webchat = TalkdeskChatSDK(node, props);
    webchat.init(configs);
  };
})(
  window,
  document,
  "${DIV_ID}",
  { flowId: "cd19d348b5fb4a0ab0d3277686f28d1e", accountId: "", region: "td-us-1" },
  { enableEmoji: true, enableUserInput: true, styles :{
    "botIcon": "${THEME_ICON}",
    "botBackgroundColor":"${THEME_COLOR_LIGHTGREY}",
    "botTextColor": "${THEME_COLOR_BLACK}",
    "chatTitle": "Royal Opera House",
    "chatThemeColor": "${THEME_COLOR}",
    "chatTitleIcon": "${THEME_ICON}",
    "customButtonBackgroundColor": "${THEME_COLOR}",
    "customButtonBorderColor": "${THEME_COLOR}",
    "customButtonColor": "${THEME_COLOR_WHITE}",
    "userBackgroundColor":"${THEME_COLOR_LIGHTGREY}",
    "userTextColor": "${THEME_COLOR_BLACK}",
    "userBackgroundColor":"${THEME_COLOR_MIDGREY}",
    "userTextColor": "${THEME_COLOR_BLACK}",
    "welcomeMessageBackgroundColor": "${THEME_COLOR_LIGHT}",
    "welcomeMessageContentFontSize": "18px",
    "welcomeMessageTitleFontFamily": "${THEME_FONT_BODY}",
    "welcomeMessageContentFontFamily": "${THEME_FONT_BODY}",
    "customMessageFontFamily": "${THEME_FONT_BODY}",
    "customMessageNoBubbleFontFamily": "${THEME_FONT_BODY}",
    "customButtonFontFamily": "${THEME_FONT_BODY}",
    "customFieldLabelFontFamily": "${THEME_FONT_BODY}",
    "customFieldInputFontFamily": "${THEME_FONT_BODY}",
    "customDropdownLabelFontFamily": "${THEME_FONT_BODY}",
    "customDropdownInputFontFamily": "${THEME_FONT_BODY}",
    "startChatButtonBackgroundColor": "${THEME_COLOR}",
    "startChatButtonBorderColor": "${THEME_COLOR}",
    "startChatButtonTextColor": "${THEME_COLOR_WHITE}",
    "startChatButtonHoverBackgroundColor": "${THEME_COLOR}",
    "startChatButtonHoverBorderColor": "${THEME_COLOR}",
    "startChatButtonHoverTextColor": "${THEME_COLOR_WHITE}",
    "startChatButtonFontFamily": "${THEME_FONT_BODY}",
  }}
);`;

const loadLiveChatScript = (callback: () => void): void => {
  const existingScript = document.getElementById(DIV_ID);
  if (!existingScript) {
    const liveChatDiv = document.createElement('div');

    liveChatDiv.id = DIV_ID;
    liveChatDiv.setAttribute('data-bind', DATA_BIND);
    liveChatDiv.setAttribute('data-org', DATA_ORG);

    const script = document.createElement('script');
    const scriptBody = document.createTextNode(SCRIPT_CONTENT);

    script.appendChild(scriptBody);

    document.body.appendChild(liveChatDiv);
    document.body.appendChild(script);
    script.onload = () => {
      if (callback) callback();
    };
  }
  if (existingScript && callback) callback();
};

const LiveChat = (): React.ReactElement => {
  // eslint-disable-next-line
  const [loaded, setLoaded] = React.useState(false);

  React.useEffect(() => {
    loadLiveChatScript(() => {
      setLoaded(true);
    });
  });

  return <span data-loaded={loaded}></span>;
};

export default LiveChat;
