import React, { FunctionComponent } from 'react';
import { TextLinkWrapper, TextLinkIconWrapper } from './TextLink.style';
import { Icon } from '../Icons';
import { ITextLinkProps } from '../../../types/types';

const TextLink: FunctionComponent<ITextLinkProps> = ({ children, iconName, iconDirection, color, ...rest }) => {
  const truncatedString = (children as string).substring(0, 30);
  return (
    <TextLinkWrapper color={color} iconName={iconName} {...rest}>
      {truncatedString}
      {iconName ? (
        <TextLinkIconWrapper data-testid="text-link-icon">
          <Icon iconName={iconName} direction={iconDirection} color={color} />
        </TextLinkIconWrapper>
      ) : null}
    </TextLinkWrapper>
  );
};

export default TextLink;
