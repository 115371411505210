import * as React from 'react';
import { IconSVGProps } from '../../../../../types/iconTypes';

export default ({ color = '#1A1A1A' }: IconSVGProps): React.ReactElement => (
  <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2ZM12 21C7 21 3 17 3 12C3 7 7 3 12 3C17 3 21 7 21 12C21 17 17 21 12 21Z"
      fill={color}
    />
    <path
      d="M16.5 11.5H12.5V5.5C12.5 5.2 12.3 5 12 5C11.7 5 11.5 5.2 11.5 5.5V12C11.5 12.3 11.7 12.5 12 12.5H16.5C16.8 12.5 17 12.3 17 12C17 11.7 16.8 11.5 16.5 11.5Z"
      fill={color}
    />
  </svg>
);
