import * as React from 'react';
import { IconSVGProps } from '../../../../../types/iconTypes';

export default ({ color = '#1A1A1A' }: IconSVGProps): React.ReactElement => (
  <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20 4H4C2.9 4 2 4.9 2 6V18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6C22 4.9 21.1 4 20 4ZM21 18.2L15.3 12.5L21 6.7V18C21 18.1 21 18.2 21 18.2ZM4 5H20C20.4 5 20.6 5.2 20.8 5.5L12 14.3L10.2 12.5L9.5 11.8L3.2 5.5C3.4 5.2 3.6 5 4 5ZM3 18.2C3 18.1 3 18 3 18V6.7L8.8 12.5L3 18.2ZM4 19C3.9 19 3.8 19 3.8 19L9.5 13.3L12 15.8L14.5 13.3L20.2 19C20.1 19 20 19 20 19H4Z"
      fill={color}
    />
  </svg>
);
