import styled from 'styled-components';
import { core } from '../../../styles/themes';
import { IIconStyledProps } from '../../../types/iconTypes';

export const Directions = {
  left: 'transform: rotate(180deg)',
  right: 'transform: rotate(0deg)',
  up: 'transform: rotate(270deg)',
  down: 'transform: rotate(90deg)',
  reverse: 'transform: scaleX(-1)',
};

export const Wrapper = styled.span<IIconStyledProps>`
  display: block;

  & svg {
    display: block;
    ${({ direction = 'right' }: IIconStyledProps) => Directions[direction]};
  }

  & svg path {
    fill: var(--base-color-${({ color }: any) => color || 'black'}, ${core.colors.black});
  }
`;
