import * as React from 'react';
import { Wrapper } from './Icons.style';
import { IconLibrary } from './SvgIcons';
import { IIconProps } from '../../../types/iconTypes';

const Icon = React.memo(
  ({ className, color, iconName, title, direction }: IIconProps): React.ReactElement => {
    const IconComponent = IconLibrary[iconName];

    return (
      <Wrapper className={className} color={color} title={title} direction={direction}>
        <IconComponent color={color} />
      </Wrapper>
    );
  }
);

Icon.displayName = 'Icon';

export default Icon;
