import { ReactNode, AnchorHTMLAttributes, MouseEventHandler } from 'react';
import { IconNameType, DirectionType } from './iconTypes';
import { Colors } from './types';

export enum ButtonType {
  Primary = 'Primary',
  Secondary = 'Secondary',
  Tertiary = 'Tertiary',
}

export interface IButtonProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  children: ReactNode;
  /**
   * Function to be called when a button is clicked
   */
  onClick?: MouseEventHandler<HTMLAnchorElement>;
  /**
   * Name of the icon to be used in the button
   */
  iconName?: IconNameType;
  /**
   * Direction of the icon used in the button
   */
  iconDirection?: DirectionType;
  /**
   * Color of text and icon
   */
  color?: Colors;
}

export interface IPrimaryButtonProps extends IButtonProps {
  /**
   * Button background color
   */
  bgColor?: Colors;
}

export interface ITertiaryButtonProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  /**
   * Color of text and icon
   */
  color?: Colors;
  /**
   * Function to be called when a button is clicked
   */
  onClick?: MouseEventHandler<HTMLAnchorElement>;
  children: ReactNode;
}

export interface IRotatorButtonsProps {
  /**
   * Set available Prev button
   */
  availablePrev?: boolean;
  /**
   * Set available Next button
   */
  availableNext?: boolean;
  /**
   * Function to be called when a button is clicked
   */
  onClickPrev?: Function;
  /**
   * Function to be called when a button is clicked
   */
  onClickNext?: Function;
}
