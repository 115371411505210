import styled from 'styled-components';
import { AspectRatio, AspectRatioLegacy, IImageAspectRatioWrapperProps } from '../../../types/image';

const ImageAspectRatioWrapper = styled.div<IImageAspectRatioWrapperProps>`
  aspect-ratio: ${({ aspectRatio = AspectRatio['1:1'] }) => aspectRatio};

  @supports not (aspect-ratio: ${({ aspectRatio = AspectRatio['1:1'] }) => aspectRatio}) {
    position: relative;
    width: 100%;
    padding-top: ${({ aspectRatio = AspectRatio['1:1'] }) => AspectRatioLegacy[aspectRatio]}%;
  }

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;

    @supports not (aspect-ratio: ${({ aspectRatio = AspectRatio['1:1'] }) => aspectRatio}) {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
`;

export default ImageAspectRatioWrapper;
