import styled from 'styled-components';
import { devices } from '../../../styles/viewports';
import { Grid } from '../../atoms/Grid';

export const HighlightsGrid = styled(Grid)`
  position: relative;
  grid-template-rows: max-content minmax(0, 1fr);
  gap: 0 var(--grid-column-gap);
  background-color: var(--base-color-black);
  color: var(--base-color-white);
  user-select: none;

  @media ${devices.mobile} {
    & {
      grid-template-rows: min-content max-content max-content;
    }
  }
`;

export const CarouselTitleWrapper = styled.div`
  grid-area: 1 / 1 / 1 / 7;
  box-sizing: border-box;
  width: calc(100% + var(--grid-column-gap));
  padding-top: 40px;
  padding-left: 50px;
  padding-right: 36px;
  user-select: text;

  h4 {
    margin: 0;
    padding: 0;
  }

  h5 {
    display: none;
  }

  @media ${devices.tablet} {
    & {
      grid-area: 1 / 1 / 2 / 12;
      padding: 12px 0 12px var(--grid-margin);
      min-height: 64px;
      box-sizing: border-box;
      width: 100%;

      h4 {
        display: none;
      }

      h5 {
        margin: 0;
        padding: 0;
        display: block;
        font-size: ${({ theme }) => theme.fonts.mobile.sizes.altHeaders[5]};
        font-weight: ${({ theme }) => theme.fonts.mobile.weights.altHeaders[5]};
        letter-spacing: ${({ theme }) => theme.fonts.mobile.letterSpacing.altHeaders[5]};
        line-height: 40px;
      }
    }
  }

  @media ${devices.mobile} {
    & {
      grid-area: 1 / 1 / 2 / 11;
      width: 100%;
      padding: 8px 0 8px 20px;
      min-height: var(--line-height-altHeader-5);

      h4 {
        display: none;
      }

      h5 {
        margin: 0;
        padding: 0;
        display: block;
      }
    }
  }
`;

export const InfoWrapper = styled.div`
  grid-area: 2 / 1 / 2 / 7;
  box-sizing: border-box;
  width: calc(100% + var(--grid-column-gap));
  align-self: center;
  padding: 36px 36px 36px 50px;

  @media ${devices.tablet} {
    & {
      grid-area: 2 / 1 / 2 / 7;
      padding: 0 12px 12px var(--grid-margin);
    }
  }

  @media ${devices.mobile} {
    & {
      grid-area: 3 / 1 / 4 / 15;
      width: 100%;
      padding: 0 20px;
      margin: 24px 0;
    }
  }
`;

export const InfoLogoWrapper = styled.div`
  margin-bottom: 32px;
  width: 132px;
  height: 36px;

  @media ${devices.tablet} {
    & {
      margin-bottom: 16px;
      width: 102px;
      height: 28px;
    }
  }

  @media ${devices.mobile} {
    & {
      margin-bottom: 20px;
      width: 102px;
      height: 28px;
    }
  }
`;

export const InfoTitleWrapper = styled.div`
  user-select: text;
  h3 {
    padding: 0;
    margin: 0 0 8px 0;
  }

  h6 {
    padding: 0;
    margin: 0;
  }

  @media ${devices.tablet} {
    & {
      h3 {
        font-size: ${({ theme }) => theme.fonts.mobile.sizes.headers[3]};
        font-weight: ${({ theme }) => theme.fonts.mobile.weights.headers[3]};
        letter-spacing: ${({ theme }) => theme.fonts.mobile.letterSpacing.headers[3]};
        line-height: ${({ theme }) => theme.fonts.mobile.lineHeights.headers[3]};
      }

      h6 {
        font-size: ${({ theme }) => theme.fonts.mobile.sizes.subtitles[2]};
        font-weight: ${({ theme }) => theme.fonts.mobile.weights.subtitles[2]};
        letter-spacing: ${({ theme }) => theme.fonts.mobile.letterSpacing.subtitles[2]};
        line-height: ${({ theme }) => theme.fonts.mobile.lineHeights.subtitles[2]};
      }
    }
  }
`;

export const InfoTextWrapper = styled.div`
  margin-top: 24px;
  user-select: text;
  h6 {
    padding: 0;
    margin: 0 0 4px 0;
  }

  @media ${devices.tablet} {
    & {
      margin-top: 20px;

      h6 {
        font-size: ${({ theme }) => theme.fonts.mobile.sizes.subtitles[2]};
        font-weight: ${({ theme }) => theme.fonts.mobile.weights.subtitles[2]};
        letter-spacing: ${({ theme }) => theme.fonts.mobile.letterSpacing.subtitles[2]};
        line-height: ${({ theme }) => theme.fonts.mobile.lineHeights.subtitles[2]};
      }

      > div {
        font-size: ${({ theme }) => theme.fonts.mobile.sizes.body[1]};
        font-weight: ${({ theme }) => theme.fonts.mobile.weights.body[1]};
        letter-spacing: ${({ theme }) => theme.fonts.mobile.letterSpacing.body[1]};
        line-height: ${({ theme }) => theme.fonts.mobile.lineHeights.body[1]};
      }
    }
  }

  @media ${devices.mobile} {
    & {
      margin-top: 20px;
      h6 {
        margin: 0 0 8px 0;
      }
    }
  }
`;

export const InfoLinkWrapper = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: row;

  @media ${devices.tablet} {
    & {
      margin-top: 16px;
    }
  }

  @media ${devices.mobile} {
    & {
      margin-top: 20px;
    }
    display: flex;
    flex-direction: column;
  }
`;

export const CarouselWrapper = styled.div`
  grid-area: 1 / 7 / 3 / 17;
  position: relative;
  height: 100%;
  width: 100%;

  .swiper-slide {
    width: calc(100% - 50px - var(--rotator-button-width));
    -webkit-transform: translate3d(0, 0, 0);
  }

  @media ${devices.tablet} {
    & {
      grid-area: 2 / 7 / 3 / 15;

      .swiper-slide {
        width: 100%;
        -webkit-transform: translate3d(0, 0, 0);
      }
    }
  }

  @media ${devices.mobile} {
    & {
      grid-area: 2 / 1 / 3 / 15;

      .swiper-slide {
        width: 100%;
        -webkit-transform: translate3d(0, 0, 0);
      }
    }
  }
`;

export const RotatorButtonsWrapper = styled.div`
  position: absolute;
  right: 50px;
  top: calc(50% - calc(var(--rotator-button-width) / 2));
  z-index: 2;

  @media ${devices.mobileAndTablet} {
    & {
      display: none;
    }
  }
`;

export const RotatorButtonsWrapperMobile = styled.div`
  display: none;

  @media ${devices.tablet} {
    & {
      display: flex;
      grid-area: 1 / 12 / 2 / 14;
      align-self: center;
      justify-content: flex-end;
    }
  }

  @media ${devices.mobile} {
    & {
      display: flex;
      grid-area: 1 / 11 / 2 / 14;
      align-self: center;
      justify-content: flex-end;
    }
  }
`;

export const HtmlBodyText = styled.p`
  padding: 0;
  margin: 0;
`;

export const FirstButtonComponentWrapper = styled.div`
  margin-right: 16px;

  @media ${devices.mobile} {
    & {
      display: flex;
      flex-direction: column;
      margin-right: 0;
      margin-bottom: 10px;
    }
  }
`;
