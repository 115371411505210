import * as React from 'react';
import { IconSVGProps } from '../../../../../types/iconTypes';

export default ({ color = '#1A1A1A' }: IconSVGProps): React.ReactElement => (
  <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19 12C19 10.3 17.7 9 16 9V10C17.1 10 18 10.9 18 12C18 13.1 17.1 14 16 14V15C17.7 15 19 13.7 19 12Z"
      fill={color}
    />
    <path
      d="M16 6V7C18.8 7 21 9.2 21 12C21 14.8 18.8 17 16 17V18C19.3 18 22 15.3 22 12C22 8.7 19.3 6 16 6Z"
      fill={color}
    />
    <path
      d="M4.5 9.5C3.7 9.5 3 10.2 3 11V14C3 14.8 3.7 15.5 4.5 15.5H6.8L14 21V4L6.8 9.5H4.5ZM13 6V19L7.2 14.5H4.5C4.2 14.5 4 14.3 4 14V11C4 10.7 4.2 10.5 4.5 10.5H7.2L13 6Z"
      fill={color}
    />
  </svg>
);
