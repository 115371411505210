import React, { CSSProperties, FunctionComponent, useState, useRef, useEffect } from 'react';
import {
  AccordionContainer,
  TitleContainer,
  ContentContainer,
  ChildrenContainer,
  LineContainer,
} from './Accordion.style';
import { IAccordionProps, INodeProps } from '../../../types/types';
import { Icon } from '../../atoms/Icons';
import { IconNameType } from '../../../types/iconTypes';

const Accordion: FunctionComponent<IAccordionProps> = ({
  title = '',
  showLine = true,
  children,
  visibleStandfirst,
  initOpen = false,
}) => {
  const [openAccordion, setOpenAccordion] = useState<boolean>(initOpen);
  const [textHeight, setTextHeight] = useState<string>('0px');
  const [iconName, setIconName] = useState<IconNameType>(initOpen ? 'Detract' : 'Expand');
  // Contents children visibility is set to prevent it from being keyboard tabbable when the accordion is closed
  const [childrenVisibility, setChildrenVisibility] = useState<CSSProperties>(
    initOpen ? { visibility: 'visible' } : { visibility: 'hidden' }
  );
  const content: INodeProps = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (content?.current && initOpen) {
      setTextHeight(`${content.current.scrollHeight}px`);
    }
  }, [content, initOpen]);

  const toggleAccordion = () => {
    if (!children) return;
    setOpenAccordion(!openAccordion);
    setTextHeight(openAccordion ? '0px' : `${content.current.scrollHeight}px`);
    setIconName(openAccordion ? 'Expand' : 'Detract');
    setChildrenVisibility(openAccordion ? { visibility: 'hidden' } : { visibility: 'visible' });
  };

  const keyDown = (e: { key: string }) => {
    if (e.key === 'Enter' || e.key === 'Space') {
      toggleAccordion();
    }
  };

  const Standfirst = () =>
    visibleStandfirst ? (
      <ContentContainer>
        <ChildrenContainer>{visibleStandfirst}</ChildrenContainer>
      </ContentContainer>
    ) : null;

  return (
    <AccordionContainer>
      <LineContainer />
      <TitleContainer onClick={toggleAccordion} onKeyDown={keyDown} tabIndex={0}>
        <h4>
          <a className="accordion-title">{title}</a>
        </h4>
        {children && <Icon iconName={iconName} />}
      </TitleContainer>
      <Standfirst />
      <ContentContainer data-testid="richcontainer" ref={content} style={{ maxHeight: `${textHeight}` }}>
        <ChildrenContainer style={childrenVisibility}>{children}</ChildrenContainer>
      </ContentContainer>
      {showLine && <LineContainer />}
    </AccordionContainer>
  );
};

export default Accordion;
