import styled from 'styled-components';
import { devices } from '../../../../styles/viewports';
import { IImpactWrappersStyledProps } from '../../../../types/editorial';
import { Grid } from '../../../atoms/Grid';
import zIndexes from '../../../../styles/zIndexes';

export const ImpactWrapper = styled.div<IImpactWrappersStyledProps>`
  aspect-ratio: 16 / 9;
  @supports not (aspect-ratio: 16 / 9) {
    height: 56.25vw;
  }

  position: relative;
  display: flex;
  background: var(--base-color-black);

  @media ${devices.mobile} {
    aspect-ratio: 1 / 1;
    @supports not (aspect-ratio: 1 / 1) {
      height: 100vw;
    }
  }
`;

export const ImpactHeaderImageWrapper = styled.div`
  overflow: hidden;
  aspect-ratio: 16 / 9;
  @supports not (aspect-ratio: 16 / 9) {
    height: 56.25vw;
  }

  img {
    width: 100%;
    filter: brightness(0.7);
    opacity: 0.95;
  }

  @media ${devices.mobile} {
    height: fit-content;
    aspect-ratio: 1 / 1;
    @supports not (aspect-ratio: 1 / 1) {
      height: 100vw;
    }

    img {
      height: 100%;
      object-fit: cover;
    }
  }
`;

export const ImpactGrid = styled(Grid)`
  position: absolute;
  align-self: center;
  width: 100%;
  z-index: ${zIndexes.contentOverlay};
  gap: 0 var(--grid-column-gap);
  grid-template-rows: min-content min-content min-content;
  grid-template-areas:
    '. . . . . logo logo logo logo logo logo . . . . .'
    '. . . text text text text text text text text text text . . .'
    '. . . button button button button button button button button button button . . .';

  @media ${devices.mobile} {
    & {
      height: 100%;
      grid-template-rows: 1fr max-content 1fr;
      grid-template-areas:
        '. . . logo logo logo logo logo logo logo logo . . .'
        '. text text text text text text text text text text text text .'
        '. button button button button button button button button button button button button .';
    }
  }

  @media ${devices.tablet} {
    align-content: center;

    & {
      grid-template-areas:
        '. . . logo logo logo logo logo logo logo logo . . .'
        '. . text text text text text text text text text text . .'
        '. . button button button button button button button button button button . .';
    }
  }
`;

export const SponsorWrapper = styled.div`
  position: absolute;
  top: 20px;
  right: var(--grid-outer-margin);
  width: fit-content;
  z-index: ${zIndexes.sponsorship};

  @media ${devices.mobileAndTablet} {
    & {
      right: var(--grid-margin);
    }
  }
`;

export const LogoWrapper = styled.div`
  grid-area: logo;
  align-self: end;
`;

export const TextWrapper = styled.div`
  grid-area: text;
  color: var(--base-color-white);
  text-align: center;
  margin-top: 40px;
  max-height: calc(2 * var(--line-height-header-3));
  overflow: hidden;

  h3 {
    margin: 0;
    padding: 0;
  }

  @media ${devices.tablet} {
    & {
      max-height: calc(4 * var(--line-height-header-3));
    }
  }

  @media ${devices.mobile} {
    & {
      margin-top: 12px;
      max-height: calc(4 * var(--line-height-header-3));
    }

    h3 {
      font-size: var(--font-size-header-3);
      letter-spacing: var(--letter-spacing-header-5);
      line-height: var(--line-height-header-5);
    }
  }
`;

export const ButtonWrapper = styled.div`
  grid-area: button;
  align-self: end;
  display: flex;
  justify-content: center;
  margin-top: 40px;

  @media ${devices.mobile} {
    & {
      margin-top: 0px;
      margin-bottom: 20px;
    }
  }
`;

export const ScrollDownWrapper = styled.div`
  position: absolute;
  bottom: 20px;
  left: var(--grid-outer-margin);
  width: fit-content;
  z-index: ${zIndexes.contentOverlay};

  @keyframes UpDown {
    0%,
    100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(8px);
    }
  }

  a {
    font-size: var(--font-size-overline-1);
    font-weight: var(--font-weight-overline-1);
    letter-spacing: var(--letter-spacing-overline-1);
    border: none;
    padding: 0;
    :hover {
      border: none;

      > span {
        animation: UpDown 1500ms linear infinite;
      }
    }
  }

  @media ${devices.mobile} {
    & {
      display: none;
    }
  }

  @media ${devices.tablet} {
    & {
      display: none;
    }
  }
`;
