import * as React from 'react';
import { IconSVGProps } from '../../../../../types/iconTypes';

export default ({ color = '#1A1A1A' }: IconSVGProps): React.ReactElement => (
  <svg width="100%" height="100%" viewBox="0 0 48 46" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M24 0L30.0248 16.7076L47.7764 17.2746L33.7483 28.1674L38.6946 45.2254L24 35.25L9.30537 45.2254L14.2517 28.1674L0.223587 17.2746L17.9752 16.7076L24 0Z"
      fill={color}
    />
  </svg>
);
