import * as React from 'react';
import { IconSVGProps } from '../../../../../types/iconTypes';

export default ({ color = '#1A1A1A' }: IconSVGProps): React.ReactElement => (
  <svg width="100%" height="100%" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.09961 11.1999L1.09961 7.2999L1.89961 6.6999L4.09961 9.4999L10.0996 1.3999L10.8996 1.9999L4.09961 11.1999Z"
      fill={color}
    />
  </svg>
);
