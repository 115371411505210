import styled from 'styled-components';
import { Grid } from '../../atoms/Grid';
import { devices } from '../../../styles/viewports';
import { IAnchorTabArrowsBoxProps, IAnchorTabsListProps, IAnchorTabBarBorderProps } from '../../../types/navigation';
import zIndexes from '../../../styles/zIndexes';

export const LIST_ITEM_GAP = 32;

export const AnchorTabbarWrapper = styled.div<IAnchorTabBarBorderProps>`
  position: sticky;
  width: 100%;
  top: 0;
  border-bottom: ${({ bottomBorder }) => (bottomBorder ? '2px solid var(--base-color-light-grey)' : 'none')};
  background-color: var(--base-color-white);
  z-index: ${zIndexes.anchor};
`;

export const TabsGrid = styled(Grid)`
  height: var(--anchor-tabs-height);
`;

export const TabsWrapper = styled.div`
  display: flex;
`;

export const TabsList = styled.ul<IAnchorTabsListProps>`
  width: ${({ tabsOverflow }) => (tabsOverflow ? 'calc(100% - 74px)' : '100%')};
  display: flex;
  gap: ${LIST_ITEM_GAP}px;
  justify-content: ${({ tabsOverflow }) => (tabsOverflow ? 'start' : 'center')};
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 0;
  height: var(--anchor-tabs-height);
  white-space: nowrap;
  overflow: scroll;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
  scroll-behavior: smooth;

  @media ${devices.mobileAndTablet} {
    background: linear-gradient(
      135deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0.48) 85%,
      rgba(235, 235, 235, 0.4) 99%,
      rgba(255, 255, 255, 0.39) 100%
    );
  }

  @media ${devices.mobile} {
    & {
      width: ${({ tabsOverflow, hasTwoArrows }) => (tabsOverflow && hasTwoArrows ? 'calc(100% - 50px)' : '100%')};
    }
  }
`;

export const ArrowsContainer = styled.div<IAnchorTabArrowsBoxProps>`
  width: ${({ fullWidth }) => (fullWidth ? '74px' : '46px')};
  height: var(--anchor-tabs-height);
  background-color: var(--base-color-white);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
`;

export const ArrowWrapper = styled.div`
  width: 24px;
  height: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-center;

  :hover {
    && svg path {
      fill: var(--base-color-primary);
    }
  }
`;
