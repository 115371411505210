import styled from 'styled-components';
import { IEditorialGridProps } from '../../../types/editorial';
import { devices } from '../../../styles/viewports';

export const EditorialGrid = styled.div<IEditorialGridProps>`
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  gap: var(--grid-column-gap);
  grid-template-areas: ${({ imageToLeft }) =>
    imageToLeft
      ? `'left left left left left . right right right right right right'`
      : `'left left left left left left . right right right right right'`};

  @media ${devices.mobile} {
    & {
      display: grid;
      grid-template-columns: 100%;
      grid-template-rows: min-content 24px min-content;
      grid-template-areas:
        'row1'
        '.'
        'row2';
    }
  }
`;

export const EditorialImageWrapper = styled.div<IEditorialGridProps>`
  align-self: center;
  grid-area: ${({ imageToLeft }) => (imageToLeft ? 'left' : 'right')};
  @media ${devices.mobile} {
    & {
      grid-area: row1;
  }
`;

export const EditorialTextWrapper = styled.div<IEditorialGridProps>`
  grid-area: ${({ imageToLeft }) => (imageToLeft ? 'right' : 'left')};
  align-self: center;
  @media ${devices.mobile} {
    & {
      grid-area: row2;
      align-self: start;
  }
`;

export const EditorialSubtitle = styled.span`
  font-family: var(--font-family-subtitle);
  font-feature-settings: var(--font-feature-settings-subtitle);
  font-size: var(--font-size-subtitle-1);
  font-weight: var(--font-weight-subtitle-1);
  line-height: var(--line-height-subtitle-1);
  letter-spacing: var(--letter-spacing-subtitle-1);
  text-transform: var(--text-transform-subtitle);
  margin-bottom: var(--editorial-subtitle-margin-bottom);
  display: block;
  overflow-wrap: break-word;
`;

export const EditorialText = styled.div`
  font-family: var(--font-family-body);
  font-feature-settings: var(--font-feature-settings-body);
  font-size: var(--font-size-body-1);
  font-weight: var(--font-weight-body-1);
  line-height: var(--line-height-body-1);
  letter-spacing: var(--letter-spacing-body-1);
  text-transform: var(--text-transform-body);
  margin: 0;
  padding: 0;
  white-space: break-spaces;
  overflow-wrap: break-word;

  em {
    font-family: var(--font-family-body-italics);
  }
`;
