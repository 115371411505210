import * as React from 'react';
import { IconSVGProps } from '../../../../../types/iconTypes';

export default ({ color = '#1A1A1A' }: IconSVGProps): React.ReactElement => (
  <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.4996 20.2002L13.3996 17.7002L12.5996 18.3002L15.5996 21.8002L20.8996 13.8002L20.0996 13.2002L15.4996 20.2002Z"
      fill={color}
    />
    <path d="M21 3H3V4H21V3Z" fill={color} />
    <path d="M21 6.5H3V7.5H21V6.5Z" fill={color} />
    <path d="M21 10H3V11H21V10Z" fill={color} />
    <path d="M14.5 13.5H3V14.5H14.5V13.5Z" fill={color} />
    <path d="M11 17H3V18H11V17Z" fill={color} />
  </svg>
);
