import * as React from 'react';
import { IconSVGProps } from '../../../../../types/iconTypes';

export default ({ color = '#1A1A1A' }: IconSVGProps): React.ReactElement => (
  <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18 18.5C18 19.3 17.3 20 16.5 20H5.5C4.7 20 4 19.3 4 18.5V7.5C4 6.7 4.7 6 5.5 6H10.6V5H5.5C4.1 5 3 6.1 3 7.5V18.5C3 19.9 4.1 21 5.5 21H16.5C17.9 21 19 19.9 19 18.5V13.4H18V18.5Z"
      fill={color}
    />
    <path d="M20.0008 3H14.0008V4H19.3008L10.3008 12.9L11.1008 13.7L20.0008 4.7V10H21.0008V4V3H20.0008Z" fill={color} />
  </svg>
);
