import { ReactNode, AnchorHTMLAttributes, MouseEventHandler } from 'react';
import { StyledProps } from 'styled-components';
import { IconNameType, DirectionType } from './iconTypes';

export enum Colors {
  White = 'white',
  Black = 'black',
  DarkGrey = 'darkgrey',
  MidGrey = 'midgrey',
  LightGrey = 'lightgrey',
  Trasparent = 'transparent',
  ErrorState = 'errorstate',
  MediumState = 'mediumstate',
  GoodState = 'goodstate',
  Primary = 'primary',
  Core = 'core',
  Stream = 'stream',
  Cinema = 'cinema',
}

export enum ThemeType {
  Core = 'core',
  Stream = 'stream',
  Cinema = 'cinema',
  Schools = 'schools',
}

export interface IThemeProviderProps {
  /**
   * The theme to use for the child elements
   */
  theme: ThemeType;
  children: ReactNode;
}

export interface ILogoProps {
  /**
   * The id value
   */
  id?: string;
  /**
   * The data-roh value
   */
  dataRoh?: string;
  /**
   * The link of the logo
   */
  urlLink?: string;
  /**
   * The image for desktop
   */
  desktopImage: string;
  /**
   * The image for other devices
   */
  devicesImage?: string;
  /**
   * The title of the image
   */
  title?: string;
}

export interface IOptionItem {
  /**
   * The text of the option
   */
  option: string;
  /**
   * The link of the option
   */
  optionLink: string;
}

export interface ITabProps {
  children?: ReactNode;
  /**
   * The value of the title
   */
  title?: string;
  /**
   * The link of the title
   */
  titleLink?: string;
  /**
   * To keep selected the title of the Tab
   */
  selected?: boolean;
  /**
   * The name of the icon
   */
  iconName?: IconNameType;
  /**
   * If options are available in mobile devices
   */
  withOptionsInMobile?: boolean;
  /**
   * The orientation of the icon
   */
  withIcon?: 'right' | 'left' | 'none';
  /**
   * If show text in mobile devices
   */
  withTextInMobile?: boolean;
  /**
   * Function to be called when click
   */
  onClick?: (e: string) => void;
  /**
   * Function to be called when focus event is fired
   */
  onFocus?: () => void;
  /**
   * Function to be called when it is needed to back to initial state
   */
  onReset?: () => void;
  /**
   * The primary color
   */
  colorPrimary?: string;
}

export interface ITabsProps {
  /**
   * An Array with Dropdown components
   */
  items?: IDropdownProps[];
  /**
   * Function to be called when click on showmenu
   */
  onShowMenu?: (e: boolean) => void;
  /**
   * Show menu in mobile devices
   */
  showMenu?: boolean;
  /**
   * Function to be called when click
   */
  onChange?: (e: string) => void;
  /**
   * The primary color
   */
  colorPrimary?: string;
}

export interface IDropdownProps {
  /**
   * The value of the title
   */
  title?: string;
  /**
   * The orientation of the icon
   */
  withIcon?: 'right' | 'left' | 'none';
  /**
   * The link of the title
   */
  titleLink?: string;
  /**
   * To keep selected the title of the Dropdown
   */
  selected?: boolean;
  /**
   * An Array with the options in the Dropdown
   */
  options?: IOptionItem[];
  /**
   * The name of the icon
   */
  iconName?: IconNameType;
  /**
   * Set if the behaviour of Dropdown change in mobile devices
   */
  isMobileBehaviour?: boolean;
  /**
   * If options are available in mobile devices
   */
  withOptionsInMobile?: boolean;
  /**
   * Function to be called when click
   */
  onChange?: (e: string) => void;
  /**
   * The primary color
   */
  colorPrimary?: string;
}

export interface IDropdowItemProps extends StyledProps<AnchorHTMLAttributes<HTMLAnchorElement>> {
  /**
   * The primary color
   */
  colorPrimary?: string;
}

export interface IControlledDropdownProps {
  /**
   * Text displayed as heading in the dropdown
   */
  text: string;
  /**
   * options to display
   */
  options?: IOptionItem[];
  /**
   * Color for active status (default primary)
   */
  activeColor?: string;
  /**
   * Set if the dropdown is active or not
   */
  active?: boolean;
  /**
   * Callback called when header is clicked
   */
  onClick: () => void;
  /**
   * Callback called when an option is clicked
   */
  onOptionClick?: (s: string) => void;
  /**
   * Mouse enter event handler
   */
  onMouseEnter?: () => void;
  /**
   * Mouse leave event handler
   */
  onMouseLeave?: () => void;
  /**
   * Callback called to reset dropdown behaviour
   */
  onReset?: () => void;
  /**
   * Callback called when main link or option are focused
   */
  onFocus?: () => void;
  /**
   * Callback called when main link or last option lose focus
   */
  onBlur?: () => void;
}

export interface IControlledDropdownHeaderProps extends StyledProps<any> {
  /**
   * Color for active status (default primary)
   */
  activeColor: string;
  /**
   * Set if the dropdown is active or not
   */
  active: boolean;
}

export type ListingPerson = {
  /**
   * Person name
   */
  name: string;
  /**
   * Link to person profile
   */
  link?: string;
  /**
   * String separator
   */
  headshot?: string;
  /**
   * Headshot image url
   */
  separator?: string;
  /**
   * Use headshot or not
   */
  useHeadshot: boolean;
};

export type ListingRole = {
  /**
   * Role name
   */
  name: string;
  /**
   * List of people in the role
   */
  people: ListingPerson[];
  /**
   * Data roh param
   */
  dataROH?: string;
};

export interface IPeopleListing {
  /**
   * List of roles to be displayed
   */
  roles: ListingRole[];
}

export interface IPagination {
  /**
   * The total number of pages
   */
  pageCount: 10;
  /**
   * The current page
   */
  currentPage: 1;
  /**
   * The url to base page links on
   */
  baseUrl: '/';
}

export interface INodeProps {
  /**
   * The current object of Node
   */
  current: any;
}

export interface IGridItemProps extends StyledProps<any> {
  /**
   * The column in which to place the child elements
   */
  columnStartDesktop: number;
  /**
   * The number of columns over which the child elements will span
   */
  columnSpanDesktop?: number;
  /**
   * The column in which to place the child elements in mobile devices
   */
  columnStartDevice: number;
  /**
   * The number of columns over which the child elements will span in mobile devices
   */
  columnSpanDevice?: number;
}

export interface ISponsorLogoProps {
  /**
   * Color for the logo
   */
  colorLogo?: string;
  /**
   * Color for the background of the logo
   */
  colorBackground?: string;
}

export interface ISponsorshipProps {
  /**
   * Source URL for Sponsorship IFRAME.
   */
  src?: string;
  /**
   * Alt Text for image.
   */
  altText?: string;
  /**
   * Used for Analytics.
   */
  dataRoh?: string;
  /**
   * Used for Accessibility.
   */
  title?: string;
  /**
   * Used to determine that there is custom data for Sponsorship
   */
  isCustomImage?: boolean;
}

export interface ITextLinkProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  children: ReactNode;
  /**
   * Function to be called when a button is clicked
   */
  onClick?: MouseEventHandler<HTMLAnchorElement>;
  /**
   * Name of the icon to be used in the button
   */
  iconName?: IconNameType;
  /**
   * Direction of the icon used in the button
   */
  iconDirection?: DirectionType;
  /**
   * Color of text and icon
   */
  color?: Colors;
}

export interface ISectionSplitterProps {
  /**
   * Set the width of the Section to full
   */
  fullWidth?: boolean;
}
export interface ITypeTagsProps {
  /**
   * List of Type Tags to display.
   */
  list: string[];
}

export interface ITextOnlyProps {
  /**
   * Text placed in the TextOnly component
   */
  text: string;
  /**
   * Set columnstart in Desktop
   */
  columnStartDesktop?: number;
  /**
   * Set columnSpan in Desktop
   */
  columnSpanDesktop?: number;
  /**
   * Set columnstart in Device
   */
  columnStartDevice?: number;
  /**
   * Set columnSpam in Device
   */
  columnSpanDevice?: number;
}

export type SectionTitleSize = 'small' | 'large';

export interface ISectionTitleProps {
  /**
   * Title to display.
   */
  title: string;
  /**
   * Size of title to display - by default small, which renders as an H2 (and large is an H1).
   */
  size?: SectionTitleSize;
  /**
   * Optional description that can be rendered below the title.
   */
  description?: string;
}

export interface ILogosProps {
  /**
   * Color used to fill logos.
   */
  fillColor?: Colors.White | Colors.Black;
}
export interface ILogosStyledProps extends StyledProps<any> {
  /**
   * Color used to fill logos.
   */
  fillColor?: Colors.White | Colors.Black;
}
export interface IAccordionProps {
  /**
   * Title to display.
   */
  title?: string;
  /**
   * The component to be wrapped into the accordion
   */
  children?: ReactNode;
  /**
   * Components wrapped into the accordion that are always visible
   */
  visibleStandfirst?: ReactNode;
  /**
   * Set if accordions are stacked
   */
  showLine?: boolean;
  /**
   * Set init open
   */
  initOpen?: boolean;
}

export interface IAccordionsProps {
  /**
   * Array of Accordion component
   */
  items: IAccordionProps[];
}

export interface IProgressProps {
  /**
   * The progress percentage
   */
  progress?: number;
}

export interface IAnnouncementBannerProps {
  /**
   * Announcement banner title
   */
  title: string;
  /**
   * Announcement message text
   */
  announcementText: string;
  /**
   * Date and time text
   */
  time: string;
  /**
   * Icon graphic
   */
  icon: 'Info' | 'Clock' | 'Location' | 'Reminder' | 'Star';
}

export type Image = Pick<HTMLImageElement, 'alt' | 'src'>;
