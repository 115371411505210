import styled from 'styled-components';
import { IBasketProps } from '../../../types/navigation';
import { devices } from '../../../styles/viewports';

export const BasketContainer = styled.div<IBasketProps>`
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  border-bottom: 1px solid var(--base-color-transparent);

  ${({ selected, colorPrimary }) => {
    if (selected) {
      return `
        border-bottom: 1px solid var(--base-color-${colorPrimary});
        && a {
          color: var(--base-color-${colorPrimary});
        }       
        && svg path {
          fill: var(--base-color-${colorPrimary});
        }
      `;
    }
    return '';
  }}

  :hover {
    border-bottom: 1px solid var(--base-color-${({ colorPrimary }) => colorPrimary});
    && a {
      color: var(--base-color-${({ colorPrimary }) => colorPrimary});
    }
    && svg path {
      fill: var(--base-color-${({ colorPrimary }) => colorPrimary});
    }
  }
`;

export const SvgContainer = styled.div`
  position: relative;

  svg {
    width: var(--navigation-large-gap);
    height: var(--navigation-large-gap);
  }
`;

export const NumContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 12px;
  height: 12px;
  position: absolute;
  top: 8px;
  margin-left: 6px;

  & .basket-num {
    color: white;
    font-size: 10px;
    font-family: var(--font-family-navigation);
  }
`;

export const BasketText = styled.a`
  font-size: var(--font-size-navigation);
  font-family: var(--font-family-navigation);
  color: var(--base-color-black);
  margin-left: 6px;
  text-decoration: none;

  @media ${devices.mobile} {
    display: none;
  }
`;
