const colors = {
  core: '#C8102E',
  stream: '#1866DC',
  cinema: '#1A1A1A',
};

const commonColors = {
  black: '#1A1A1A',
  white: '#FFFFFF',
  darkgrey: '#727272',
  midgrey: '#B2B2B2',
  lightgrey: '#F0F0F0',
  error: '#C8102E',
  medium: '#D79233',
  good: '#4EAA33',
  progress: '#1866DC',
  navigation: '#C8102E',
  lemonChiffon: '#fffbbe',
  lapisLazuli: '#0060a0',
  blue: '#1866DC',
  red: '#C8102E',
};

export const fontFamilies = {
  adobeGaramondPro: 'adobe-garamond-pro',
  adobeGaramondProItalics: 'adobe-garamond-pro',
  gothamSSmMedium: '"Gotham SSm A","Gotham SSm B","Montserrat",sans-serif',
  gothamSSm: '"Gotham SSm A","Gotham SSm B","Montserrat",sans-serif',
  sabonNextLTProRegular: 'Sabon Next LT Pro Regular',
  sabonNextLTProRegularItalics: 'Sabon Next LT Pro Regular Italics',
  sansSerifFallback: 'Montserrat, sans-serif',
};

const common = {
  fonts: {
    desktop: {
      sizes: {
        headers: {
          1: '96px',
          2: '68px',
          3: '44px',
          4: '34px',
          5: '24px',
          6: '20px',
        },
        altHeaders: {
          3: '44px',
          4: '34px',
          5: '24px',
          6: '20px',
        },
        subtitles: {
          1: '18px',
          2: '14px',
        },
        titleDescription: '20px',
        body: {
          1: '19px',
          2: '16px',
          3: '14px',
        },
        listing: '19px',
        buttons: '14px',
        overline: {
          1: '14px',
          2: '12px',
        },
        navigation: '13px',
        search: '24px',
      },
      letterSpacing: {
        headers: {
          1: '2px',
          2: '1px',
          3: '1px',
          4: '1px',
          5: '1px',
          6: '1px',
        },
        altHeaders: {
          3: 'normal',
          4: 'normal',
          5: 'normal',
          6: 'normal',
        },
        overline: {
          1: '1px',
          2: '1px',
        },
        subtitles: {
          1: '1px',
          2: '1px',
        },
        body: {
          1: 'normal',
          2: 'normal',
          3: 'normal',
        },
        navigation: '1px',
      },
      lineHeights: {
        headers: {
          1: '110px',
          2: '72px',
          3: '48px',
          4: '40px',
          5: '30px',
          6: '28px',
        },
        altHeaders: {
          3: '48px',
          4: '44px',
          5: '30px',
          6: '28px',
        },
        subtitles: {
          1: '22px',
          2: '22px',
        },
        titleDescription: '28px',
        body: {
          1: '26px',
          2: '22px',
          3: '20px',
        },
        peopleListing: '24px',
        listing: '36px',
        buttons: '20px',
        overline: {
          1: '16px',
          2: '14px',
        },
        navigation: '16px',
      },
      families: {
        headers: fontFamilies.gothamSSmMedium,
        altHeaders: fontFamilies.adobeGaramondPro,
        subtitles: fontFamilies.gothamSSmMedium,
        body: fontFamilies.adobeGaramondPro,
        bodyItalics: fontFamilies.adobeGaramondProItalics,
        listing: fontFamilies.adobeGaramondPro,
        listingItalics: fontFamilies.adobeGaramondProItalics,
        buttons: fontFamilies.gothamSSm,
        overline: fontFamilies.gothamSSmMedium,
        navigation: fontFamilies.gothamSSmMedium,
        peopleListingRole: fontFamilies.gothamSSm,
        search: fontFamilies.adobeGaramondPro,
      },
      weights: {
        headers: {
          1: '500',
          2: '500',
          3: '500',
          4: '500',
          5: '500',
          6: '500',
        },
        altHeaders: {
          3: 'normal',
          4: 'normal',
          5: 'normal',
          6: 'normal',
        },
        buttons: '400',
        overline: {
          1: '500',
          2: '500',
        },
        body: {
          1: '400',
          2: '400',
          3: '400',
        },
        subtitles: {
          1: '500',
          2: '500',
        },
        navigation: '500',
      },
      transforms: {
        headers: 'uppercase',
        altHeaders: 'none',
        overline: 'uppercase',
        subtitles: 'uppercase',
        body: 'none',
        navigation: 'uppercase',
      },
      fontFeatureSettings: {
        headers: "'tnum' on, 'lnum' on",
        altHeaders: "'pnum' on, 'onum' on",
        overline: "'tnum' on, 'lnum' on, 'liga' off, 'calt' off",
        subtitles: "'tnum' on, 'lnum' on",
        body: "'pnum' on, 'onum' on, 'liga' off",
        navigation: "'tnum' on, 'lnum' on, 'liga' off, 'calt' off",
      },
      wordBreak: {
        headers: 'break-word',
        altHeaders: 'break-word',
        overline: 'break-word',
        subtitles: 'break-word',
        body: 'break-word',
      },
      margins: {
        headers: {
          // These margins use the Chrome defaults to enable proper fallbacks when using semantic level - https://www.w3schools.com/cssref/css_default_values.php
          1: '0.67em 0',
          2: '0.83em 0',
          3: '1em 0',
          4: '1.33em 0',
          5: '1.67em 0',
          6: '2.33em 0',
        },
      },
    },
    mobile: {
      sizes: {
        headers: {
          1: '38px',
          2: '30px',
          3: '26px',
          4: '24px',
          5: '20px',
          6: '17px',
        },
        altHeaders: {
          3: '26px',
          4: '24px',
          5: '20px',
          6: '17px',
        },
        subtitles: {
          1: '16px',
          2: '14px',
        },
        titleDescription: '17px',
        body: {
          1: '17px',
          2: '12px',
          3: '11px',
        },
        listing: '16px',
        buttons: '14px',
        overline: {
          1: '14px',
          2: '12px',
        },
        navigation: '13px',
        search: '20px',
      },
      letterSpacing: {
        headers: {
          1: '1px',
          2: '1px',
          3: '1px',
          4: '1px',
          5: '1px',
          6: '1px',
        },
        altHeaders: {
          3: 'normal',
          4: 'normal',
          5: 'normal',
          6: 'normal',
        },
        overline: {
          1: '1px',
          2: '1px',
        },
        subtitles: {
          1: '1px',
          2: '1px',
        },
        body: {
          1: 'normal',
          2: 'normal',
          3: 'normal',
        },
        navigation: '1px',
      },
      lineHeights: {
        headers: {
          1: '42px',
          2: '34px',
          3: '30px',
          4: '28px',
          5: '28px',
          6: '24px',
        },
        altHeaders: {
          3: '30px',
          4: '28px',
          5: '28px',
          6: '24px',
        },
        subtitles: {
          1: '24px',
          2: '18px',
        },
        titleDescription: '24px',
        body: {
          1: '24px',
          2: '18px',
          3: '16px',
        },
        listing: '34px',
        buttons: '20px',
        overline: {
          1: '16px',
          2: '14px',
        },
        navigation: '16px',
      },
      families: {
        headers: fontFamilies.gothamSSm,
        altHeaders: fontFamilies.adobeGaramondPro,
        subtitles: fontFamilies.gothamSSmMedium,
        body: fontFamilies.adobeGaramondPro,
        bodyItalics: fontFamilies.adobeGaramondProItalics,
        listing: fontFamilies.adobeGaramondPro,
        listingItalics: fontFamilies.adobeGaramondProItalics,
        buttons: fontFamilies.gothamSSm,
        overline: fontFamilies.gothamSSmMedium,
        navigation: fontFamilies.gothamSSmMedium,
        search: fontFamilies.adobeGaramondPro,
      },
      weights: {
        headers: {
          1: '500',
          2: '500',
          3: '500',
          4: '500',
          5: '500',
          6: '500',
        },
        altHeaders: {
          3: 'normal',
          4: 'normal',
          5: 'normal',
          6: 'normal',
        },
        buttons: '400',
        overline: {
          1: '500',
          2: '500',
        },
        body: {
          1: '400',
          2: '400',
          3: '400',
        },
        subtitles: {
          1: '500',
          2: '500',
        },
        navigation: '500',
      },
      transforms: {
        headers: 'uppercase',
        altHeaders: 'none',
        overline: 'uppercase',
        subtitles: 'uppercase',
        body: 'none',
        navigation: 'uppercase',
      },
      fontFeatureSettings: {
        headers: "'tnum' on, 'lnum' on",
        altHeaders: "'pnum' on, 'onum' on",
        overline: "'tnum' on, 'lnum' on, 'liga' off, 'calt' off",
        subtitles: "'tnum' on, 'lnum' on",
        body: "'pnum' on, 'onum' on, 'liga' off",
        navigation: "'tnum' on, 'lnum' on, 'liga' off, 'calt' off",
      },
      wordBreak: {
        headers: 'break-word',
        altHeaders: 'break-word',
        overline: 'break-word',
        subtitles: 'break-word',
        body: 'break-word',
      },
    },
    tablet: {
      sizes: {
        headers: {
          6: '17px',
        },
        altHeaders: {
          4: '24px',
        },
        body: {
          1: '17px',
        },
      },
    },
  },
  spacing: {
    1: '4px',
    2: '8px',
    3: '12px',
    4: '16px',
    5: '20px',
    6: '24px',
    7: '28px',
    8: '32px',
    9: '36px',
    10: '40px',
    12: '48px',
    15: '60px',
    18: '72px',
    20: '80px',
    30: '120px',
    35: '140px',
    40: '160px',
    45: '180px',
    50: '200px',
  },
  grid: {
    mobile: {
      margin: '20px',
      outerMargin: '0',
      gap: '12px',
    },
    tablet: {
      margin: '32px',
      outerMargin: '0',
      gap: '20px',
    },
    desktop: {
      margin: '10%',
      outerMargin: '50px',
      gap: '36px',
    },
  },
  buttons: {
    paddingX: '20px',
    paddingY: '14px',
    paddingYIcon: '12px',
    iconWidth: '20px',
    iconHeight: '20px',
    iconMargin: '12px',
  },
  footer: {
    desktop: {
      height: '380px',
      paddingTop: '44px',
      paddingBottom: '0',
      itemsGap: '32px',
      mediaGap: '32px',
      mediaIconWidth: '24px',
      mediaIconHeight: '24px',
      verticalSpacingLarge: '58px',
      verticalSpacingSmall: '16px',
    },
    mobile: {
      height: 'auto',
      paddingTop: '20px',
      paddingBottom: '20px',
      itemsGap: '12px',
      mediaGap: '28px',
      mediaIconWidth: '28px',
      mediaIconHeight: '28px',
      verticalSpacingLarge: '24px',
      verticalSpacingSmall: '24px',
    },
  },
};

export const core = {
  ...common,
  colors: {
    ...commonColors,
    ...colors,
    primary: colors.core,
    primaryButton: colors.core,
    secondaryButton: commonColors.white,
    tertiaryButton: 'transparent',
    primaryButtonReverseBg: colors.cinema,
    primaryButtonReverse: commonColors.white,
  },
};
export const stream = {
  ...common,
  colors: {
    ...commonColors,
    ...colors,
    primary: colors.stream,
    primaryButton: colors.stream,
    secondaryButton: commonColors.white,
    tertiaryButton: 'transparent',
    primaryButtonReverseBg: colors.cinema,
    primaryButtonReverse: commonColors.white,
  },
};
export const cinema = {
  ...common,
  colors: {
    ...commonColors,
    ...colors,
    primary: colors.cinema,
    primaryButton: commonColors.white,
    secondaryButton: commonColors.white,
    tertiaryButton: 'transparent',
    primaryButtonReverseBg: commonColors.white,
    primaryButtonReverse: colors.cinema,
  },
};

export const schools = {
  ...common,
  colors: {
    ...commonColors,
    ...colors,
    primary: colors.core,
    primaryButton: colors.core,
    secondaryButton: commonColors.white,
    tertiaryButton: 'transparent',
    primaryButtonReverseBg: colors.cinema,
    primaryButtonReverse: commonColors.white,
  },
  fonts: {
    ...common.fonts,
    desktop: {
      ...common.fonts.desktop,
      transforms: {
        headers: 'none',
        altHeaders: 'none',
        overline: 'none',
        subtitles: 'none',
        body: 'none',
        navigation: 'uppercase',
      },
    },
    mobile: {
      ...common.fonts.mobile,
      transforms: {
        headers: 'none',
        altHeaders: 'none',
        overline: 'none',
        subtitles: 'none',
        body: 'none',
        navigation: 'uppercase',
      },
    },
  },
};

export const themes = { core, stream, cinema, fontFamilies, schools };
