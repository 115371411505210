import React, { FunctionComponent } from 'react';
import SecondaryButtonWrapper from './SecondaryButtonWrapper.style';
import { Colors } from '../../../../types/types';
import { IButtonProps } from '../../../../types/buttonTypes';

const SecondaryButton: FunctionComponent<IButtonProps> = ({ children, ...props }) => {
  return (
    <SecondaryButtonWrapper color={Colors.Primary} {...props}>
      {children}
    </SecondaryButtonWrapper>
  );
};

export default SecondaryButton;
