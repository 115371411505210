import styled from 'styled-components';
import { devices } from '../../../styles/viewports';
import { ICardButtonContainerProps, ICardContentContainerProps } from '../../../types/card';
import zIndexes from '../../../styles/zIndexes';
import { Colors } from '../../..';

const LENGTH_LARGE_TEXT = 28;
const LENGTH_SMALL_TEXT = 19;
const LENGTH_TEXT_TABLET = 10;

export const CardContainer = styled.div<{ onlyShowButtonsOnHover: boolean }>`
  cursor: pointer;
  position: relative;
  ${({ onlyShowButtonsOnHover }) =>
    !onlyShowButtonsOnHover
      ? `height: 100%;
       display: flex;
       flex-direction: column;
       justify-content: space-between;  
    `
      : ''}

  .targetLink {
    text-decoration: none;
    color: inherit;
  }
`;

export const HoverContainer = styled.div<{ lineColor: Colors }>`
  height: var(--editorial-spacing-hover);
  background-color: var(--base-color-${({ lineColor }) => lineColor});
  position: absolute;
  width: 100%;
  z-index: ${zIndexes.contentOverlay};
`;

export const ProgressContainer = styled.div`
  margin-top: -10px;
`;

export const ContentContainer = styled.div<ICardContentContainerProps>`
  display: flex;
  flex-direction: column;
  margin-top: var(--editorial-margin-between);

  @media ${devices.mobile} {
    margin-left: ${({ fullWidth }) => (fullWidth ? '0' : '20px')};
    margin-right: ${({ fullWidth }) => (fullWidth ? '0' : '20px')};
  }
`;

export const TitleContainer = styled.div`
  margin: calc(-1 * var(--editorial-margin-between)) 0 calc(-1 * var(--editorial-margin-between)) 0;

  @media ${devices.mobileAndTablet} {
    display: none;
  }
`;

export const TitleContainerMobile = styled.div`
  margin: calc(-1 * var(--editorial-margin-between)) 0 calc(-1 * var(--editorial-margin-between)) 0;
  display: none;

  @media ${devices.mobileAndTablet} {
    display: block;
  }
`;

export const TextContainer = styled.div`
  font-family: var(--font-family-body);
  font-feature-settings: var(--font-feature-settings-body);
  font-size: var(--font-size-body-1);
  font-weight: var(--font-weight-body-1);
  line-height: var(--line-height-body-1);
  letter-spacing: var(--letter-spacing-body-1);
  text-transform: var(--text-transform-body);
  margin-top: var(--editorial-spacing-hover);
  padding: 0;
  white-space: break-spaces;
  overflow-wrap: break-word;

  em {
    font-family: var(--font-family-body-italics);
  }
`;

export const SubtitleContainer = styled.span`
  font-family: var(--font-family-subtitle);
  font-feature-settings: var(--font-feature-settings-subtitle);
  font-size: var(--font-size-subtitle-2);
  font-weight: var(--font-weight-subtitle-2);
  line-height: var(--line-height-subtitle-2);
  letter-spacing: var(--letter-spacing-subtitle-2);
  text-transform: var(--text-transform-subtitle);
  display: block;
  overflow-wrap: break-word;
`;

const getButtonsOpacity = ({ onlyShowButtonsOnHover }: ICardButtonContainerProps) =>
  onlyShowButtonsOnHover ? 'opacity: 0;' : 'opacity: 1;';

const getButtonsMinHeight = ({ onlyShowButtonsOnHover }: ICardButtonContainerProps) =>
  onlyShowButtonsOnHover ? 'min-height: unset;' : 'min-height: 120px;';

export const ButtonsContainer = styled.div<ICardButtonContainerProps>`
  ${getButtonsOpacity};
  display: flex;
  gap: 16px;
  margin-top: var(--editorial-margin-between);

  ${({ size, primaryButtonTextLength, tertiaryButtonTextLength }) => {
    const isLinksLayoutColumn =
      size === 'small'
        ? primaryButtonTextLength >= LENGTH_SMALL_TEXT || tertiaryButtonTextLength >= LENGTH_SMALL_TEXT
        : primaryButtonTextLength >= LENGTH_LARGE_TEXT || tertiaryButtonTextLength >= LENGTH_LARGE_TEXT;

    if (isLinksLayoutColumn) {
      return `
        flex-direction: column;
      `;
    }
    return '';
  }}

  @media ${devices.mobile} {
    ${getButtonsMinHeight};
    margin-left: ${({ fullWidth }) => (fullWidth ? '0' : '20px')};
    margin-right: ${({ fullWidth }) => (fullWidth ? '0' : '20px')};
    opacity: 1 !important;
    gap: 24px;
    margin-top: 24px;
    flex-direction: column;
    align-items: center;
  }

  @media ${devices.tablet} {
    ${getButtonsMinHeight};
    opacity: 1 !important;
    gap: 24px;
    margin-top: 24px;
    align-items: flex-start;

    ${({ primaryButtonTextLength, tertiaryButtonTextLength }) => {
      const isLinksLayoutColumnTablet =
        primaryButtonTextLength >= LENGTH_TEXT_TABLET || tertiaryButtonTextLength >= LENGTH_TEXT_TABLET;

      if (isLinksLayoutColumnTablet) {
        return `
          flex-direction: column;
        `;
      }
      return '';
    }}
  }
`;
