import * as React from 'react';
import { IconSVGProps } from '../../../../../../types/iconTypes';

export default ({ color = '#1A1A1A' }: IconSVGProps): React.ReactElement => (
  <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21.9998 5.88989C21.2497 6.22055 20.4541 6.43631 19.6398 6.52989C20.4934 6.0305 21.1366 5.23803 21.4498 4.29989C20.6398 4.77267 19.7583 5.11042 18.8398 5.29989C18.4539 4.89263 17.9885 4.5689 17.4725 4.34873C16.9565 4.12856 16.4008 4.01664 15.8398 4.01989C14.7662 4.01179 13.7327 4.42715 12.9634 5.17592C12.194 5.92469 11.7508 6.94652 11.7298 8.01989C11.7307 8.32978 11.7676 8.63852 11.8398 8.93989C10.2142 8.86071 8.62291 8.44473 7.1666 7.71829C5.71029 6.99186 4.42077 5.97082 3.37975 4.71989C3.02375 5.3266 2.83404 6.01646 2.82976 6.71989C2.82871 7.38732 2.9947 8.04441 3.31258 8.63128C3.63047 9.21815 4.09016 9.71614 4.64976 10.0799C3.99934 10.0599 3.36252 9.88873 2.78976 9.57989C2.78849 10.5249 3.11611 11.4409 3.71641 12.1707C4.31671 12.9006 5.15227 13.3988 6.07976 13.5799C5.72736 13.6732 5.36431 13.7203 4.99976 13.7199C4.74165 13.7169 4.48418 13.6935 4.22976 13.6499C4.49917 14.456 5.01247 15.1585 5.69862 15.6601C6.38478 16.1618 7.20987 16.4377 8.05975 16.4499C6.61975 17.5939 4.83879 18.2239 2.99976 18.2399C2.66976 18.2399 2.33976 18.2399 1.99976 18.2399C3.88906 19.4067 6.06925 20.0167 8.28976 19.9999C9.81706 20.0291 11.3349 19.7535 12.7544 19.1892C14.1739 18.6249 15.4667 17.7832 16.557 16.7133C17.6474 15.6435 18.5134 14.3669 19.1046 12.9583C19.6957 11.5498 20 10.0375 19.9998 8.50989C19.9998 8.32989 19.9998 8.15989 19.9998 7.97989C20.7857 7.40806 21.463 6.70024 21.9998 5.88989Z"
      fill={color}
    />
  </svg>
);
