import styled from 'styled-components';
import { devices } from '../../../styles/viewports';
import { Grid } from '../../atoms/Grid';

export interface IPageHeadingWrapperProps {
  isPageHeadingWithoutTitle?: boolean;
}

export const PageHeadingWrapper = styled.div<IPageHeadingWrapperProps>`
  padding-bottom: 80px;
  background-color: ${({ theme }) => theme.colors.primary};
  color: var(--base-color-white);

  ${({ isPageHeadingWithoutTitle }) =>
    isPageHeadingWithoutTitle &&
    `
    padding-bottom: 42px;
  `};

  h2 {
    margin: 0;
    padding: 0;
  }

  @media ${devices.mobile} {
    padding-bottom: 0;
  }
`;

export const PageHeadingGrid = styled(Grid)`
  gap: 0 var(--grid-column-gap);
  grid-template-rows: min-content min-content;
  grid-template-areas:
    '. . title title title title title title title title title title . . logo .'
    '. . text text text text text text text text text button button button . .';

  @media ${devices.mobile} {
    & {
      grid-template-rows: 20px min-content min-content min-content 20px;
      grid-template-areas:
        '. . . . . . . . . . . . . .'
        '. title title title title title title title title title title logo logo .'
        '. text text text text text text text text text text text text .'
        '. button button button button button button button button button button button button .'
        '. . . . . . . . . . . . . .';
    }
  }

  @media ${devices.tablet} {
    & {
      grid-template-areas:
        '. title title title title title title title title title title logo logo .'
        '. text text text text text text text text text button button button .';
    }
  }
`;

export const TitleWrapper = styled.div<IPageHeadingWrapperProps>`
  grid-area: title;
  margin-top: 80px;

  ${({ isPageHeadingWithoutTitle }) =>
    isPageHeadingWithoutTitle &&
    `
    margin-top: 42px;
  `};

  @media ${devices.mobile} {
    & {
      margin-top: 0;
    }
  }
`;

export const ChildrenWrapper = styled.div<IPageHeadingWrapperProps>`
  margin-bottom: 20px;
  width: fit-content;
  height: 36px;

  ${({ isPageHeadingWithoutTitle }) =>
    isPageHeadingWithoutTitle &&
    `
    margin: 10px 0;
  `};

  @media ${devices.mobile} {
    & {
      height: 30px;
    }
  }
`;

export const TextWrapper = styled.div`
  grid-area: text;
  align-self: end;
  margin-top: 12px;

  h6 {
    margin: 0;
    padding: 0;
  }
`;

export const LogoWrapper = styled.div`
  grid-area: logo;
  justify-self: end;
  margin-top: 20px;
  position: relative;

  > div {
    position: absolute;
    top: 0;
    right: 0;
  }

  @media ${devices.mobile} {
    & {
      align-self: start;
      margin-top: 0;
    }
  }
`;

export const ButtonWrapper = styled.div`
  grid-area: button;
  justify-self: end;
  align-self: end;

  @media ${devices.mobile} {
    & {
      justify-self: center;
      align-self: center;
      width: 100%;
      margin-top: 16px;
    }
  }
`;

export const PageHeadingText = styled.div`
  font-size: var(--font-size-altHeader-6);
  font-weight: var(--font-weight-altHeader-6);
  letter-spacing: var(--letter-spacing-altHeader-6);
  line-height: var(--line-height-altHeader-6);
  font-feature-settings: var(--font-feature-settings-altHeader);
  font-family: var(--font-family-altHeader);
  text-transform: var(--text-transform-altHeader);
  word-break: var(--word-break-altHeader);
`;
