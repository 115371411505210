import React, { FunctionComponent } from 'react';
import ButtonWrapper from './Button.style';
import ButtonIconWrapper from './ButtonIconWrapper.style';
import { Icon } from '../Icons';
import { IButtonProps } from '../../../types/buttonTypes';

const Button: FunctionComponent<IButtonProps> = ({ children, iconName, iconDirection, color, ...rest }) => {
  const truncatedString = (children as string).substring(0, 30);
  return (
    <ButtonWrapper {...rest} color={color} iconName={iconName}>
      {iconName ? (
        <ButtonIconWrapper data-testid="button-icon">
          <Icon iconName={iconName} direction={iconDirection} color={color} />
        </ButtonIconWrapper>
      ) : null}
      {truncatedString}
    </ButtonWrapper>
  );
};

export default Button;
