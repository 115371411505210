import * as React from 'react';
import { IconSVGProps } from '../../../../../types/iconTypes';

export default ({ color = '#1A1A1A' }: IconSVGProps): React.ReactElement => (
  <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.8 6.0999C9 2.8999 14.1 2.6999 17.5 5.4999L17.3 2.9999L18.3 2.8999L18.6 6.9999L14.5 7.2999L14.4 6.2999L16.9 6.0999C13.8 3.7999 9.4 3.9999 6.5 6.7999C3.5 9.7999 3.5 14.6999 6.5 17.6999C9.5 20.6999 14.4 20.6999 17.4 17.6999C18.9 16.1999 19.7 14.2999 19.7 12.1999H20.7C20.7 14.4999 19.8 16.6999 18.1 18.3999C16.5 20.0999 14.2 20.9999 12 20.9999C9.8 20.9999 7.5 20.0999 5.8 18.3999C2.4 14.9999 2.4 9.4999 5.8 6.0999Z"
      fill={color}
    />
    <path d="M8.8 10.3L7.8 10.6L7.5 9.5L9.2 9H10.1V14.9H8.8V10.3Z" fill={color} />
    <path
      d="M11.3008 12C11.3008 10.3 12.4008 9 13.9008 9C15.4008 9 16.5008 10.3 16.5008 12C16.5008 13.7 15.5008 15 13.9008 15C12.3008 15 11.3008 13.7 11.3008 12ZM15.2008 12C15.2008 10.9 14.7008 10.1 13.9008 10.1C13.1008 10.1 12.6008 10.9 12.6008 12C12.6008 13.1 13.1008 13.9 13.9008 13.9C14.7008 13.9 15.2008 13.1 15.2008 12Z"
      fill={color}
    />
  </svg>
);
