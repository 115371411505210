import styled from 'styled-components';
import { devices } from '../../../styles/viewports';

interface BodyTextProps {
  truncate?: number;
}

interface ContentSummaryProps {
  showImage?: boolean;
}

export const ContentSummaryWrapper = styled.article`
  display: flex;
  justify-items: start;
  align-items: center;
  margin: 20px 0;
  gap: 24px;

  @media ${devices.mobile} {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
`;

export const ContentSummaryImageWrapper = styled.div`
  flex: 0 0 33.3%;
`;

export const ContentSummaryTextWrapper = styled.div<ContentSummaryProps>`
  grid-column: ${(props) => (props.showImage ? 2 : '1 / span 4')};
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: 22px;

  & > * {
    margin-block-start: 0;
    margin-block-end: 0;
  }

  @media ${devices.mobile} {
    margin: 0 20px;
    gap: 12px;
  }

  & .content-summary-text-link {
    height: unset;
  }
`;

export const ContentSummaryTitleWrapper = styled.div`
  & > * {
    margin: 0;
  }

  @media ${devices.mobile} {
    & > * {
      font-size: var(--font-size-header-5);
      font-weight: var(--font-weight-header-5);
    }
  }
`;

export const ContentSummaryBodyTextWrapper = styled.div<BodyTextProps>`
  & p:not(:last-child):after {
    content: '\\A\\A';
    white-space: pre;
  }

  ${(props) =>
    !!props.truncate &&
    `
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: ${props.truncate};
    -webkit-box-orient: vertical;
  `}
`;
