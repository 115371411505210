import styled from 'styled-components';
import { ITypographyWrapperProps } from '../../../types/typography';

const Wrapper = styled.div<ITypographyWrapperProps>`
  font-family: var(--font-family-${({ typography }) => typography});
  font-feature-settings: var(--font-feature-settings-${({ typography }) => typography});
  font-size: var(--font-size-${({ typography }) => typography}-${({ level }) => level});
  font-weight: var(--font-weight-${({ typography }) => typography}-${({ level }) => level});
  letter-spacing: var(--letter-spacing-${({ typography }) => typography}-${({ level }) => level});
  line-height: var(--line-height-${({ typography }) => typography}-${({ level }) => level});
  text-transform: var(--text-transform-${({ typography }) => typography});
  word-break: var(--word-break-${({ typography }) => typography});
  margin: var(--margin-${({ typography }) => typography}-${({ level }) => level});
`;
export default Wrapper;
