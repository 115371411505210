import * as React from 'react';
import { IconSVGProps } from '../../../../../types/iconTypes';

export default ({ color = '#1A1A1A' }: IconSVGProps): React.ReactElement => (
  <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 15.5C10.1 15.5 8.5 13.9 8.5 12C8.5 10.1 10.1 8.5 12 8.5C13.9 8.5 15.5 10.1 15.5 12C15.5 13.9 13.9 15.5 12 15.5ZM12 9.5C10.6 9.5 9.5 10.6 9.5 12C9.5 13.4 10.6 14.5 12 14.5C13.4 14.5 14.5 13.4 14.5 12C14.5 10.6 13.4 9.5 12 9.5Z"
      fill={color}
    />
    <path
      d="M16.7996 17.4L16.0996 16.7C18.3996 14.1 18.3996 9.9 16.0996 7.2L16.7996 6.5C19.4996 9.6 19.4996 14.4 16.7996 17.4Z"
      fill={color}
    />
    <path d="M7.2 17.4C4.6 14.4 4.6 9.5 7.2 6.5L7.9 7.2C5.6 9.8 5.6 14 7.9 16.7L7.2 17.4Z" fill={color} />
    <path
      d="M19.1004 20L18.4004 19.3C21.9004 15.3 21.9004 8.7 18.4004 4.7L19.1004 4C23.0004 8.4 23.0004 15.6 19.1004 20Z"
      fill={color}
    />
    <path
      d="M4.89961 20C0.999609 15.6 0.999609 8.4 4.89961 4L5.59961 4.7C2.09961 8.7 2.09961 15.3 5.59961 19.3L4.89961 20Z"
      fill={color}
    />
  </svg>
);
