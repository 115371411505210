import styled from 'styled-components';
import { ITabsProps } from '../../../types/types';
import { devices } from '../../../styles/viewports';

export const TabsContainer = styled.div`
  display: inline-flex;
  flex-direction: row;
  column-gap: 32px;
`;

export const ItemsContainer = styled.div<ITabsProps>`
  display: inline-flex;
  flex-direction: row;
  column-gap: 32px;

  @media ${devices.desktop} {
    column-gap: 15px;
  }

  @media ${devices.desktop}, ${devices.largeDesktop} {
    div:last-child > div {
      right: 1px;
    }
  }

  @media ${devices.mobileAndTablet} {
    ${(props) => {
      if (props.showMenu) {
        return `
          display: flex;
          flex-direction: column;
          row-gap: 32px;
        `;
      }
      return `display: none;`;
    }}
  }
`;

export const MenuContainer = styled.div<ITabsProps>`
  display: none;
  width: 60px;

  & a {
    font-size: var(--font-size-navigation);
    font-family: var(--font-family-navigation);
    font-weight: var(--font-weight-navigation);
    letter-spacing: var(--letter-spacing-navigation);
    color: var(--base-color-black);
    text-decoration: none;
    cursor: pointer;

    :hover {
      color: var(--base-color-${({ colorPrimary }) => colorPrimary});
    }
  }

  :hover {
    && svg path {
      fill: var(--base-color-${({ colorPrimary }) => colorPrimary});
    }
  }

  @media ${devices.mobileAndTablet} {
    display: block;

    svg {
      width: 24px;
      height: 24px;
      margin-left: 40px;
      margin-top: -20px;
    }

    a {
      margin-left: 20px;
    }
  }
`;

export const MenuItem = styled.div`
  @media ${devices.mobileAndTablet} {
    a {
      margin-left: 20px;
    }
  }
`;
