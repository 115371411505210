import styled from 'styled-components';
import { devices } from '../../../styles/viewports';

export const SearchBarContainer = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 64px;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  background-color: var(--base-color-transparent);

  @media ${devices.mobile} {
    column-gap: 12px;
  }
`;

export const SvgContainerSearch = styled.div`
  svg {
    width: var(--navigation-xlarge-gap);
    height: var(--navigation-xlarge-gap);
  }
`;

export const SvgContainerClose = styled.div`
  svg {
    width: var(--navigation-large-gap);
    height: var(--navigation-large-gap);
  }

  :hover {
    cursor: pointer;
    && svg path {
      fill: var(--base-color-navigation);
    }
  }
`;

export const SearchUnavailableContainer = styled.div`
  width: 100%;
  background-color: var(--base-color-transparent);
  font-size: var(--font-size-search);
  font-family: var(--font-family-search);
  color: var(--base-color-darkgrey);
  cursor: not-allowed;

  @media ${devices.mobileAndTablet} {
    font-size: var(--font-size-body-2);
    line-height: var(--line-height-body-2);
    padding-bottom: 12px;
  }
`;

export const InputContainer = styled.div`
  background-color: blue;
  width: 100%;

  input {
    font-size: var(--font-size-search);
    font-family: var(--font-family-search);
    border: none;
    border-radius: 0;
    outline: none;
    outline-color: var(--base-color-white);
    height: 48px;
    box-sizing: border-box;
    width: 100%;
    -webkit-appearance: none;

    &.search-input {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    @media ${devices.mobile} {
      column-gap: 24px;
    }
  }
`;

export const SearchLinkContainer = styled.div`
  @media ${devices.mobile} {
    display: none;
  }
`;

export const SearchArrowContainer = styled.a`
  display: none;
  height: 24px;
  width: 24px;

  @media ${devices.mobile} {
    display: block;
  }
`;
