import styled from 'styled-components';
import { IControlledDropdownHeaderProps } from '../../../types/types';

export const ControlledDropdownWrapper = styled.div`
  position: relative;
  width: fit-content;
`;

export const ControlledDropdownHeaderContainer = styled.span<IControlledDropdownHeaderProps>`
  display: flex;
  gap: 6px;
  border-bottom: 1px solid transparent;
  height: 24px;
  line-height: 24px;
  font-size: var(--font-size-navigation);
  font-family: var(--font-family-navigation);
  font-weight: var(--font-weight-navigation);
  letter-spacing: var(--letter-spacing-navigation);
  color: var(--base-color-black);
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;

  ${({ active, activeColor }) => {
    if (active) {
      return `
      & {
        border-bottom: 1px solid ${activeColor};
        color: var(--base-color-${activeColor});
      }
      && svg path {
        fill: var(--base-color-${activeColor});
      } 
      `;
    } else {
      return '';
    }
  }}
`;

export const ControlledDropdownHeaderContainerLink = styled(ControlledDropdownHeaderContainer).attrs({
  as: 'a',
})``;

export const IconWrapper = styled.span`
  width: 24px;
  height: 24px;
`;
