import * as React from 'react';
import { IconSVGProps } from '../../../../../types/iconTypes';

export default ({ color = '#1A1A1A' }: IconSVGProps): React.ReactElement => (
  <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.22 8.07994L12 2.43994L14.78 8.09994L21 8.99994L16.5 13.3699L17.56 19.5599L12 16.6399L6.44 19.5799L7.5 13.3899L3 8.99994L9.22 8.07994Z"
      fill={color}
    />
  </svg>
);
