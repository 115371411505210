import React from 'react';
import { TypeTagsContainer } from './TypeTags.style';
import { ITypeTagsProps } from '../../../types/types';
import { Overline } from '../Typography';

const TypeTags = ({ list }: ITypeTagsProps) => {
  return (
    <TypeTagsContainer>
      {list.map((t) => (
        <Overline level={1} tag="li" key={t}>
          {t}
        </Overline>
      ))}
    </TypeTagsContainer>
  );
};

export default TypeTags;
