import * as React from 'react';
import { IconSVGProps } from '../../../../../types/iconTypes';

export default ({ color = '#1A1A1A' }: IconSVGProps): React.ReactElement => (
  <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M22.0008 9.9001L21.3008 9.1001L19.2008 11.3001L17.0008 9.1001L16.3008 9.9001L18.4008 12.0001L16.3008 14.1001L17.0008 14.9001L19.2008 12.7001L21.3008 14.9001L22.0008 14.1001L19.9008 12.0001L22.0008 9.9001Z"
      fill={color}
    />
    <path
      d="M4.5 9.5C3.7 9.5 3 10.2 3 11V14C3 14.8 3.7 15.5 4.5 15.5H6.8L14 21V4L6.8 9.5H4.5ZM13 6V19L7.2 14.5H4.5C4.2 14.5 4 14.3 4 14V11C4 10.7 4.2 10.5 4.5 10.5H7.2L13 6Z"
      fill={color}
    />
  </svg>
);
