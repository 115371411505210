import * as React from 'react';
import { IconSVGProps } from '../../../../../types/iconTypes';

export default ({ color = '#1A1A1A' }: IconSVGProps): React.ReactElement => (
  <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20.9 9L19 10.9V5H15.5V4H14.5V5H12V4H11V5H8.5V4H7.5V5H4V18C4 19.1 4.9 20 6 20H17C18.1 20 19 19.1 19 18V15.1L23 11.1L20.9 9ZM17.5 12.3H14.3V10H18V11.9L17.5 12.3ZM14.6 15.7H14.3V13.4H16.5L14.7 15.2L14.6 15.7ZM8.7 19H6C5.4 19 5 18.6 5 18V16.7H8.7V19ZM8.7 15.7H5V13.4H8.7V15.7ZM8.7 12.3H5V10H8.7V12.3ZM5 9V6H7.5V7H8.5V6H11V7H12V6H14.5V7H15.5V6H18V9H5ZM13.3 19H9.7V16.7H13.4V19H13.3ZM13.3 15.7H9.7V13.4H13.4V15.7H13.3ZM13.3 12.3H9.7V10H13.4V12.3H13.3ZM18 16.7V18C18 18.6 17.6 19 17 19H14.3V18L16.9 17.2L17.5 16.6H18V16.7ZM19 13.7L18 14.7L17 15.7L16.3 16.4L15.3 16.7L15.6 15.7L17.9 13.4L18.9 12.4L19 12.3L19.7 13L19 13.7ZM19.8 11.5L20.9 10.4L21.6 11.1L20.5 12.2L19.8 11.5Z"
      fill={color}
    />
  </svg>
);
