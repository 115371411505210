import * as React from 'react';
import { IconSVGProps } from '../../../../../types/iconTypes';

export default ({ color = '#1A1A1A' }: IconSVGProps): React.ReactElement => (
  <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 14H11V16H12V14Z" fill={color} />
    <path d="M15 8H14V10H15V8Z" fill={color} />
    <path
      d="M19 4C19 4.26522 18.8946 4.51957 18.7071 4.70711C18.5196 4.89464 18.2652 5 18 5C17.7348 5 17.4804 4.89464 17.2929 4.70711C17.1054 4.51957 17 4.26522 17 4H5V10H2V20H14C14 19.7348 14.1054 19.4804 14.2929 19.2929C14.4804 19.1054 14.7348 19 15 19C15.2652 19 15.5196 19.1054 15.7071 19.2929C15.8946 19.4804 16 19.7348 16 20H19V14H22V4H19ZM18 19H16.73C16.5542 18.6969 16.3018 18.4453 15.9982 18.2705C15.6946 18.0956 15.3504 18.0035 15 18.0035C14.6496 18.0035 14.3054 18.0956 14.0018 18.2705C13.6982 18.4453 13.4458 18.6969 13.27 19H12V17H11V19H3V11H11V13H12V11H13.27C13.4458 11.3031 13.6982 11.5547 14.0018 11.7295C14.3054 11.9044 14.6496 11.9965 15 11.9965C15.3504 11.9965 15.6946 11.9044 15.9982 11.7295C16.3018 11.5547 16.5542 11.3031 16.73 11H18V19ZM21 13H19.73C19.5547 12.697 19.303 12.4453 19 12.27V10H16C16 10.2652 15.8946 10.5196 15.7071 10.7071C15.5196 10.8946 15.2652 11 15 11C14.7348 11 14.4804 10.8946 14.2929 10.7071C14.1054 10.5196 14 10.2652 14 10H6V5H14V7H15V5H16.27C16.4458 5.30308 16.6982 5.55465 17.0018 5.72953C17.3054 5.90441 17.6496 5.99646 18 5.99646C18.3504 5.99646 18.6946 5.90441 18.9982 5.72953C19.3018 5.55465 19.5542 5.30308 19.73 5H21V13Z"
      fill={color}
    />
  </svg>
);
