import React, { FunctionComponent } from 'react';
import { AccordionsContainer } from './Accordions.style';
import { IAccordionsProps, IAccordionProps } from '../../../types/types';
import Accordion from '../Accordion';

const Accordions: FunctionComponent<IAccordionsProps> = ({ items = [] }) => {
  const isLastAccordion = (index: number) => items.length - 1 === index;

  return (
    <AccordionsContainer>
      {items.map((accordion: IAccordionProps, index: number) => {
        return (
          <Accordion
            key={`${accordion.title}-${index}`}
            title={accordion.title}
            showLine={isLastAccordion(index)}
            visibleStandfirst={accordion.visibleStandfirst}
          >
            {accordion.children}
          </Accordion>
        );
      })}
    </AccordionsContainer>
  );
};

export default Accordions;
