import * as React from 'react';
import { IconSVGProps } from '../../../../../types/iconTypes';

export default ({ color = '#1A1A1A' }: IconSVGProps): React.ReactElement => (
  <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3 12.6001H19L12.9 18.7001L13.6 19.4001L21 12.0001L13.6 4.6001L12.9 5.3001L19.2 11.6001H3V12.6001Z"
      fill={color}
    />
  </svg>
);
