import styled from 'styled-components';
import { ITextLinkProps } from '../../../types/types';

export const TextLinkWrapper = styled.a<ITextLinkProps>`
  border-bottom: 1px solid;
  padding-bottom: ${({ iconName }) =>
    iconName ? 'calc(var(--text-link-underline-offset) - 4px)' : 'var(--text-link-underline-offset)'};
  font-family: var(--font-family-overline);
  font-size: var(--font-size-overline-1);
  font-weight: var(--font-weight-overline-1);
  line-height: var(--line-height-overline-1);
  letter-spacing: var(--letter-spacing-overline-1);
  text-transform: var(--text-transform-overline);
  color: var(--base-color-${({ color }) => color});
  width: fit-content;
  display: flex;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
`;

export const TextLinkIconWrapper = styled.span`
  width: var(--button-icon-width);
  height: var(--button-icon-height);
  margin-left: var(--button-icon-margin);
`;
