import * as React from 'react';
import { IconSVGProps } from '../../../../../types/iconTypes';

export default ({ color = '#1A1A1A' }: IconSVGProps): React.ReactElement => (
  <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.4 5C8.2 4.1 7.4 3.5 6.5 3.5C5.6 3.5 4.8 4.1 4.6 5H2V6H4.6C4.8 6.9 5.6 7.5 6.5 7.5C7.4 7.5 8.2 6.9 8.4 6H22V5H8.4ZM6.5 6.5C5.9 6.5 5.5 6.1 5.5 5.5C5.5 4.9 5.9 4.5 6.5 4.5C7.1 4.5 7.5 4.9 7.5 5.5C7.5 6.1 7.1 6.5 6.5 6.5Z"
      fill={color}
    />
    <path
      d="M17.5 10C16.6 10 15.8 10.6 15.6 11.5H2V12.5H15.6C15.8 13.4 16.6 14 17.5 14C18.4 14 19.2 13.4 19.4 12.5H22V11.5H19.4C19.2 10.6 18.4 10 17.5 10ZM17.5 13C16.9 13 16.5 12.6 16.5 12C16.5 11.4 16.9 11 17.5 11C18.1 11 18.5 11.4 18.5 12C18.5 12.6 18.1 13 17.5 13Z"
      fill={color}
    />
    <path
      d="M6.5 16.5C5.6 16.5 4.8 17.1 4.6 18H2V19H4.6C4.8 19.9 5.6 20.5 6.5 20.5C7.4 20.5 8.2 19.9 8.4 19H22V18H8.4C8.2 17.1 7.4 16.5 6.5 16.5ZM6.5 19.5C5.9 19.5 5.5 19.1 5.5 18.5C5.5 17.9 5.9 17.5 6.5 17.5C7.1 17.5 7.5 17.9 7.5 18.5C7.5 19.1 7.1 19.5 6.5 19.5Z"
      fill={color}
    />
  </svg>
);
