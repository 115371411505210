import * as React from 'react';
import { IconSVGProps } from '../../../../../../types/iconTypes';

export default ({ color = '#1A1A1A' }: IconSVGProps): React.ReactElement => (
  <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.6598 22H9.70976V12.14H6.99976V8.93H9.69976V6.65C9.70976 3.94 10.8998 2 14.8398 2C15.5622 2.02024 16.2831 2.07698 16.9998 2.17V5.17H15.6098C14.2098 5.17 13.6598 5.59 13.6598 6.77V8.93H16.9298L16.6398 12.14H13.6398L13.6598 22Z"
      fill={color}
    />
  </svg>
);
