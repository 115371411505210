import styled from 'styled-components';
import Button from '../Button';
import { IButtonProps } from '../../../../types/buttonTypes';

const PrimaryButtonWrapper = styled(Button)<IButtonProps>`
  background-color: var(--base-color-transparent);
  border-color: ${({ color, theme }) => (color ? `var(--base-color-${color})` : theme.colors.primary)};
`;

export default PrimaryButtonWrapper;
