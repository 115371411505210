import styled from 'styled-components';
import { devices } from '../../../styles/viewports';

export const CustomImage = styled.img`
  max-height: 100px;
  max-width: auto;

  @media ${devices.tablet} {
    max-height: 55px;
  }

  @media ${devices.mobile} {
    max-height: 45px;
  }
`;

export const SponsorshipStyledIframe = styled.div`
  background-color: transparent;
  height: 80px;
  margin: 0px;
  overflow: hidden;
  padding: 0;
  width: 80px;

  & > iframe {
    border: 0;
    display: block;
    margin: 0px;
    padding: 0px;
    overflow: hidden;
    width: 100%;
    height: 100%;
  }

  @media ${devices.mobile} {
    & {
      height: 40px;
      width: 40px;
    }
    & iframe {
      height: 80px;
      transform: scale(0.5) translate(-40px, -40px);
      width: 80px;
    }
  }
`;
