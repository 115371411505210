import styled from 'styled-components';
import { Grid, GridItem } from '../../atoms/Grid';
import { devices } from '../../../styles/viewports';
import { CarouselType, ICarouselWrapperProps } from '../../../types/carousel';

export const CarouselWrapper = styled.div<ICarouselWrapperProps>`
  user-select: none;

  .swiper-wrapper {
    height: 100%;
  }
  .swiper-slide {
    height: unset;
  }

  ${({ type, imagesHeightDesktop }) => {
    if (type !== CarouselType.Image) {
      const numberOfGaps = type === CarouselType.LargeCard ? 4 : 3;
      const numberOfColumns = type === CarouselType.LargeCard ? 5 : 4;
      const gapsSpace = `calc(var(--grid-column-gap) * ${numberOfGaps})`;
      const gapsInMainGraid = 'calc(11 * var(--grid-column-gap))';
      const calcColumns = `calc(calc(80% - ${gapsInMainGraid}) * ${numberOfColumns} / 12)`;

      return `
      & {
        .swiper-slide {
          width: calc(${calcColumns} + ${gapsSpace} - 1px);
          margin-right: var(--grid-column-gap);
          -webkit-transform: translate3d(0, 0, 0);
        }
      }
    `;
    } else {
      return `
      & {
        .swiper-slide {
          height: fit-content;
          width: auto;
          margin-right: var(--grid-column-gap);
          -webkit-transform: translate3d(0, 0, 0);

          > div {
            height: calc(${imagesHeightDesktop}px + var(--line-height-body-2) + 12px);
          }
        }
      }
    `;
    }
  }}

  @media ${devices.mobile} {
    ${({ active, type, imagesHeightDevice }) => {
      if (type !== CarouselType.Image) {
        const gapsSpace = 'calc(var(--grid-column-gap) * 9)';
        const numberOfColumns = 10;
        const gapsInMainGraid = 'calc(11 * var(--grid-column-gap))';
        const mainGridWidth = 'calc(100% - calc(2 * var(--grid-margin)))';
        const columnWidth = `calc(calc(${mainGridWidth} - ${gapsInMainGraid}) / 12)`;
        const calcColumns = `calc(${columnWidth} * ${numberOfColumns})`;
        const marginRight = active
          ? `calc(calc(var(--grid-column-gap) * 2) + ${columnWidth})`
          : `calc(calc(${columnWidth} * 2) + var(--grid-column-gap))`;

        return `
        && {
          .swiper-slide {
            width: calc(${calcColumns} + ${gapsSpace} - 1px);
            margin-right: ${marginRight};
            -webkit-transform: translate3d(0, 0, 0);
          }
        }`;
      } else {
        return `
        &&& {
          .swiper-slide > div{
            height: calc(${imagesHeightDevice}px + var(--line-height-body-2) + 12px);
          }
        }`;
      }
    }}
  }
`;

export const TitleButtonsGrid = styled(Grid)`
  margin-bottom: 28px;

  @media ${devices.mobile} {
    margin-bottom: 20px;
  }
`;

export const TitleWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  user-select: text;

  h4 {
    padding: 0;
    margin: 0;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: start;

  @media ${devices.mobile} {
    display: none;
  }
`;

export const TitleGridItem = styled(GridItem)`
  @media ${devices.tablet} {
     {
      grid-column: 2 / span 10;
    }
  }
`;
