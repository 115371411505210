import styled from 'styled-components';
import { ISearchProps } from '../../../types/navigation';

export const SearchContainer = styled.div<ISearchProps>`
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  border-bottom: 1px solid var(--base-color-transparent);
  ${({ selected, colorPrimary }) => {
    if (selected) {
      return `
        border-bottom: 1px solid var(--base-color-${colorPrimary});
        && svg path {
          fill: var(--base-color-${colorPrimary});
        }
      `;
    }
    return '';
  }}

  :hover {
    border-bottom: 1px solid var(--base-color-${({ colorPrimary }) => colorPrimary});
    && svg path {
      fill: var(--base-color-${({ colorPrimary }) => colorPrimary});
    }
  }
`;

export const SvgContainer = styled.div`
  svg {
    width: var(--navigation-large-gap);
    height: var(--navigation-large-gap);
  }
`;
