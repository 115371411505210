import React from 'react';
import Wrapper from './Typography.style';
import {
  IAltHeaderProps,
  IBodyTextProps,
  IHeaderProps,
  IOverlineProps,
  IStyledTag,
  ISubtitleProps,
} from '../../../types/typography';

export const StyledTag = ({ tag, typography, children, level }: IStyledTag) => {
  return (
    <Wrapper typography={typography} as={tag} level={level}>
      {children}
    </Wrapper>
  );
};

export const AltHeader = ({ level, children }: IAltHeaderProps) => {
  const renderedTag = `h${level}` as React.ElementType;
  return (
    <StyledTag tag={renderedTag} typography="altHeader" level={level}>
      {children}
    </StyledTag>
  );
};

export const BodyText = ({ level, children, tag = 'div' }: IBodyTextProps) => {
  const renderedTag = tag as React.ElementType;
  return (
    <StyledTag tag={renderedTag} typography="body" level={level}>
      {children}
    </StyledTag>
  );
};

export const Header = ({ semanticLevel, level, children }: IHeaderProps) => {
  const renderedTag = semanticLevel ? `h${semanticLevel}` : `h${level}`;
  return (
    <StyledTag tag={renderedTag as React.ElementType} typography="header" level={level}>
      {children}
    </StyledTag>
  );
};

export const Overline = ({ level, children, tag = 'div' }: IOverlineProps) => {
  const renderedTag = tag as React.ElementType;
  return (
    <StyledTag tag={renderedTag} typography="overline" level={level}>
      {children}
    </StyledTag>
  );
};

export const Subtitle = ({ level, children, tag = 'div' }: ISubtitleProps) => {
  const renderedTag = tag as React.ElementType;
  return (
    <StyledTag tag={renderedTag} typography="subtitle" level={level}>
      {children}
    </StyledTag>
  );
};
