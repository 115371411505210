import styled from 'styled-components';
import { devices } from '../../../styles/viewports';
import { IQuoteAttributionBlockProps } from '../../../types/quote';

export const TextWrapper = styled.div`
  h4 {
    margin: 0;
  }
`;

export const AttributionWrapper = styled.div<IQuoteAttributionBlockProps>`
  ${({ displayAttribution }) => `display: ${displayAttribution ? 'block' : 'none'};`}
`;

export const Line = styled.div`
  background-color: var(--base-color-black);
  height: 2px;
  width: 62px;
  margin: 32px 0;

  @media ${devices.mobile} {
    height: 1px;
    width: 46px;
    margin: 24px 0;
  }
`;
