import React from 'react';
import { IPeopleListing, ListingRole } from '../../../types/types';
import { GridItem } from '../../atoms/Grid';
import { Overline, BodyText } from '../../atoms/Typography';
import { HeadshotWrapper, PeopleListingGrid, PersonWrapper, RoleWrapper, TextWrapper } from './PeopleListing.style';
import Person from './Person';

function hasHeadshot(role: ListingRole): number {
  return +(!!role.people[0].headshot && role.people[0].useHeadshot);
}

function textSection(role: ListingRole): JSX.Element {
  return (
    <TextWrapper>
      <RoleWrapper title="role" data-roh={role.dataROH}>
        <Overline level={1}>{role.name}</Overline>
      </RoleWrapper>
      <BodyText level={1} tag="p">
        {role.people.map((person, personIndex) => (
          <Person
            key={`${person.name}${personIndex}`}
            person={person}
            withSeparator={role.people.length !== personIndex + 1}
          />
        ))}
      </BodyText>
    </TextWrapper>
  );
}
const PeopleListing = ({ roles }: IPeopleListing) => {
  return (
    <PeopleListingGrid>
      {roles
        .sort((a, b) => hasHeadshot(b) - hasHeadshot(a))
        .map((role, index) => {
          return (
            <GridItem key={`${role.name}-${index}`}>
              {!!hasHeadshot(role) ? (
                <PersonWrapper>
                  <HeadshotWrapper>
                    <img src={role.people[0].headshot} alt={role.people[0].name} />
                  </HeadshotWrapper>
                  {textSection(role)}
                </PersonWrapper>
              ) : (
                textSection(role)
              )}
            </GridItem>
          );
        })}
    </PeopleListingGrid>
  );
};

export default PeopleListing;
