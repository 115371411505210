import * as React from 'react';
import { IconSVGProps } from '../../../../../types/iconTypes';

export default ({ color = '#1A1A1A' }: IconSVGProps): React.ReactElement => (
  <svg width="100%" height="100%" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.06066 5.93957L11.0001 2.00011L9.93945 0.939453L6 4.87891L2.06055 0.939453L0.999887 2.00011L4.93934 5.93957L0.939453 9.93945L2.00011 11.0001L6 7.00023L9.99989 11.0001L11.0605 9.93945L7.06066 5.93957Z"
      fill={color}
    />
  </svg>
);
