import styled from 'styled-components';
import { AspectRatio, AspectRatioWidth, IImageWithCaptionWrapperProps } from '../../../types/image';
import ImageAspectRatioWrapper from '../../atoms/ImageAspectRatioWrapper';

export const Wrapper = styled.div<IImageWithCaptionWrapperProps>`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: fit-content;

  ${ImageAspectRatioWrapper} {
    height: calc(100% - 12px - var(--line-height-body-2));
    @supports not (aspect-ratio: ${({ aspectRatio = AspectRatio['1:1'] }) => aspectRatio}) {
      width: ${({ aspectRatio = AspectRatio['1:1'], height }) =>
        `calc(calc(${height}px - 12px - var(--line-height-body-2)) * ${AspectRatioWidth[aspectRatio]})`};
    }
  }
`;

export const CaptionWrapper = styled.div`
  margin-top: 12px;
  height: var(--line-height-body-2);
  font-family: var(--font-family-body);
  font-feature-settings: var(--font-feature-settings-body);
  font-size: var(--font-size-body-2);
  font-weight: var(--font-weight-body-2);
  letter-spacing: var(--letter-spacing-body-2);
  line-height: var(--line-height-body-2);
  text-transform: var(--text-transform-body-2);
  word-break: var(--word-break-body-2);
  font-style: italic;
`;
