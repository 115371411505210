import * as React from 'react';
import { IconSVGProps } from '../../../../../types/iconTypes';

export default ({ color = '#1A1A1A' }: IconSVGProps): React.ReactElement => (
  <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21 20.3L16.1 15.4C17.3 14.1 18 12.4 18 10.5C18 6.4 14.6 3 10.5 3C6.4 3 3 6.4 3 10.5C3 14.6 6.4 18 10.5 18C12.4 18 14.1 17.3 15.4 16.1L20.3 21L21 20.3ZM4 10.5C4 6.9 6.9 4 10.5 4C14.1 4 17 6.9 17 10.5C17 14.1 14.1 17 10.5 17C6.9 17 4 14.1 4 10.5Z"
      fill={color}
    />
  </svg>
);
