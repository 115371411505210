import styled from 'styled-components';
import { devices } from '../../../styles/viewports';

export const AnnouncementBannerWrapper = styled.div`
  padding: 28px 0;
  background-color: ${({ theme }) => theme.colors.primary};
  color: var(--base-color-white);

  @media ${devices.mobile} {
    padding: 20px 0;
  }
`;

export const SvgContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  svg {
    width: var(--navigation-large-gap);
    height: var(--navigation-large-gap);
  }

  @media ${devices.mobile} {
    align-items: flex-start;
  }
`;

export const ContentContainer = styled.div`
  overflow: auto;
  transition: max-height 0.2s ease;
  font-size: var(--font-size-body-1);
  font-family: var(--font-family-body);
  font-feature-settings: var(--font-feature-settings-body);
  font-weight: var(--font-weight-body-1);
  letter-spacing: var(--letter-spacing-body-1);
  line-height: var(--line-height-body-1);
  text-transform: var(--text-transform-body);
  color: var(--base-color-white);
  a {
    color: var(--base-color-white);
    text-decoration: underline;
  }
`;
