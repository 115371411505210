import styled from 'styled-components';
import { Grid } from '../../atoms/Grid';
import zIndexes from '../../../styles/zIndexes';
import { devices } from '../../../styles/viewports';

export const StickyBarWrapper = styled.div`
  top: 0;
  position: sticky;
  z-index: ${zIndexes.anchor};
  background-color: var(--base-color-white);
`;

export const StickyBarGrid = styled(Grid)`
  border-bottom: 2px solid var(--base-color-lightgrey);
  padding: 28px 0 26px;

  @media ${devices.mobileAndTablet} {
    padding: 24px 0 28px;
  }

  @media ${devices.mobile} {
    border-bottom: none;
  }
`;
