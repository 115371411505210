import styled from 'styled-components';
import { Grid } from '../../atoms/Grid';
import { IEditorialGridProps } from '../../../types/editorial';
import { devices } from '../../../styles/viewports';

const LENGTH_TEXT = 28;
const LENGTH_TEXT_TABLET = 10;

export const PromoWithTitleGrid = styled(Grid)<IEditorialGridProps>`
  grid-template-areas: ${({ imageToLeft }) =>
    imageToLeft
      ? `'left left left left left left left left . right right right right right . .'`
      : `'. . left left left left left . right right right right right right right right'`};

  @media ${devices.tablet} {
    & {
      grid-template-areas: ${({ imageToLeft }) =>
        imageToLeft
          ? `'left left left left left left left right right right right right right .'`
          : `'. left left left left left left right right right right right right right'`};
    }
  }

  @media ${devices.mobile} {
    & {
      grid-template-columns: var(--grid-margin) minmax(0, 1fr) var(--grid-margin);
      grid-template-rows: min-content 24px min-content;
      gap: 0;
      grid-template-areas:
        'row1 row1 row1'
        '. . .'
        '. row2 .';
    }
  }
`;

export const PromoWithTitleImageWrapper = styled.div<IEditorialGridProps>`
  grid-area: ${({ imageToLeft }) => (imageToLeft ? 'left' : 'right')};
  align-self: center;
  @media ${devices.mobile} {
    & {
      grid-area: row1;
    }
  }
`;

export const PromoWithTitleContentWrapper = styled.div<IEditorialGridProps>`
  grid-area: ${({ imageToLeft }) => (imageToLeft ? 'right' : 'left')};
  align-self: center;
  @media ${devices.mobile} {
    & {
      grid-area: row2;
      align-self: start;
    }
  }
`;

export const PromoWithTitleContainer = styled.div`
  h2,
  h3 {
    margin: 0 0 32px;

    @media ${devices.mobile} {
      & {
        margin: 0 0 24px;
      }
    }
  }
`;

export const PromoWithTitleSubtitle = styled.span`
  font-family: var(--font-family-subtitle);
  font-feature-settings: var(--font-feature-settings-subtitle);
  font-size: var(--font-size-subtitle-1);
  font-weight: var(--font-weight-subtitle-1);
  line-height: var(--line-height-subtitle-1);
  letter-spacing: var(--letter-spacing-subtitle-1);
  text-transform: var(--text-transform-subtitle);
  margin-bottom: var(--editorial-subtitle-margin-bottom);
  display: block;
  overflow-wrap: break-word;
`;

export const PromoWithTitleText = styled.div`
  font-family: var(--font-family-body);
  font-feature-settings: var(--font-feature-settings-body);
  font-size: var(--font-size-body-1);
  font-weight: var(--font-weight-body-1);
  line-height: var(--line-height-body-1);
  letter-spacing: var(--letter-spacing-body-1);
  text-transform: var(--text-transform-body);
  margin: 0;
  padding: 0;
  white-space: break-spaces;
  overflow-wrap: break-word;

  h5,
  h6 {
    margin: 0 0 4px 0;
    font-family: var(--font-family-subtitle);
    font-feature-settings: var(--font-feature-settings-subtitle);
    font-size: var(--font-size-subtitle-2);
    font-weight: var(--font-weight-subtitle-2);
    line-height: var(--line-height-subtitle-2);
    letter-spacing: var(--letter-spacing-subtitle-2);
    text-transform: var(--text-transform-subtitle);
    overflow-wrap: break-word;
  }

  em {
    font-family: var(--font-family-body-italics);
  }

  a {
    text-decoration: underline;
    cursor: pointer;

    &[href^='tel:'] {
      text-decoration: none;
    }

    &:link {
      color: ${({ theme }) => theme.colors.black};
    }

    &:visited {
      color: ${({ theme }) => theme.colors.darkgrey};
    }

    &:hover {
      color: ${({ theme }) => theme.colors.primary};
    }
  }
`;

export const ButtonsContainer = styled.div<IEditorialGridProps>`
  display: flex;
  gap: 16px;
  margin-top: 32px;

  ${({ primaryButtonTextLength = 0, tertiaryButtonTextLength = 0 }) => {
    const isLinksLayoutColumn = primaryButtonTextLength >= LENGTH_TEXT || tertiaryButtonTextLength >= LENGTH_TEXT;

    if (isLinksLayoutColumn) {
      return `
        flex-direction: column;
      `;
    }
    return '';
  }}

  @media ${devices.mobile} {
    gap: 24px;
    margin-top: 24px;
    flex-direction: column;
    align-items: center;
  }

  @media ${devices.tablet} {
    gap: 24px;
    margin-top: 24px;
    align-items: flex-start;

    ${({ primaryButtonTextLength = 0, tertiaryButtonTextLength = 0 }) => {
      const isLinksLayoutColumnTablet =
        primaryButtonTextLength >= LENGTH_TEXT_TABLET || tertiaryButtonTextLength >= LENGTH_TEXT_TABLET;

      if (isLinksLayoutColumnTablet) {
        return `
          flex-direction: column;
        `;
      }
      return '';
    }}
  }
`;
