import * as React from 'react';
import { IconSVGProps } from '../../../../../types/iconTypes';

export default ({ color = '#1A1A1A' }: IconSVGProps): React.ReactElement => (
  <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19 6H16V3H8V6H5C3.9 6 3 6.9 3 8V16C3 17.1 3.9 18 5 18H8V21H16V18H19C20.1 18 21 17.1 21 16V8C21 6.9 20.1 6 19 6ZM9 4H15V6H9V4ZM15 20H9V14H15V20ZM20 16C20 16.6 19.6 17 19 17H16V13H8V17H5C4.4 17 4 16.6 4 16V8C4 7.4 4.4 7 5 7H19C19.6 7 20 7.4 20 8V16Z"
      fill={color}
    />
  </svg>
);
