export const breakpoints = {
  xs: 320,
  sm: 700,
  md: 1140,
  lg: 1400,
};

export const devices = {
  smallMobile: `only screen and (max-width: ${breakpoints.xs - 1}px)`,
  mobile: `only screen and (max-width: ${breakpoints.sm - 1}px)`,
  tablet: `only screen and (max-width: ${breakpoints.md - 1}px) and (min-width: ${breakpoints.sm}px)`,
  mobileAndTablet: `only screen and (max-width: ${breakpoints.md - 1}px)`,
  desktop: `only screen and (max-width: ${breakpoints.lg - 1}px) and (min-width: ${breakpoints.md}px)`,
  largeDesktop: `only screen and (min-width: ${breakpoints.lg}px)`,
};
