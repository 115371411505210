import styled from 'styled-components';
import { Grid, GridItem } from '../../atoms/Grid';
import { devices } from '../../../styles/viewports';

export const CardsContainer = styled(Grid)`
  gap: var(--line-height-people-listing-gap) var(--grid-column-gap);

  @media ${devices.mobile} {
     {
      grid-row-gap: var(--cards-spacing-stack);
    }
  }
`;

export const GridItemContainer = styled(GridItem)`
  @media ${devices.mobile} {
     {
      grid-column: 1 / span 14;
    }
  }
`;
