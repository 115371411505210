import * as React from 'react';
import { IconSVGProps } from '../../../../../../types/iconTypes';

export default ({ color = '#1A1A1A' }: IconSVGProps): React.ReactElement => (
  <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.6997 10.28C19.5532 10.2898 19.4062 10.2898 19.2597 10.28C18.4648 10.2811 17.6823 10.083 16.9836 9.70397C16.2849 9.32492 15.6922 8.77693 15.2597 8.11V15.51C15.2597 16.5919 14.9389 17.6494 14.3378 18.549C13.7368 19.4485 12.8825 20.1496 11.883 20.5636C10.8835 20.9776 9.78364 21.086 8.72256 20.8749C7.66148 20.6638 6.68682 20.1429 5.92183 19.3779C5.15684 18.6129 4.63587 17.6382 4.42481 16.5771C4.21375 15.5161 4.32207 14.4162 4.73608 13.4167C5.15009 12.4172 5.8512 11.5629 6.75074 10.9619C7.65027 10.3608 8.70784 10.04 9.7897 10.04H10.1297V12.73H9.7897C9.04975 12.73 8.3401 13.0239 7.81688 13.5472C7.29365 14.0704 6.9997 14.78 6.9997 15.52C6.9997 16.26 7.29365 16.9696 7.81688 17.4928C8.3401 18.0161 9.04975 18.31 9.7897 18.31C10.5372 18.3236 11.26 18.0429 11.8025 17.5285C12.345 17.0141 12.6636 16.3071 12.6897 15.56L12.6997 3H15.2797C15.3963 4.11994 15.9028 5.16325 16.7108 5.94747C17.5188 6.7317 18.5768 7.20691 19.6997 7.29V10.29"
      fill={color}
    />
  </svg>
);
