import styled from 'styled-components';
import { IProgressProps } from '../../../types/types';

export const ProgressView = styled.div`
  height: 10px;
  width: 100%;
  display: flex;
  flex-direction: row;
`;

export const ElapsedProgressView = styled.div<IProgressProps>`
  background-color: var(--base-color-progress);
  flex: ${({ progress }) => progress};
`;

export const PendingProgressView = styled.div<IProgressProps>`
  background-color: var(--base-color-white);
  opacity: 0.5;
  flex: ${({ progress }) => progress};
`;
