import * as React from 'react';
import { IconSVGProps } from '../../../../../types/iconTypes';

export default ({ color = '#1A1A1A' }: IconSVGProps): React.ReactElement => (
  <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.1992 6.1C14.9992 2.9 9.89922 2.7 6.49922 5.5L6.69922 3H5.69922L5.39922 7.1L9.49922 7.4L9.59922 6.4L7.09922 6.2C10.0992 3.7 14.5992 3.9 17.3992 6.7C20.3992 9.7 20.3992 14.6 17.3992 17.6C14.3992 20.6 9.49922 20.6 6.49922 17.6C4.99922 16.1 4.19922 14.2 4.19922 12.1H3.19922C3.19922 14.4 4.09922 16.6 5.79922 18.3C7.49922 20.1 9.79922 21 11.9992 21C14.1992 21 16.4992 20.1 18.1992 18.4C21.5992 15 21.5992 9.5 18.1992 6.1Z"
      fill={color}
    />
    <path d="M8.8 10.3L7.8 10.6L7.5 9.5L9.2 9H10.1V14.9H8.8V10.3Z" fill={color} />
    <path
      d="M11.3008 12C11.3008 10.3 12.4008 9 13.9008 9C15.4008 9 16.5008 10.3 16.5008 12C16.5008 13.7 15.5008 15 13.9008 15C12.3008 15 11.3008 13.7 11.3008 12ZM15.2008 12C15.2008 10.9 14.7008 10.1 13.9008 10.1C13.1008 10.1 12.6008 10.9 12.6008 12C12.6008 13.1 13.1008 13.9 13.9008 13.9C14.7008 13.9 15.2008 13.1 15.2008 12Z"
      fill={color}
    />
  </svg>
);
