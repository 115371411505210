import * as React from 'react';
import { IconSVGProps } from '../../../../../types/iconTypes';

export default ({ color = '#1A1A1A' }: IconSVGProps): React.ReactElement => (
  <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M21 5V19H3V5H21ZM22 4H2V20H22V4Z" fill={color} />
    <path
      d="M8.17938 9H9.33938L11.8994 15H10.5894L9.99937 13.64H7.44937L6.88937 15H5.60938L8.17938 9ZM9.57937 12.53L8.73937 10.53L7.89937 12.53H9.57937Z"
      fill={color}
    />
    <path
      d="M12.9199 8.99994H15.2199C15.628 8.97678 16.0364 9.03723 16.4202 9.17759C16.8041 9.31795 17.1552 9.53526 17.452 9.81618C17.7489 10.0971 17.9852 10.4357 18.1464 10.8112C18.3077 11.1867 18.3906 11.5913 18.3899 11.9999C18.3906 12.4086 18.3077 12.8131 18.1464 13.1887C17.9852 13.5642 17.7489 13.9028 17.452 14.1837C17.1552 14.4646 16.8041 14.6819 16.4202 14.8223C16.0364 14.9626 15.628 15.0231 15.2199 14.9999H12.9199V8.99994ZM14.1799 10.1399V13.8199H15.1799C15.4276 13.8417 15.6771 13.8101 15.9115 13.7273C16.1459 13.6445 16.3599 13.5124 16.539 13.34C16.7181 13.1675 16.8582 12.9587 16.9497 12.7275C17.0413 12.4964 17.0823 12.2483 17.0699 11.9999C17.0814 11.7544 17.0414 11.5092 16.9523 11.28C16.8633 11.0509 16.7273 10.843 16.553 10.6697C16.3787 10.4963 16.17 10.3614 15.9404 10.2736C15.7108 10.1858 15.4654 10.1471 15.2199 10.1599L14.1799 10.1399Z"
      fill={color}
    />
  </svg>
);
