import React, { useState, useRef, FunctionComponent } from 'react';
import { ButtonType } from '../../../types';
import { IButtonProps } from '../../../types/buttonTypes';
import {
  ContentContainer,
  TitleContainer,
  TitleContainerMobile,
  SubtitleContainer,
  ButtonsContainer,
  TextContainer,
  HoverContainer,
  CardContainer,
  ProgressContainer,
} from './Card.style';
import ImageAspectRatioWrapper from '../../atoms/ImageAspectRatioWrapper';
import { PrimaryButton, Header, SecondaryButton, TertiaryButton } from '../../atoms';
import { AspectRatio } from '../../../types';
import { ICardProps } from '../../../types/card';
import Progress from '../../atoms/Progress';
import TypeTags from '../../atoms/TypeTags';
import { Colors, INodeProps } from '../../../types/types';
import { truncateHtmlString } from '../../../helpers/htmlStrings';

const LENGTH_LARGE_TEXT = 28;
const LENGTH_SMALL_TEXT = 19;

const buttonTypeToButton = {
  [ButtonType.Primary]: PrimaryButton,
  [ButtonType.Secondary]: SecondaryButton,
  [ButtonType.Tertiary]: TertiaryButton,
};

const Card: FunctionComponent<ICardProps> = ({
  progress = 0,
  title = '',
  subtitle,
  text = '',
  links,
  tags = [],
  image,
  imageAltText = '',
  withContinueWatching = false,
  size = 'small',
  fullWidth = false,
  onlyShowButtonsOnHover = true,
  hoverLineColor = Colors.Core,
}) => {
  const truncate = (str: string, n: number) => (str.length >= n ? str.substr(0, n) : str);

  const node: INodeProps = useRef();
  const [hovered, setHovered] = useState(false);

  const truncatedText = truncateHtmlString(text, 185, true);
  const firstButton = links?.[0];
  const { text: firstButtonText = '', ...restFirstButton } = firstButton || {};
  const primaryButtonTextTruncate =
    size === 'small' ? truncate(firstButtonText, LENGTH_SMALL_TEXT) : truncate(firstButtonText, LENGTH_LARGE_TEXT);

  const secondButton = links?.[1];
  const { text: secondButtonText = '', ...restSecondButton } = secondButton || {};
  const tertiaryButtonTextTruncate =
    size === 'small' ? truncate(secondButtonText, LENGTH_SMALL_TEXT) : truncate(secondButtonText, LENGTH_LARGE_TEXT);

  const hoverHandler = (value: boolean) => {
    if (value) {
      node.current.style.opacity = '1';
      node.current.style.transition = 'opacity 0.3s';
    } else {
      node.current.style.opacity = '0';
      node.current.style.transition = 'opacity 0.3s';
    }

    setHovered(value);
  };

  const FirstButtonComponent = firstButton?.buttonType
    ? (buttonTypeToButton[firstButton.buttonType] as FunctionComponent<IButtonProps>)
    : PrimaryButton;

  const SecondButtonComponent = secondButton?.buttonType
    ? (buttonTypeToButton[secondButton.buttonType] as typeof TertiaryButton)
    : TertiaryButton;

  return (
    <CardContainer
      onMouseOver={() => (onlyShowButtonsOnHover ? hoverHandler(true) : undefined)}
      onMouseLeave={() => (onlyShowButtonsOnHover ? hoverHandler(false) : undefined)}
      onlyShowButtonsOnHover={onlyShowButtonsOnHover}
      data-testid="cardcontainer"
    >
      {' '}
      <a
        href={firstButton?.href}
        target={firstButton?.target}
        className="targetLink"
        style={{
          flexGrow: onlyShowButtonsOnHover ? 'unset' : 1,
        }}
      >
        {hovered && <HoverContainer lineColor={hoverLineColor} data-testid="hovercontainer" />}
        <ImageAspectRatioWrapper aspectRatio={AspectRatio['4:3']}>
          <img src={image} alt={imageAltText} />
        </ImageAspectRatioWrapper>
        {withContinueWatching && (
          <ProgressContainer>
            <Progress progress={progress} />
          </ProgressContainer>
        )}
        <ContentContainer fullWidth={fullWidth}>
          {tags ? <TypeTags list={tags} /> : null}
          <TitleContainer>
            <Header level={size === 'small' ? 6 : 5}>{title}</Header>
          </TitleContainer>
          <TitleContainerMobile>
            <Header level={6}>{title}</Header>
          </TitleContainerMobile>
          {subtitle ? <SubtitleContainer>{subtitle}</SubtitleContainer> : null}
          <TextContainer dangerouslySetInnerHTML={{ __html: truncatedText }} />
        </ContentContainer>
      </a>
      {links && (
        <ButtonsContainer
          ref={node}
          data-testid="buttonscontainer"
          size={size}
          primaryButtonTextLength={firstButtonText.length}
          tertiaryButtonTextLength={secondButtonText.length}
          onlyShowButtonsOnHover={onlyShowButtonsOnHover}
          fullWidth={fullWidth}
        >
          {firstButton && <FirstButtonComponent {...restFirstButton}>{primaryButtonTextTruncate}</FirstButtonComponent>}
          {secondButton && (
            <SecondButtonComponent {...restSecondButton}>{tertiaryButtonTextTruncate}</SecondButtonComponent>
          )}
        </ButtonsContainer>
      )}
    </CardContainer>
  );
};

export default Card;
