import * as React from 'react';
import { IconSVGProps } from '../../../../../types/iconTypes';

export default ({ color = '#1A1A1A' }: IconSVGProps): React.ReactElement => (
  <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18 18V10C18 6.9 15.6 4.3 12.5 4V2H11.5V4C8.4 4.3 6 6.9 6 10V18H4V19H6H9C9 20.7 10.3 22 12 22C13.7 22 15 20.7 15 19H18H20V18H18ZM12 21C10.9 21 10 20.1 10 19H14C14 20.1 13.1 21 12 21ZM15 18H9H7V10C7 7.2 9.2 5 12 5C14.8 5 17 7.2 17 10V18H15Z"
      fill={color}
    />
  </svg>
);
