import styled from 'styled-components';
import { Grid, GridItem } from '../../atoms/Grid';
import { devices } from '../../../styles/viewports';
import zIndexes from '../../../styles/zIndexes';
import { INavigationWrapperProps } from '../../../types/navigation';

export const NavigationWrapper = styled.div<INavigationWrapperProps>`
  @media ${devices.mobileAndTablet} {
    ${({ isMenuOpen }) =>
      isMenuOpen &&
      `
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: ${zIndexes.overlay};
      background-color: var(--base-color-white);
      overflow-y: auto;
      scrollbar-width: none;
      ::-webkit-scrollbar {
        display: none;
      }
    `}
  }
`;

export const FullScreenContainer = styled.div`
  background-color: var(--base-color-black);
  width: 100vw;
  position: absolute;
  z-index: ${zIndexes.searchOverlay};
  opacity: 0.4;
`;

export const NavigationGrid = styled(Grid)`
  position: relative;
  background-color: var(--base-color-white);
  height: 140px;
  z-index: ${zIndexes.navigation};

  @media ${devices.mobile} {
    height: 80px;
  }
`;

export const SearchBackground = styled.div`
  background-color: var(--base-color-white);
  height: 100px;
  border-top: 1px solid var(--base-color-light-grey);
  width: 100%;
  position: absolute;
  top: 140px;
  z-index: ${zIndexes.search};

  @media ${devices.mobile} {
    height: 100%;
    top: 80px;
  }
`;

export const GridItemSearch = styled(GridItem)`
  background-color: var(--base-color-white);
  border-top: 1px solid var(--base-color-light-grey);
  width: 100%;
  position: absolute;
  top: 140px;
  z-index: ${zIndexes.search};
  height: 100px;

  @media ${devices.mobile} {
    height: 100%;
    top: 80px;
  }
`;

export const NavigationGridMobile = styled(Grid)`
  display: none;

  @media ${devices.mobileAndTablet} {
    display: grid;
    background-color: var(--base-color-white);
    padding-top: ${({ theme }) => theme.spacing[12]};
    padding-bottom: var(--navigation-large-margin);
  }
`;

export const LogoContainer = styled.div`
  height: 140px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &&& .logoImg {
    height: 96px;
    width: 66px;
  }

  @media ${devices.mobile} {
    height: 80px;

    &&& .logoImg {
      width: 100%;
      height: 64px;
    }
  }
`;

export const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 140px;

  @media ${devices.mobileAndTablet} {
    flex-direction: row;
    height: 80px;
    justify-content: flex-end;
  }
`;

export const NavContainer = styled.div`
  height: 40px;
  margin-top: auto;

  @media ${devices.mobileAndTablet} {
    background-color: var(--base-color-white);
    height: auto;
    margin-top: 0px;
  }
`;

export const NavContainerGridItem = styled(GridItem)`
  @media ${devices.desktop} {
    grid-column: 3 / span 14;
  }
`;

export const NavTopContainer = styled.div`
  height: 26px;
  right: 50px;
  top: 20px;
  position: absolute;

  @media ${devices.mobileAndTablet} {
    margin-top: 1px;
    position: inherit;
  }
`;
