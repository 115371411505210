import React, { FunctionComponent } from 'react';
import PrimaryButtonWrapper from './PrimaryButtonWrapper.style';
import { Colors } from '../../../../types';
import { IPrimaryButtonProps } from '../../../../types/buttonTypes';

const PrimaryButton: FunctionComponent<IPrimaryButtonProps> = ({ children, ...props }) => {
  return (
    <PrimaryButtonWrapper color={Colors.White} {...props}>
      {children}
    </PrimaryButtonWrapper>
  );
};

export default PrimaryButton;
