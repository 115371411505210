import { ReactElement } from 'react';
import { StyledProps } from 'styled-components';

export enum AspectRatio {
  '1:1' = '1 / 1',
  '3:4' = '3 / 4',
  '4:3' = '4 / 3',
  '16:9' = '16 / 9',
}

export enum AspectRatioLegacy {
  '1 / 1' = '100',
  '3 / 4' = '133',
  '4 / 3' = '75',
  '16 / 9' = '56.25',
}

export enum AspectRatioWidth {
  '1 / 1' = '1',
  '3 / 4' = '0.75',
  '4 / 3' = '1.33',
  '16 / 9' = '1.78',
}

export interface IImageAspectRatioWrapperProps {
  /**
   * Aspect ratio of the contained image
   */
  aspectRatio?: AspectRatio;
}

export interface IImageWithCaptionProps {
  /**
   * Aspect ratio of the contained image
   */
  aspectRatio?: AspectRatio;
  /**
   * Image caption or credit
   */
  caption: string;
  /**
   * Image element
   */
  children: ReactElement<HTMLImageElement>;
}

export interface IImageWithCaptionWrapperProps extends StyledProps<any> {
  /**
   * Aspect ratio of the contained image
   */
  aspectRatio?: AspectRatio;
  /**
   * Height of the wrapper
   */
  height: number;
}
