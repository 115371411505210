import * as React from 'react';
import { IconSVGProps } from '../../../../../types/iconTypes';

export default ({ color = '#1A1A1A' }: IconSVGProps): React.ReactElement => (
  <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9 7H7V8H9V7Z" fill={color} />
    <path d="M17 7H11V8H17V7Z" fill={color} />
    <path d="M9 12H7V13H9V12Z" fill={color} />
    <path d="M17 12H11V13H17V12Z" fill={color} />
    <path d="M17 9.5H15V10.5H17V9.5Z" fill={color} />
    <path d="M13 9.5H7V10.5H13V9.5Z" fill={color} />
    <path
      d="M19 3H5C4.46957 3 3.96086 3.21071 3.58579 3.58579C3.21071 3.96086 3 4.46957 3 5V15C3 15.5304 3.21071 16.0391 3.58579 16.4142C3.96086 16.7893 4.46957 17 5 17H11.3L17 21V17H19C19.5304 17 20.0391 16.7893 20.4142 16.4142C20.7893 16.0391 21 15.5304 21 15V5C21 4.46957 20.7893 3.96086 20.4142 3.58579C20.0391 3.21071 19.5304 3 19 3ZM20 15C20.0088 15.1336 19.989 15.2676 19.9419 15.3929C19.8948 15.5182 19.8214 15.632 19.7267 15.7267C19.632 15.8214 19.5182 15.8948 19.3929 15.9419C19.2676 15.989 19.1336 16.0088 19 16H16V19L13.1 17L12.9 16.8L11.7 16H5C4.86639 16.0088 4.73244 15.989 4.6071 15.9419C4.48177 15.8948 4.36795 15.8214 4.27327 15.7267C4.17859 15.632 4.10523 15.5182 4.0581 15.3929C4.01097 15.2676 3.99116 15.1336 4 15V5C3.99116 4.86639 4.01097 4.73244 4.0581 4.6071C4.10523 4.48177 4.17859 4.36795 4.27327 4.27327C4.36795 4.17859 4.48177 4.10523 4.6071 4.0581C4.73244 4.01097 4.86639 3.99116 5 4H19C19.1336 3.99116 19.2676 4.01097 19.3929 4.0581C19.5182 4.10523 19.632 4.17859 19.7267 4.27327C19.8214 4.36795 19.8948 4.48177 19.9419 4.6071C19.989 4.73244 20.0088 4.86639 20 5V15Z"
      fill={color}
    />
  </svg>
);
