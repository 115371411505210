import styled, { css } from 'styled-components';
import { ITextFieldProps } from '../../../types/formTypes';
import { Theme } from '../../../types/utility';

export const TextFieldContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const textStyles = css`
  font-family: var(--font-family-body);
  font-weight: ${({ theme }) => (theme as Theme).fonts.desktop.weights.body[1]};
  font-size: ${({ theme }) => (theme as Theme).fonts.desktop.sizes.altHeaders[6]};
  line-height: ${({ theme }) => (theme as Theme).spacing[7]};
`;

export const Input = styled.input<Pick<ITextFieldProps, 'error' | 'darkMode'>>`
  ${textStyles}

  width: 196px;
  height: 48px;
  padding: 0px 20px;

  border: 1px solid ${({ theme }) => (theme as Theme).colors.darkgrey};
  outline: none;

  ${({ error, theme }) => {
    if (!error) return;
    return `
      border: 1px solid ${(theme as Theme).colors.error};
    `;
  }}

  :disabled {
    background-color: ${({ theme }) => (theme as Theme).colors.lightgrey};
    border: 1px solid transparent;
    outline: none;
  }

  :focus {
    outline: ${({ darkMode, theme }) => {
      if (darkMode) return `4px solid ${(theme as Theme).colors.lemonChiffon}`;
      return `3px solid ${(theme as Theme).colors.lapisLazuli}`;
    }}};
  }
`;

export const TextLabel = styled.p<Pick<ITextFieldProps, 'darkMode'>>`
  ${textStyles};

  color: ${({ darkMode, theme }) => (darkMode ? (theme as Theme).colors.white : (theme as Theme).colors.black)};
`;

export const ErrorLabel = styled.p<Pick<ITextFieldProps, 'darkMode'>>`
  ${textStyles};

  color: ${({ darkMode, theme }) => (darkMode ? (theme as Theme).colors.white : (theme as Theme).colors.error)};
`;
