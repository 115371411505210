import styled from 'styled-components';
import { devices } from '../../../../styles/viewports';
import { Grid } from '../../../atoms/Grid';

interface HighlightsInfoWrapperProps {
  hasImages: boolean;
}

interface HighlightsCarouselWrapperProps {
  hasMultipleImages: boolean;
}

export const HighlightsGrid = styled(Grid)`
  position: relative;
  grid-template-rows: max-content minmax(0, 1fr);
  gap: 0 var(--grid-column-gap);
  background-color: var(--base-color-black);
  color: var(--base-color-white);
  user-select: none;

  @media ${devices.mobile} {
    & {
      grid-template-rows: min-content max-content max-content;
    }
  }
`;

export const HighlightTitleWrapper = styled.div`
  user-select: text;
  h3 {
    padding: 0;
    margin: 0 0 8px 0;
  }

  @media ${devices.tablet} {
    & {
      h3 {
        font-size: ${({ theme }) => theme.fonts.mobile.sizes.headers[3]};
        font-weight: ${({ theme }) => theme.fonts.mobile.weights.headers[3]};
        letter-spacing: ${({ theme }) => theme.fonts.mobile.letterSpacing.headers[3]};
        line-height: ${({ theme }) => theme.fonts.mobile.lineHeights.headers[3]};
      }
    }
  }
`;

export const HighlightTextWrapper = styled.div`
  margin-top: 24px;
  user-select: text;

  @media ${devices.tablet} {
    & {
      margin-top: 20px;

      > div {
        font-size: ${({ theme }) => theme.fonts.mobile.sizes.body[1]};
        font-weight: ${({ theme }) => theme.fonts.mobile.weights.body[1]};
        letter-spacing: ${({ theme }) => theme.fonts.mobile.letterSpacing.body[1]};
        line-height: ${({ theme }) => theme.fonts.mobile.lineHeights.body[1]};
      }
    }
  }

  @media ${devices.mobile} {
    & {
      margin-top: 20px;
    }
  }
`;

export const HighlightsInfoWrapper = styled.div<HighlightsInfoWrapperProps>`
  grid-area: ${({ hasImages }) => (hasImages ? '1 / 1 / 3 / 7' : '1 / 1 / 3 / 12')};
  box-sizing: border-box;
  width: calc(100% + var(--grid-column-gap));
  align-self: center;
  padding: 36px 36px 36px 50px;

  @media ${devices.mobile} {
    & {
      grid-area: ${({ hasImages }) => (hasImages ? '3 / 1 / 4 / 15' : '1 / 1 / 3 / 15')};
      width: 100%;
      padding: 20px;
    }
  }
`;

export const HighlightsCarouselWrapper = styled.div<HighlightsCarouselWrapperProps>`
  grid-area: 1 / 7 / 3 / 17;
  position: relative;
  height: 100%;
  width: 100%;
  background-color: var(--base-color-black);

  .swiper-slide {
    ${({ hasMultipleImages }) =>
      hasMultipleImages
        ? 'width: calc(100% - 50px - var(--rotator-button-width)); -webkit-transform: translate3d(0, 0, 0);'
        : 'width: 100%'}
  }

  @media ${devices.tablet} {
    & {
      grid-area: 2 / 7 / 3 / 15;

      .swiper-slide {
        width: 100%;
        -webkit-transform: translate3d(0, 0, 0);
      }
    }
  }

  @media ${devices.mobile} {
    & {
      grid-area: 2 / 1 / 3 / 15;

      .swiper-slide {
        width: 100%;
        -webkit-transform: translate3d(0, 0, 0);
      }
    }
  }
`;

export const MobileRotatorButtonsWrapper = styled.div`
  div {
    padding-top: 8px;
  }

  @media ${devices.desktop}, ${devices.largeDesktop} {
    display: none;
  }

  @media ${devices.mobileAndTablet} {
    .carousel-icon-wrapper-left,
    .carousel-icon-wrapper-right {
      background-color: transparent;

      svg path {
        fill: var(--base-color-lightgrey);
      }
    }

    .carousel-icon-wrapper-left {
      margin-right: 8px;
    }
  }

  @media ${devices.mobile} {
    grid-area: 1 / 2;
    height: 56px;

    div {
      padding-top: 6px;
    }
  }

  @media ${devices.tablet} {
    grid-area: 1 / 13;
    height: 80px;

    div {
      padding-top: 8px;
    }
  }
`;

export const RotatorButtonsWrapper = styled.div`
  position: absolute;
  right: 50px;
  top: calc(50% - calc(var(--rotator-button-width) / 2));
  z-index: 2;

  @media ${devices.mobileAndTablet} {
    & {
      display: none;
    }
  }
`;
