import * as React from 'react';
import { IconSVGProps } from '../../../../../types/iconTypes';

export default ({ color = '#1A1A1A' }: IconSVGProps): React.ReactElement => (
  <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 4.69994L13.88 8.51994L14.12 8.98994L14.64 9.06994L18.85 9.67994L15.8 12.6799L15.43 13.0499L15.51 13.5599L16.23 17.7599L12.47 15.7599L12 15.5099L11.53 15.7499L7.77 17.7499L8.49 13.5499L8.57 13.0399L8.2 12.6699L5.15 9.66994L9.36 9.05994L9.88 8.97994L10.12 8.50994L12 4.69994ZM12 2.43994L9.22 8.07994L3 8.99994L7.5 13.3899L6.44 19.5799L12 16.6399L17.56 19.5599L16.5 13.3699L21 8.99994L14.78 8.09994L12 2.43994Z"
      fill={color}
    />
  </svg>
);
