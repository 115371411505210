import styled from 'styled-components';
import { ILogosStyledProps } from '../../../types/types';

export const BadgeWrapper = styled.svg<ILogosStyledProps>`
  width: auto;

  g,
  path {
    fill: var(--base-color-${({ fillColor }) => fillColor});
  }
`;
