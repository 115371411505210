import React, { FunctionComponent } from 'react';
import { TertiaryButtonWrapper, TertiaryIconWrapper } from './TertiaryButton.style';
import { Icon } from '../../Icons';
import { IButtonProps } from '../../../../types/buttonTypes';

const Button: FunctionComponent<IButtonProps> = ({ children, color, ...rest }) => {
  const truncatedString = (children as string).substring(0, 100);
  return (
    <TertiaryButtonWrapper {...rest} color={color}>
      {truncatedString}
      <TertiaryIconWrapper data-testid="tertiary-icon">
        <Icon iconName="Arrow" color={color} />
      </TertiaryIconWrapper>
    </TertiaryButtonWrapper>
  );
};

export default Button;
