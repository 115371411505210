import styled from 'styled-components';
import { devices } from '../../../styles/viewports';

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

export const IconWrapper = styled.div`
  justify-content: center;
  display: flex;
  align-items: center;
  width: var(--rotator-button-width);
  height: var(--rotator-button-width);
  background-color: var(--base-color-lightgrey);
  color: var(--base-color-black);

  svg {
    width: var(--rotator-button-icon-width);
    height: var(--rotator-button-icon-width);
  }

  svg path {
    fill: var(--base-color-black);
  }

  :hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.primary};

    && svg path {
      fill: var(--base-color-white);
    }
  }

  @media ${devices.mobile} {
    background-color: var(--base-color-black);

    && svg path {
      fill: var(--base-color-white);
    }

    :hover {
      cursor: default;
      background-color: var(--base-color-black);

      && svg path {
        fill: var(--base-color-white);
      }
    }
  }
`;

export const IconUnavailableWrapper = styled.div`
  justify-content: center;
  display: flex;
  align-items: center;
  width: var(--navigation-large-margin);
  height: var(--navigation-large-margin);
  background-color: var(--base-color-lightgrey);
  color: var(--base-color-black);
  opacity: 0.2;

  svg {
    width: var(--navigation-xlarge-gap);
    height: var(--navigation-xlarge-gap);
  }

  svg path {
    fill: var(--base-color-black);
  }

  @media ${devices.mobile} {
    opacity: 1;
    pointer-events: none;
    background-color: var(--base-color-black);

    && svg path {
      fill: var(--base-color-white);
    }
  }
`;
