import * as React from 'react';
import { IconSVGProps } from '../../../../../types/iconTypes';

export default ({ color = '#1A1A1A' }: IconSVGProps): React.ReactElement => (
  <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 12C20.1 12 20.6 16.6 20.9 20H3.1C3.4 16.6 3.9 12 12 12ZM12 11C2 11 2.4 17.7 2 21H22C21.6 17.7 22 11 12 11Z"
      fill={color}
    />
    <path
      d="M12 3C13.7 3 15 4.3 15 6C15 7.7 13.7 9 12 9C10.3 9 9 7.7 9 6C9 4.3 10.3 3 12 3ZM12 2C9.8 2 8 3.8 8 6C8 8.2 9.8 10 12 10C14.2 10 16 8.2 16 6C16 3.8 14.2 2 12 2Z"
      fill={color}
    />
  </svg>
);
