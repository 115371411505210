import * as React from 'react';
import { IconSVGProps } from '../../../../../types/iconTypes';

export default ({ color = '#1A1A1A' }: IconSVGProps): React.ReactElement => (
  <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7 2H6V5H7V2Z" fill={color} />
    <path d="M4 8V9L20 9V8L4 8Z" fill={color} />
    <path d="M18 2H17V5H18V2Z" fill={color} />
    <path d="M14.3008 2H13.3008V5H14.3008V2Z" fill={color} />
    <path d="M15.3008 8H14.3008V20H15.3008V8Z" fill={color} />
    <path d="M9.69922 8H8.69922V20H9.69922V8Z" fill={color} />
    <path d="M10.6992 2H9.69922V5H10.6992V2Z" fill={color} />
    <path d="M21 17V16L3 16V17L21 17Z" fill={color} />
    <path d="M21 13V12L3 12V13L21 13Z" fill={color} />
    <path
      d="M20 4V19C20 19.6 19.6 20 19 20H5C4.4 20 4 19.6 4 19V4H20ZM21 3H3V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V3Z"
      fill={color}
    />
  </svg>
);
