import * as React from 'react';
import { IconSVGProps } from '../../../../../types/iconTypes';

export default ({ color = '#1A1A1A' }: IconSVGProps): React.ReactElement => (
  <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M22 12.1L12 4L2 12.1L2.6 12.9L4.8 11.1V18C4.8 19.1 5.7 20 6.8 20H9.6C10.1 20 10.5 19.6 10.5 19.1V15.3C10.5 14.9 10.7 14.5 11 14.2C11.3 13.9 11.7 13.8 12.1 13.8C12.9 13.8 13.5 14.6 13.5 15.4V19.1C13.5 19.6 13.9 20 14.4 20H17.2C18.3 20 19.2 19.1 19.2 18V11.1L21.4 12.9L22 12.1ZM18.2 18C18.2 18.6 17.8 19 17.2 19H14.6V15.4C14.6 14 13.5 12.8 12.2 12.8C11.5 12.8 10.8 13 10.3 13.5C9.8 14 9.5 14.6 9.5 15.3V19H6.8C6.2 19 5.8 18.5 5.8 18V10.3L12 5.3L18.2 10.3V18Z"
      fill={color}
    />
  </svg>
);
