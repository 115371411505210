import styled from 'styled-components';

export const LogoWrapper = styled.div`
  width: 66px;
  height: auto;
  cursor: pointer;
  & img {
    width: auto;
    height: 100%;
  }
`;
