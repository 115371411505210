import * as React from 'react';
import { IconSVGProps } from '../../../../../types/iconTypes';

export default ({ color = '#1A1A1A' }: IconSVGProps): React.ReactElement => (
  <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.0996 8V7C15.9996 4.8 14.1996 3 11.9996 3C9.69961 3 7.89961 4.8 7.89961 7V8H3.09961L5.19961 18.8C5.39961 20.1 6.49961 21 7.79961 21H16.1996C17.4996 21 18.5996 20.1 18.7996 18.8L20.8996 8H16.0996ZM8.89961 7C8.89961 5.4 10.2996 4 11.9996 4C13.5996 4 15.0996 5.4 15.0996 7V8H8.89961V7ZM17.8996 18.6C17.6996 19.4 16.9996 20 16.1996 20H7.79961C6.99961 20 6.29961 19.4 6.19961 18.6L4.29961 9H7.89961H16.0996H19.6996L17.8996 18.6Z"
      fill={color}
    />
  </svg>
);
