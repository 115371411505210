import styled from 'styled-components';
import { devices } from '../../../styles/viewports';

export const FooterSection = styled.section`
  background-color: var(--base-color-black);
  color: var(--base-color-white);
`;

export const FooterContainer = styled.div`
  height: var(--footer-height);
  padding-top: var(--footer-padding-top);
  padding-bottom: var(--footer-padding-bottom);
  display: grid;
  grid-template-rows:
    min-content var(--footer-vertical-spacing-lg) min-content var(--footer-vertical-spacing-lg) min-content var(
      --footer-vertical-spacing-sm
    )
    min-content;
  grid-template-areas:
    'row1'
    '.'
    'row2'
    '.'
    'row3'
    '.'
    'row4';
`;

export const PolicyLinksRow = styled.div`
  grid-area: row1;
  justify-self: center;

  @media ${devices.mobile} {
    & {
      justify-self: start;
      grid-area: row2;
    }
  }
`;

export const ContactNewsletterRow = styled.div`
  grid-area: row2;

  @media ${devices.mobile} {
    & {
      grid-area: row1;
    }
  }
`;

export const ArtsLogoRow = styled.div`
  grid-area: row3;
  justify-self: center;

  @media ${devices.mobile} {
    justify-self: start;
  }
`;

export const AdditionalInfo = styled.p`
  grid-area: row4;
  font-family: var(--font-family-body);
  font-size: var(--font-size-body-3);
  line-height: var(--line-height-body-3);
  text-align: center;
  margin: 0;
  padding: 0;

  @media ${devices.mobile} {
    text-align: left;
  }
`;
