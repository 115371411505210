import * as React from 'react';
import { IconSVGProps } from '../../../../../types/iconTypes';

export default ({ color = '#1A1A1A' }: IconSVGProps): React.ReactElement => (
  <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14 2H6C4.9 2 4 2.9 4 4V20C4 21.1 4.9 22 6 22H18C19.1 22 20 21.1 20 20V8L14 2ZM14.5 3.9L18.1 7.5H14.5V3.9ZM18 21H6C5.4 21 5 20.6 5 20V4C5 3.4 5.4 3 6 3H13.6H13.5V8.5H19V20C19 20.6 18.6 21 18 21Z"
      fill={color}
    />
    <path
      d="M8 12H9.2C9.9 12 10.3 12.4 10.3 13C10.3 13.7 9.8 14 9.2 14H8.6V14.8H8V12ZM9.1 13.4C9.4 13.4 9.6 13.2 9.6 13C9.6 12.7 9.4 12.6 9.1 12.6H8.6V13.5H9.1V13.4Z"
      fill={color}
    />
    <path
      d="M10.6992 12H11.7992C12.6992 12 13.2992 12.6 13.2992 13.4C13.2992 14.2 12.6992 14.8 11.7992 14.8H10.6992V12ZM11.7992 14.3C12.2992 14.3 12.6992 14 12.6992 13.5C12.6992 13 12.3992 12.6 11.7992 12.6H11.2992V14.3H11.7992Z"
      fill={color}
    />
    <path d="M13.9004 12H16.0004V12.6H14.5004V13.2H15.8004V13.8H14.5004V14.9H13.9004V12Z" fill={color} />
  </svg>
);
