import * as React from 'react';
import { IconSVGProps } from '../../../../../types/iconTypes';

export default ({ color = '#1A1A1A' }: IconSVGProps): React.ReactElement => (
  <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M22 11V10C20.9391 10 19.9217 9.57857 19.1715 8.82843C18.4214 8.07828 18 7.06087 18 6H17V14.78C16.4875 14.3216 15.833 14.0532 15.1463 14.0197C14.4595 13.9862 13.7821 14.1896 13.2273 14.5958C12.6726 15.0021 12.2743 15.5866 12.0991 16.2515C11.9238 16.9164 11.9823 17.6213 12.2647 18.2482C12.5471 18.8751 13.0363 19.386 13.6504 19.6954C14.2644 20.0047 14.9661 20.0937 15.638 19.9475C16.3098 19.8013 16.9111 19.4287 17.3411 18.8921C17.771 18.3556 18.0037 17.6876 18 17V9C18.4657 9.62098 19.0696 10.125 19.7639 10.4721C20.4582 10.8193 21.2237 11 22 11V11ZM15 19C14.6044 19 14.2177 18.8827 13.8888 18.6629C13.5599 18.4432 13.3036 18.1308 13.1522 17.7654C13.0008 17.3999 12.9612 16.9978 13.0384 16.6098C13.1156 16.2219 13.306 15.8655 13.5857 15.5858C13.8655 15.3061 14.2218 15.1156 14.6098 15.0384C14.9977 14.9613 15.3999 15.0009 15.7653 15.1522C16.1308 15.3036 16.4431 15.56 16.6629 15.8889C16.8827 16.2178 17 16.6044 17 17C17 17.5304 16.7892 18.0391 16.4142 18.4142C16.0391 18.7893 15.5304 19 15 19Z"
      fill={color}
    />
    <path d="M22 4.02979H2V5.02979H22V4.02979Z" fill={color} />
    <path d="M11 19.0298H2V20.0298H11V19.0298Z" fill={color} />
    <path d="M22 19.0298H19V20.0298H22V19.0298Z" fill={color} />
    <path d="M11 14.0298H2V15.0298H11V14.0298Z" fill={color} />
    <path d="M22 14.0298H19V15.0298H22V14.0298Z" fill={color} />
    <path d="M15 9.02979H2V10.0298H15V9.02979Z" fill={color} />
  </svg>
);
