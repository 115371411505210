import { ImgHTMLAttributes, ReactElement } from 'react';
import { ICardProps } from './card';
import { EditorialLink } from './editorial';
import { IImageWithCaptionProps } from './image';
import { ILogosProps } from './types';

export enum CarouselType {
  Image = 'image',
  SmallCard = 'SmallCard',
  LargeCard = 'LargeCard',
}

export interface ICarouselProps {
  /**
   * Carousel title
   */
  title: string;
  /**
   * Carousel type.
   */
  type: CarouselType;
  /**
   * Only for image type. It sets the height in pixels of the slides in devices(default 300)
   */
  imagesHeightDevice?: number;
  /**
   * Only for image type. It sets the height in pixels of the slides in desktop(default 500)
   */
  imagesHeightDesktop?: number;
  /**
   * Carousel children (only Card and ImageAspectRatioWrapper children are allowed).
   */
  children: ReactElement<ICardProps | IImageWithCaptionProps>[];
}

export interface ICarouselWrapperProps {
  /**
   * Carousel has been activated or not
   */
  active: boolean;
  /**
   * Carousel type.
   */
  type: CarouselType;
  /**
   * Only for image type. It sets the height in pixels of the slides in devices(default 300)
   */
  imagesHeightDevice?: number;
  /**
   * Only for image type. It sets the height in pixels of the slides in desktop(default 500)
   */
  imagesHeightDesktop?: number;
}

export type HighlightsCarouselSlideImage = ImgHTMLAttributes<HTMLImageElement> & {
  key: string;
};

export type HighlightsCarouselSlide = {
  /**
   * heder in info section
   */
  infoTitle: string;
  /**
   * Timeframe subtitle in info section
   */
  infoTimeframe: string;
  /**
   * text subtitle in info section
   */
  infoSubtitle?: string;
  /**
   * text in info section
   */
  infoText?: string;
  /**
   * Link in info section
   */
  links: EditorialLink[];
  /**
   * Slide image
   */
  image: HighlightsCarouselSlideImage;
};

export interface IHighlightsCarouselProps {
  /**
   * Carousel title
   */
  carouselTitle?: string;
  /**
   * Logo element
   */
  logo?: ReactElement<ILogosProps> | null;
  /**
   * slides to be displayed in the carousel
   */
  slides: HighlightsCarouselSlide[];
}

export interface IHighlightsCoreCarouselProps extends Omit<IHighlightsCarouselProps, 'logo'> {}
export interface IHighlightsCinemaCarouselProps extends Omit<IHighlightsCarouselProps, 'logo'> {
  /**
   * Renders logo or not
   */
  logo: boolean;
}
export interface IHighlightsStreamCarouselProps extends Omit<IHighlightsCarouselProps, 'logo'> {
  /**
   * Renders logo or not
   */
  logo: boolean;
}
