import styled from 'styled-components';
import { devices } from '../../../styles/viewports';

const SectionTitleWrapper = styled.div`
  margin: 30px 0;

  & > div {
    row-gap: 0;
  }

  & h1,
  & h2 {
    margin: 16px 0;
    white-space: pre-wrap;
  }

  @media ${devices.mobile} {
    & h1,
    & h2 {
      margin: 12px 0;
    }
  }

  & div {
    font-size: var(--font-size-title-description);
    line-height: var(--line-height-title-description);
    margin-block-start: 0;
  }
`;

export default SectionTitleWrapper;
