import * as React from 'react';
import { IconSVGProps } from '../../../../../types/iconTypes';

export default ({ color = '#1A1A1A' }: IconSVGProps): React.ReactElement => (
  <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.71 13.9001C18.3138 15.3804 17.4428 16.6898 16.2307 17.6273C15.0186 18.5648 13.5323 19.0787 12 19.0901C10.2633 19.0541 8.59637 18.3999 7.29868 17.2452C6.00098 16.0905 5.15758 14.5108 4.92 12.7901L6.64 14.2901L7.21 13.5701L4.42 11.1001L2 14.0001L2.7 14.5901L4.17 12.8301C4.33413 14.6921 5.15733 16.4345 6.49149 17.7437C7.82566 19.0529 9.58323 19.8431 11.448 19.9721C13.3128 20.1011 15.1624 19.5604 16.6641 18.4473C18.1658 17.3342 19.2211 15.7218 19.64 13.9001H18.71Z"
      fill={color}
    />
    <path
      d="M21.2994 9.44987L19.8294 11.2099C19.6738 9.34515 18.8572 7.59736 17.5268 6.28151C16.1964 4.96566 14.4397 4.16832 12.5734 4.0332C10.7071 3.89808 8.8539 4.43407 7.34785 5.54457C5.8418 6.65507 4.78196 8.26702 4.35938 10.0899H5.28937C5.68741 8.61145 6.55919 7.30431 7.77114 6.36871C8.98308 5.43311 10.4683 4.92066 11.9994 4.90987C13.7361 4.9459 15.403 5.60004 16.7007 6.75476C17.9984 7.90949 18.8418 9.48913 19.0794 11.2099L17.3594 9.70987L16.7894 10.4299L19.5994 12.8999L21.9994 9.99987L21.2994 9.44987Z"
      fill={color}
    />
  </svg>
);
