import styled from 'styled-components';
import { IButtonProps } from '../../../types/buttonTypes';
import { devices } from '../../../styles/viewports';

const ButtonWrapper = styled.a<IButtonProps>`
  padding: ${({ iconName }) => (iconName ? 'var(--button-padding-y-icon)' : 'var(--button-padding-y)')}
    var(--button-padding-x);
  height: var(--button-height);
  font-family: var(--font-family-buttons);
  font-size: var(--button-font-size);
  font-weight: var(--button-font-weight);
  color: var(--base-color-${({ color }) => color});
  line-height: var(--button-line-height);
  width: fit-content;
  border-style: solid;
  border-width: 1px;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;

  @media ${devices.mobile} {
    & {
      width: 100%;
    }
  }
`;

export default ButtonWrapper;
