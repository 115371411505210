import * as React from 'react';
import { IconSVGProps } from '../../../../../types/iconTypes';

export default ({ color = '#1A1A1A' }: IconSVGProps): React.ReactElement => (
  <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M22 5H2V6H22V5Z" fill={color} />
    <path d="M22 18H2V19H22V18Z" fill={color} />
    <path d="M22 11.5H2V12.5H22V11.5Z" fill={color} />
  </svg>
);
