import * as React from 'react';
import { IconSVGProps } from '../../../../../types/iconTypes';

export default ({ color = '#1A1A1A' }: IconSVGProps): React.ReactElement => (
  <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20.14 14H20C18.5808 13.8715 17.201 13.4637 15.94 12.8L15.67 12.66C15.5265 12.5796 15.3645 12.5382 15.2 12.54C14.9377 12.5411 14.6863 12.6453 14.5 12.83L12.9 14.43C11.4318 13.3789 10.1763 12.0589 9.2 10.54L10.85 8.89001C11.0287 8.70464 11.129 8.45748 11.13 8.20001C11.1278 8.08009 11.1041 7.96154 11.06 7.85001L10.87 7.45001C10.3893 6.35669 10.0952 5.19054 10 4.00001C9.99507 3.96686 9.99507 3.93316 10 3.90001C9.97513 3.65249 9.85889 3.42312 9.67398 3.25671C9.48907 3.09029 9.24877 2.99877 9 3.00001H4C3.73478 3.00001 3.48043 3.10537 3.29289 3.29291C3.10536 3.48044 3 3.7348 3 4.00001C3.24002 8.43074 5.10813 12.6167 8.24573 15.7543C11.3833 18.8919 15.5693 20.76 20 21C20.2652 21 20.5196 20.8947 20.7071 20.7071C20.8946 20.5196 21 20.2652 21 20V15C21.0024 14.7574 20.9165 14.5221 20.7582 14.3381C20.6 14.1541 20.3803 14.034 20.14 14ZM20 17V20C16.9863 19.8334 14.0721 18.8631 11.56 17.19C11.28 17 11.01 16.79 10.74 16.59C9.29429 15.4967 8.03183 14.1802 7 12.69C6.8 12.4 6.62 12.11 6.44 11.82C4.99691 9.45324 4.1589 6.76749 4 4.00001H9C9 4.34001 9.07 4.67001 9.13 5.00001C9.25673 5.76115 9.45769 6.50805 9.73 7.23001C9.85 7.54001 9.98 7.85001 10.12 8.15001L9.41 8.86001L8.62 9.64001L7.89 10.38C8.06 10.68 8.23 10.97 8.42 11.26C9.4178 12.7656 10.6778 14.0797 12.14 15.14C12.41 15.34 12.69 15.53 12.98 15.72L13.71 14.98L14.46 14.23L15.16 13.53L16.06 13.98C16.9924 14.3929 17.9737 14.6853 18.98 14.85C19.3 14.91 19.63 14.95 19.98 14.98V16.98L20 17Z"
      fill={color}
    />
  </svg>
);
