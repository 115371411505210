import styled from 'styled-components';
import { devices } from '../../../styles/viewports';
import { Grid } from '../../atoms/Grid';

export const PeopleListingGrid = styled(Grid)`
  gap: var(--line-height-people-listing-gap) var(--grid-column-gap);

  p {
    margin: 0;
  }

  grid-template-columns: 1fr 1fr 1fr;

  @media ${devices.mobile} {
    grid-template-columns: 1fr;
  }

  @media ${devices.tablet} {
    grid-template-columns: 1fr 1fr;
  }

  @media ${devices.largeDesktop} {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`;

export const PersonWrapper = styled.div`
  display: grid;
  grid-template-columns: 60px 1fr;
  grid-template-areas: 'headshot role-person';
  grid-column-gap: 10px;
`;

export const HeadshotWrapper = styled.div`
  display: flex;
  grid-area: headshot;
  img {
    width: 60px;
    height: 60px;
  }
`;

export const RoleWrapper = styled.div`
  color: var(--base-color-darkgrey);
  overflow-wrap: break-word;
  margin-bottom: 4px;
`;

export const TextWrapper = styled.div`
  grid-area: role-person;
`;

export const PersonLink = styled.a`
  color: var(--base-color-black);
  text-decoration: underline;

  :hover {
    color: var(--base-color-primary);
    :after {
      color: var(--base-color-black);
    }
  }

  :visited {
    color: var(--base-color-black);
  }
`;
