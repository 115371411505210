import styled from 'styled-components';
import { Grid } from '../../atoms/Grid';

export const SectionSplitterContainer = styled(Grid)`
  height: 4px;
`;
export const Splitter = styled.div`
  width: 100%;
  height: 4px;
  background: var(--base-color-lightgrey);
`;
