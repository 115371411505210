import styled, { css } from 'styled-components';
import { Grid } from '../../atoms/Grid';
import { IEditorialGridProps, IPromoWithTagsStyledProps } from '../../../types/editorial';
import { devices } from '../../../styles/viewports';
import TypeTags from '../../atoms/TypeTags/TypeTags';
import { Header, TextLink } from '../../atoms';

const LENGTH_TEXT = 28;
const LENGTH_TEXT_TABLET = 12;

const GridTemplateImageToLeft = `'left left left left left left left left . right right right right right . .'`;
const GridTemplateImageToRight = `'. . left left left left left . right right right right right right right right'`;
const GridTemplateAsCard = `'. . left left left left left right right right right right right right . . '`;
const GridTemplateAsCardTablet = `'. . left left left left left left left right right right right right . . '`;

export const PromoWithTagsGrid = styled(Grid)<IEditorialGridProps>`
  grid-template-areas: ${({ imageToLeft }) => (imageToLeft ? GridTemplateImageToLeft : GridTemplateImageToRight)};

  @media ${devices.tablet} {
    & {
      grid-template-areas: ${({ imageToLeft }) =>
        imageToLeft
          ? `'left left left left left left left right right right right right right .'`
          : `'. left left left left left left right right right right right right right'`};
    }
  }

  @media ${devices.mobile} {
    & {
      grid-template-columns: var(--grid-margin) minmax(0, 1fr) var(--grid-margin);
      grid-template-rows: min-content 24px min-content;
      gap: 0;
      grid-template-areas:
        'row1 row1 row1'
        '. . .'
        '. row2 .';
    }
  }

  /* Overwrite template for card */
  ${(asCard) =>
    asCard
      ? `
    grid-template-areas: ${GridTemplateAsCard};
    @media ${devices.tablet} {
      grid-template-areas: ${GridTemplateAsCardTablet};
    }
  `
      : ``}
`;

export const PromoWithTagsSubtitle = styled.span`
  font-family: var(--font-family-subtitle);
  font-feature-settings: var(--font-feature-settings-subtitle);
  font-size: var(--font-size-subtitle-1);
  font-weight: var(--font-weight-subtitle-1);
  line-height: var(--line-height-subtitle-1);
  letter-spacing: var(--letter-spacing-subtitle-1);
  text-transform: var(--text-transform-subtitle);
  margin-bottom: var(--editorial-subtitle-margin-bottom);
  display: block;
  overflow-wrap: break-word;
`;

export const PromoWithTagsText = styled.div`
  font-family: var(--font-family-body);
  font-feature-settings: var(--font-feature-settings-body);
  font-size: var(--font-size-body-1);
  font-weight: var(--font-weight-body-1);
  line-height: var(--line-height-body-1);
  letter-spacing: var(--letter-spacing-body-1);
  text-transform: var(--text-transform-body);
  margin: 0;
  padding: 0;
  white-space: break-spaces;
  overflow-wrap: break-word;

  h5,
  h6 {
    margin: 0 0 4px 0;
    font-family: var(--font-family-subtitle);
    font-feature-settings: var(--font-feature-settings-subtitle);
    font-size: var(--font-size-subtitle-2);
    font-weight: var(--font-weight-subtitle-2);
    line-height: var(--line-height-subtitle-2);
    letter-spacing: var(--letter-spacing-subtitle-2);
    text-transform: var(--text-transform-subtitle);
    overflow-wrap: break-word;
  }

  em {
    font-family: var(--font-family-body-italics);
  }

  a {
    text-decoration: underline;
    cursor: pointer;

    &[href^='tel:'] {
      text-decoration: none;
    }

    &:link {
      color: ${({ theme }) => theme.colors.black};
    }

    &:visited {
      color: ${({ theme }) => theme.colors.darkgrey};
    }

    &:hover {
      color: ${({ theme }) => theme.colors.primary};
    }
  }
`;

export const ButtonsContainer = styled.div<IEditorialGridProps>`
  display: flex;
  gap: 16px;
  margin-top: 24px;

  ${({ hasTextLinks }) => `margin-top: ${hasTextLinks ? '0' : '24px'};`};

  ${({ primaryButtonTextLength = 0, tertiaryButtonTextLength = 0 }) => {
    const isLinksLayoutColumn = primaryButtonTextLength >= LENGTH_TEXT || tertiaryButtonTextLength >= LENGTH_TEXT;

    if (isLinksLayoutColumn) {
      return `
        flex-direction: column;
      `;
    }
    return '';
  }}

  @media ${devices.mobile} {
    gap: 24px;
    flex-direction: column;
    align-items: center;
  }

  @media ${devices.tablet} {
    gap: 24px;
    align-items: flex-start;

    ${({ primaryButtonTextLength = 0, tertiaryButtonTextLength = 0 }) => {
      const isLinksLayoutColumnTablet =
        primaryButtonTextLength >= LENGTH_TEXT_TABLET || tertiaryButtonTextLength >= LENGTH_TEXT_TABLET;

      if (isLinksLayoutColumnTablet) {
        return `
          flex-direction: column;
        `;
      }
      return '';
    }}
  }
`;

export const PromoWithTagsTagWrapper = styled.div<IPromoWithTagsStyledProps>`
  margin-bottom: ${({ marginBottom }) => `${marginBottom}px`};
`;

export const PromoWithTagsAdditionalText = styled(PromoWithTagsText)`
  margin: 24px 0;
`;

export const TextLinksContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 24px 0 4px 0;
`;

export const TextLinkWrapper = styled.div`
  margin-right: 24px;
  margin-bottom: 20px;

  &:last-child {
    margin-right: 0;
  }
`;

export const ExtraContentWrapper = styled.div`
  position: relative;
  padding-right: 68px;

  iframe {
    height: 800px;
  }

  @media ${devices.tablet} {
    padding: 30px 0 0;
  }

  @media ${devices.mobile} {
    padding: 0;
    position: initial;
  }
`;

export const IconWrapper = styled.div`
  cursor: pointer;
  position: absolute;
  top: 25px;
  right: 35px;
  width: 24px;
  height: 24px;

  @media ${devices.tablet} {
    top: 30px;
    right: 30px;
  }

  @media ${devices.mobile} {
    top: 3px;
    right: 20px;
  }
`;

export const PrimaryButtonWrapper = styled.div`
  height: var(--button-height);
`;

export const MobileTitleWrapper = styled.div`
  display: none;
  margin: 0 50px 0 20px;

  h2,
  h3 {
    margin: 0;
  }

  @media ${devices.mobile} {
    display: block;
  }
`;

export const PromoWithTagsTypeTags = styled(TypeTags)``;
export const PromoWithTagsHeader = styled(Header)``;
export const PromoWithTagsTextLink = styled(TextLink)``;
const asCardOverrides = css`
  ${PromoWithTagsSubtitle} {
    font-size: var(--font-size-subtitle-2);
  }

  ${PromoWithTagsTypeTags} {
    font-size: var(--font-size-overline-1);
  }

  ${ButtonsContainer} {
    flex-direction: row;
    @media ${devices.tablet} {
      flex-direction: row;
    }
  }

  ${PromoWithTagsTextLink} {
    font-size: var(--font-size-overline-2);
    font-weight: var(--font-weight-overline-2);
    line-height: var(--line-height-overline-2);
    letter-spacing: var(--letter-spacing-overline-2);
  }
`;

export const PromoWithTagsExtraContentWrapper = styled.div<IEditorialGridProps>`
  position: relative;
  grid-area: left;
  grid-area: ${({ imageToLeft }) => (imageToLeft ? 'left' : 'right')};
  align-self: center;
  @media ${devices.mobile} {
    & {
      grid-area: row1;
    }
  }
`;

export const PromoWithTagsContentWrapper = styled.div<IEditorialGridProps>`
  grid-area: ${({ imageToLeft }) => (imageToLeft ? 'right' : 'left')};
  align-self: center;
  @media ${devices.mobile} {
    & {
      display: ${({ hideSection }) => (hideSection ? 'none' : 'block')};
      grid-area: row2;
      align-self: start;
    }
  }

  ${(asCard) => asCard && asCardOverrides}
`;

export const PromoWithTagsContainer = styled.div`
  h2,
  h3 {
    margin: 0 0 16px;
  }
`;
