import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const MorePages = styled.span`
  cursor: not-allowed;
  pointer-events: none;
`;

export const PageNumberWrapper = styled.ol`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 40px;
  flex-wrap: nowrap;
  flex-direction: row;
  list-style: none;
  padding: 0;
  max-width: fit-content;
  margin: auto;
  li {
    font-size: var(--font-size-subtitle-1);
    font-weight: var(--font-weight-body-1);
    line-height: var(--line-height-subtitle-1);
    letter-spacing: var(--letter-spacing-subtitle-1);
    margin-left: 16px;
    color: var(--base-color-dark-grey);
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0);
  }
`;

// PageNumber extends bodyText but uses subtitle-1 font size
export const PageNumber = styled(Link)<{ active?: string }>`
  color: inherit;
  margin: 0;
  border-bottom: 1px solid transparent;
  line-height: var(--base-line-height);
  text-decoration: none;
  font-family: var(--font-family-body-1);
  :hover {
    color: var(--base-color-core);
    border-bottom: 1px solid var(--base-color-core);
    svg > path {
      fill: var(--base-color-core);
    }
  }
  /* active state - string used because react returns error for bool */
  ${({ active }) =>
    active === 'true' &&
    ` 
      color: var(--base-color-core);
    `}
`;

export const NextPage = styled(PageNumber)`
  display: inline-flex;
  width: 25px;
  align-items: center;
  justify-content: center;
  margin-left: 0;
  span {
    height: 1.5em;
    svg > path {
      fill: var(--base-color-dark-grey);
    }
  }
`;
