import styled from 'styled-components';
import Button from '../Button';
import { IPrimaryButtonProps } from '../../../../types/buttonTypes';

const PrimaryButtonWrapper = styled(Button)<IPrimaryButtonProps>`
  background-color: ${({ bgColor, theme }) => (bgColor ? `var(--base-color-${bgColor})` : theme.colors.primary)};
  border-color: ${({ bgColor, theme }) => (bgColor ? `var(--base-color-${bgColor})` : theme.colors.primary)};
`;

export default PrimaryButtonWrapper;
