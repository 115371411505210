import * as React from 'react';
import { IconSVGProps } from '../../../../../types/iconTypes';

export default ({ color = '#1A1A1A' }: IconSVGProps): React.ReactElement => (
  <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11 4L2 12L11 20H22V4H11ZM21 19H11.4L3.5 12L11.4 5H21V19Z" fill={color} />
    <path
      d="M12.7 15.5L15.5 12.7L18.3 15.5L19 14.8L16.2 12L19 9.2L18.3 8.5L15.5 11.3L12.7 8.5L12 9.2L14.8 12L12 14.8L12.7 15.5Z"
      fill={color}
    />
  </svg>
);
