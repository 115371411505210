import styled from 'styled-components';

const ButtonIconWrapper = styled.span`
  width: var(--button-icon-width);
  height: var(--button-icon-height);
  margin-right: var(--button-icon-margin);
  display: inline-block;
`;

export default ButtonIconWrapper;
