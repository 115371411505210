export enum TickboxMode {
  Dark = 'dark',
  Light = 'light',
}

export interface ITickboxProps {
  /**
   * The text of tickbox
   */
  children: string;
  /**
   * Tickbox is checked or not initially
   */
  checked?: boolean;
  /**
   * Mode to determine colors of text and checkmark
   */
  mode?: TickboxMode;
  /**
   * Callback called when tickbox change
   */
  onChange?: (checked: boolean) => void;
  /**
   * Data label for tickbox
   */
  dataLabel?: string;
}

export interface TickboxLabelProps {
  /**
   * Dark mode or not
   */
  dark: boolean;
}

export interface TickboxInputProps {
  /**
   * Dark mode or not
   */
  dark: boolean;
}

export interface TickboxCheckmarkProps {
  /**
   * Dark mode or not
   */
  dark: boolean;
}
