import * as React from 'react';
import { IconSVGProps } from '../../../../../types/iconTypes';

export default ({ color = '#1A1A1A' }: IconSVGProps): React.ReactElement => (
  <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H14.3H15.3H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3ZM19 4C19.6 4 20 4.4 20 5V8.9H15.3V4H19ZM9.7 9.9H14.4V14.6H9.7V9.9ZM8.7 14.6H4V9.9H8.7V14.6ZM15.3 9.9H20V14.6H15.3V9.9ZM14.3 8.9H9.7V4H14.4V8.9H14.3ZM5 4H8.7V8.9H4V5C4 4.4 4.4 4 5 4ZM4 19V15.6H8.7V20H5C4.4 20 4 19.6 4 19ZM9.7 20V15.6H14.4V20H9.7ZM19 20H15.3V15.6H20V19C20 19.6 19.6 20 19 20Z"
      fill={color}
    />
  </svg>
);
