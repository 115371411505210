const zLevels = [
  'base',
  'content',
  'contentOverlay',
  'sponsorship',
  'anchor',
  'searchOverlay',
  'navigation',
  'search',
  'menu',
  'overlay',
  'popup',
];

const zIndexes: { [level: string]: number } = {};

zLevels.forEach((name, index) => {
  zIndexes[name] = index;
});

export default zIndexes;
