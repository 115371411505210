import styled from 'styled-components';
import { devices } from '../../../styles/viewports';

export const TextContainer = styled.div`
  font-size: var(--font-size-body-1);
  font-family: var(--font-family-body);
  font-feature-settings: var(--font-feature-settings-body);
  font-weight: var(--font-weight-body-1);
  letter-spacing: var(--letter-spacing-body-1);
  line-height: var(--line-height-body-1);
  text-transform: var(--text-transform-body);

  & h1,
  h2,
  h3,
  h4 {
    font-size: var(--font-size-header-4);
    font-family: var(--font-family-header);
    font-feature-settings: var(--font-feature-settings-header);
    font-weight: var(--font-weight-header-4);
    letter-spacing: var(--letter-spacing-header-4);
    line-height: var(--line-height-header-4);
    text-transform: var(--text-transform-header);
    white-space: break-spaces;
    overflow-wrap: break-word;
    margin: 80px 0 16px;

    @media ${devices.mobileAndTablet} {
      margin-top: 60px;
    }

    + p {
      margin-top: 0px;
    }
  }

  & h5,
  h6 {
    font-size: var(--font-size-subtitle-1);
    font-family: var(--font-family-subtitle);
    font-feature-settings: var(--font-feature-settings-subtitle);
    font-weight: var(--font-weight-subtitle-1);
    letter-spacing: var(--letter-spacing-subtitle-1);
    line-height: var(--line-height-subtitle-1);
    text-transform: var(--text-transform-subtitle);
    white-space: break-spaces;
    overflow-wrap: break-word;
    margin: 80px 0 16px;

    @media ${devices.mobileAndTablet} {
      margin-top: 60px;
    }

    + p {
      margin-top: 10px;
    }
  }

  & a {
    color: ${({ theme }) => theme.colors.primary};
    text-decoration: underline;
    cursor: pointer;
  }

  p {
    margin: 30px 0;
  }

  & em {
    font-family: var(--font-family-body-italics);
  }

  & strong {
    font-weight: bold;
  }

  & u {
    font-size: var(--font-size-body-1);
    font-family: var(--font-family-body);
  }

  & ul {
    padding: 0;
    list-style: none;
    margin: 0 0 0 30px;

    li {
      text-indent: -24px;
      line-height: var(--line-height-listing);
    }
  }

  & ol {
    padding: 0;
    margin: 0 0 0 20px;

    li {
      line-height: var(--line-height-listing);
    }
  }

  & ul > li:before {
    display: inline-block;
    content: '—';
    width: 0px;
    margin-left: 20px;
  }
`;
