import * as React from 'react';
import { IconSVGProps } from '../../../../../types/iconTypes';

export default ({ color = '#1A1A1A' }: IconSVGProps): React.ReactElement => (
  <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9 3H8V8H3V9H9V3Z" fill={color} />
    <path d="M16 16H21V15H15V21H16V16Z" fill={color} />
    <path d="M21 9V8H16V3H15V9H21Z" fill={color} />
    <path d="M8 21H9V15H3V16H8V21Z" fill={color} />
  </svg>
);
