import styled from 'styled-components';
import { devices } from '../../../styles/viewports';
import { TickboxLabelProps, TickboxInputProps, TickboxCheckmarkProps } from '../../../types/tickbox';

export const TickboxLabel = styled.label<TickboxLabelProps>`
  display: block;
  width: fit-content;
  position: relative;
  padding-left: 32px;
  color: var(--base-color-${({ dark }) => (dark ? 'white' : 'black')});
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  @media ${devices.mobileAndTablet} {
    &:focus {
      outline: 0;
    }
  }
`;

export const TickboxCheckmark = styled.span<TickboxCheckmarkProps>`
  position: absolute;
  top: 1px;
  left: 0;
  height: 20px;
  width: 20px;
  padding: 3px;
  box-sizing: border-box;
  border: 1px solid var(--base-color-${({ dark }) => (dark ? 'white' : 'black')});

  svg {
    display: none;
  }

  @media ${devices.mobile} {
    top: 0;
  }
`;

export const TickboxInput = styled.input<TickboxInputProps>`
  display: none;

  &:checked ~ ${TickboxCheckmark} {
    background-color: var(--base-color-${({ dark }) => (dark ? 'white' : 'black')});
    & svg {
      display: block;
      path {
        fill: var(--base-color-${({ dark }) => (dark ? 'black' : 'white')});
      }
    }
  }
`;
