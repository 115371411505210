import styled from 'styled-components';

const Grid = styled.div`
  display: grid;
  grid-template-columns: var(--grid-template-columns);
  grid-template-rows: max-content;
  gap: var(--grid-column-gap);
  margin: 0;
`;

export default Grid;
