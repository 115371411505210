import * as React from 'react';
import { IconSVGProps } from '../../../../../types/iconTypes';

export default ({ color = '#1A1A1A' }: IconSVGProps): React.ReactElement => (
  <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.6 4.24658L21.3536 12.0001L13.6 19.7537L12.5464 18.7001L18.3964 12.8501H2.75V11.3501H18.5964L12.5464 5.30014L13.6 4.24658Z"
      fill={color}
    />
  </svg>
);
