import styled from 'styled-components';
import { devices } from '../../../styles/viewports';
import { IContactCardWrapperProps } from '../../../types/contactCard';

export const ContentWrapper = styled.div`
  & > div {
    margin-bottom: 2px;

    &:first-child {
      margin-bottom: 4px;

      @media ${devices.mobile} {
        margin-bottom: 6px;
      }
    }
  }

  @media ${devices.mobileAndTablet} {
    grid-column: 2 / span 12;
  }
`;

export const Wrapper = styled.div<IContactCardWrapperProps>`
  ${({ hideBottomBorder }) => `border-bottom: ${hideBottomBorder ? 'none' : '2px solid var(--base-color-midgrey)'};`}
  ${({ hideTopBorder }) => `border-top: ${hideTopBorder ? 'none' : '2px solid var(--base-color-midgrey)'};`}
  padding: 30px 0;

  @media ${devices.mobileAndTablet} {
    display: grid;
    grid-template-columns: var(--grid-template-columns);
    grid-template-rows: max-content;
    gap: var(--grid-column-gap);
    margin: 0;
  }
`;

export const AddressWrapperDesktop = styled.div`
  @media ${devices.mobile} {
    display: none;
  }
`;

export const AddressWrapperMobile = styled.div`
  display: none;

  & > div {
    margin-bottom: 2px;
  }

  @media ${devices.mobile} {
    display: block;
  }
`;

export const DetailsWrapper = styled.div`
  display: flex;

  @media ${devices.mobile} {
    flex-direction: column;
    margin: 4px 0;
  }

  a {
    font-size: var(--font-size-body-1);
    font-family: var(--font-family-body);
    font-feature-settings: var(--font-feature-settings-body);
    font-weight: var(--font-weight-body-1);
    letter-spacing: var(--letter-spacing-body-1);
    line-height: var(--line-height-body-1);
    text-transform: var(--text-transform-body);
    color: var(--base-color-black);
    text-decoration: underline;

    &:before {
      display: inline-block;
      content: '';
      background-color: var(--base-color-black);
      width: 1px;
      height: 18px;
      margin: 0 8px -4px;

      @media ${devices.mobile} {
        display: none;
      }
    }

    &:first-child:before {
      display: none;
    }
  }
`;
