import { createGlobalStyle, GlobalStyleComponent } from 'styled-components';
import { devices } from './viewports';

const GlobalStyles: GlobalStyleComponent<any, any> = createGlobalStyle`
  html {
    --base-color-primary: ${({ theme }) => theme.colors.primary};
    --base-color-core: ${({ theme }) => theme.colors.core};
    --base-color-stream: ${({ theme }) => theme.colors.stream};
    --base-color-cinema: ${({ theme }) => theme.colors.cinema};
    --base-color-white: ${({ theme }) => theme.colors.white};
    --base-color-black: ${({ theme }) => theme.colors.black};
    --base-color-dark-grey: ${({ theme }) => theme.colors.darkgrey};
    --base-color-mid-grey: ${({ theme }) => theme.colors.midgrey};
    --base-color-light-grey: ${({ theme }) => theme.colors.lightgrey};
    --error-color-state: ${({ theme }) => theme.colors.error};
    --medium-color-state: ${({ theme }) => theme.colors.medium};
    --good-color-state: ${({ theme }) => theme.colors.good};
    --base-color-darkgrey: ${({ theme }) => theme.colors.darkgrey};
    --base-color-midgrey: ${({ theme }) => theme.colors.midgrey};
    --base-color-lightgrey: ${({ theme }) => theme.colors.lightgrey};
    --base-color-transparent: transparent;
    --base-color-errorstate: ${({ theme }) => theme.colors.error};
    --base-color-mediumstate: ${({ theme }) => theme.colors.medium};
    --base-color-goodstate: ${({ theme }) => theme.colors.good};
    --base-color-progress: ${({ theme }) => theme.colors.progress};
    --base-color-navigation: ${({ theme }) => theme.colors.navigation};

    --button-height: ${({ theme }) => theme.spacing[12]};
    --button-line-height: ${({ theme }) => theme.fonts.desktop.lineHeights.buttons};
    --button-padding-x: ${({ theme }) => theme.buttons.paddingX};
    --button-padding-y: ${({ theme }) => theme.buttons.paddingY};
    --button-padding-y-icon: ${({ theme }) => theme.buttons.paddingYIcon};
    --button-icon-margin: ${({ theme }) => theme.buttons.iconMargin};
    --button-icon-width: ${({ theme }) => theme.buttons.iconWidth};
    --button-icon-height: ${({ theme }) => theme.buttons.iconHeight};
    --button-font-size: ${({ theme }) => theme.fonts.desktop.sizes.buttons};
    --button-font-weight: ${({ theme }) => theme.fonts.desktop.weights.buttons};

    --font-size-header-1: ${({ theme }) => theme.fonts.desktop.sizes.headers[1]};
    --font-weight-header-1: ${({ theme }) => theme.fonts.desktop.weights.headers[1]};
    --letter-spacing-header-1: ${({ theme }) => theme.fonts.desktop.letterSpacing.headers[1]};
    --line-height-header-1: ${({ theme }) => theme.fonts.desktop.lineHeights.headers[1]};
    --margin-header-1: ${({ theme }) => theme.fonts.desktop.margins.headers[1]};

    --font-size-header-2: ${({ theme }) => theme.fonts.desktop.sizes.headers[2]};
    --font-weight-header-2: ${({ theme }) => theme.fonts.desktop.weights.headers[2]};
    --letter-spacing-header-2: ${({ theme }) => theme.fonts.desktop.letterSpacing.headers[2]};
    --line-height-header-2: ${({ theme }) => theme.fonts.desktop.lineHeights.headers[2]};
    --margin-header-2: ${({ theme }) => theme.fonts.desktop.margins.headers[2]};

    --font-size-header-3: ${({ theme }) => theme.fonts.desktop.sizes.headers[3]};
    --font-weight-header-3: ${({ theme }) => theme.fonts.desktop.weights.headers[3]};
    --letter-spacing-header-3: ${({ theme }) => theme.fonts.desktop.letterSpacing.headers[3]};
    --line-height-header-3: ${({ theme }) => theme.fonts.desktop.lineHeights.headers[3]};
    --margin-header-3: ${({ theme }) => theme.fonts.desktop.margins.headers[3]};

    --font-size-header-4: ${({ theme }) => theme.fonts.desktop.sizes.headers[4]};
    --font-weight-header-4: ${({ theme }) => theme.fonts.desktop.weights.headers[4]};
    --letter-spacing-header-4: ${({ theme }) => theme.fonts.desktop.letterSpacing.headers[4]};
    --line-height-header-4: ${({ theme }) => theme.fonts.desktop.lineHeights.headers[4]};
    --margin-header-4: ${({ theme }) => theme.fonts.desktop.margins.headers[4]};

    --font-size-header-5: ${({ theme }) => theme.fonts.desktop.sizes.headers[5]};
    --font-weight-header-5: ${({ theme }) => theme.fonts.desktop.weights.headers[5]};
    --letter-spacing-header-5: ${({ theme }) => theme.fonts.desktop.letterSpacing.headers[5]};
    --line-height-header-5: ${({ theme }) => theme.fonts.desktop.lineHeights.headers[5]};
    --margin-header-5: ${({ theme }) => theme.fonts.desktop.margins.headers[5]};

    --font-size-header-6: ${({ theme }) => theme.fonts.desktop.sizes.headers[6]};
    --font-weight-header-6: ${({ theme }) => theme.fonts.desktop.weights.headers[6]};
    --letter-spacing-header-6: ${({ theme }) => theme.fonts.desktop.letterSpacing.headers[6]};
    --line-height-header-6: ${({ theme }) => theme.fonts.desktop.lineHeights.headers[6]};
    --margin-header-6: ${({ theme }) => theme.fonts.desktop.margins.headers[6]};

    --font-size-altHeader-3: ${({ theme }) => theme.fonts.desktop.sizes.altHeaders[3]};
    --font-weight-altHeader-3: ${({ theme }) => theme.fonts.desktop.weights.altHeaders[3]};
    --letter-spacing-altHeader-3: ${({ theme }) => theme.fonts.desktop.letterSpacing.altHeaders[3]};
    --line-height-altHeader-3: ${({ theme }) => theme.fonts.desktop.lineHeights.altHeaders[3]};

    --font-size-altHeader-4: ${({ theme }) => theme.fonts.desktop.sizes.altHeaders[4]};
    --font-weight-altHeader-4: ${({ theme }) => theme.fonts.desktop.weights.altHeaders[4]};
    --letter-spacing-altHeader-4: ${({ theme }) => theme.fonts.desktop.letterSpacing.altHeaders[4]};
    --line-height-altHeader-4: ${({ theme }) => theme.fonts.desktop.lineHeights.altHeaders[4]};

    --font-size-altHeader-5: ${({ theme }) => theme.fonts.desktop.sizes.altHeaders[5]};
    --font-weight-altHeader-5: ${({ theme }) => theme.fonts.desktop.weights.altHeaders[5]};
    --letter-spacing-altHeader-5: ${({ theme }) => theme.fonts.desktop.letterSpacing.altHeaders[5]};
    --line-height-altHeader-5: ${({ theme }) => theme.fonts.desktop.lineHeights.altHeaders[5]};

    --font-size-altHeader-6: ${({ theme }) => theme.fonts.desktop.sizes.altHeaders[6]};
    --font-weight-altHeader-6: ${({ theme }) => theme.fonts.desktop.weights.altHeaders[6]};
    --letter-spacing-altHeader-6: ${({ theme }) => theme.fonts.desktop.letterSpacing.altHeaders[6]};
    --line-height-altHeader-6: ${({ theme }) => theme.fonts.desktop.lineHeights.altHeaders[6]};

    --font-size-overline-1: ${({ theme }) => theme.fonts.desktop.sizes.overline[1]};
    --font-weight-overline-1: ${({ theme }) => theme.fonts.desktop.weights.overline[1]};
    --letter-spacing-overline-1: ${({ theme }) => theme.fonts.desktop.letterSpacing.overline[1]};
    --line-height-overline-1: ${({ theme }) => theme.fonts.desktop.lineHeights.overline[1]};

    --font-size-overline-2: ${({ theme }) => theme.fonts.desktop.sizes.overline[2]};
    --font-weight-overline-2: ${({ theme }) => theme.fonts.desktop.weights.overline[2]};
    --letter-spacing-overline-2: ${({ theme }) => theme.fonts.desktop.letterSpacing.overline[2]};
    --line-height-overline-2: ${({ theme }) => theme.fonts.desktop.lineHeights.overline[2]};

    --font-size-body-1: ${({ theme }) => theme.fonts.desktop.sizes.body[1]};
    --font-weight-body-1: ${({ theme }) => theme.fonts.desktop.weights.body[1]};
    --letter-spacing-body-1: ${({ theme }) => theme.fonts.desktop.letterSpacing.body[1]};
    --line-height-body-1: ${({ theme }) => theme.fonts.desktop.lineHeights.body[1]};

    --font-size-body-2: ${({ theme }) => theme.fonts.desktop.sizes.body[2]};
    --font-weight-body-2: ${({ theme }) => theme.fonts.desktop.weights.body[2]};
    --letter-spacing-body-2: ${({ theme }) => theme.fonts.desktop.letterSpacing.body[2]};
    --line-height-body-2: ${({ theme }) => theme.fonts.desktop.lineHeights.body[2]};

    --font-size-body-3: ${({ theme }) => theme.fonts.desktop.sizes.body[3]};
    --font-weight-body-3: ${({ theme }) => theme.fonts.desktop.weights.body[3]};
    --letter-spacing-body-3: ${({ theme }) => theme.fonts.desktop.letterSpacing.body[3]};
    --line-height-body-3: ${({ theme }) => theme.fonts.desktop.lineHeights.body[3]};

    --font-size-subtitle-1: ${({ theme }) => theme.fonts.desktop.sizes.subtitles[1]};
    --font-weight-subtitle-1: ${({ theme }) => theme.fonts.desktop.weights.subtitles[1]};
    --letter-spacing-subtitle-1: ${({ theme }) => theme.fonts.desktop.letterSpacing.subtitles[1]};
    --line-height-subtitle-1: ${({ theme }) => theme.fonts.desktop.lineHeights.subtitles[1]};
    
    --font-size-subtitle-2: ${({ theme }) => theme.fonts.desktop.sizes.subtitles[2]};
    --font-weight-subtitle-2: ${({ theme }) => theme.fonts.desktop.weights.subtitles[2]};
    --letter-spacing-subtitle-2: ${({ theme }) => theme.fonts.desktop.letterSpacing.subtitles[2]};
    --line-height-subtitle-2: ${({ theme }) => theme.fonts.desktop.lineHeights.subtitles[2]};

    --font-size-title-description: ${({ theme }) => theme.fonts.desktop.sizes.titleDescription};
    --line-height-title-description: ${({ theme }) => theme.fonts.desktop.lineHeights.titleDescription};
    --line-height-listing: ${({ theme }) => theme.fonts.desktop.lineHeights.listing};
    --font-size-individual-listing-name: ${({ theme }) => theme.fonts.desktop.sizes.listing};
    --line-height-individual-listing-name: ${({ theme }) => theme.fonts.desktop.lineHeights.peopleListing};
    --line-height-people-listing-gap: ${({ theme }) => theme.spacing[6]};

    --font-family-header: ${({ theme }) => theme.fonts.desktop.families.headers};
    --font-family-altHeader: ${({ theme }) => theme.fonts.desktop.families.altHeaders};
    --font-family-body: ${({ theme }) => theme.fonts.desktop.families.body};
    --font-family-body-italics: ${({ theme }) => theme.fonts.desktop.families.bodyItalics};
    --font-family-buttons: ${({ theme }) => theme.fonts.desktop.families.buttons};
    --font-family-overline: ${({ theme }) => theme.fonts.desktop.families.overline};
    --font-family-subtitle: ${({ theme }) => theme.fonts.desktop.families.subtitles};
    --font-family-people-listing-role: ${({ theme }) => theme.fonts.desktop.families.peopleListingRole};
    --font-family-people-listing-name: ${({ theme }) => theme.fonts.desktop.families.body};

    --font-feature-settings-header: ${({ theme }) => theme.fonts.desktop.fontFeatureSettings.headers};
    --font-feature-settings-altHeader: ${({ theme }) => theme.fonts.desktop.fontFeatureSettings.altHeaders};
    --font-feature-settings-overline: ${({ theme }) => theme.fonts.desktop.fontFeatureSettings.overline};
    --font-feature-settings-subtitle: ${({ theme }) => theme.fonts.desktop.fontFeatureSettings.subtitles};
    --font-feature-settings-body: ${({ theme }) => theme.fonts.desktop.fontFeatureSettings.body};
    --font-feature-settings-navigation: ${({ theme }) => theme.fonts.desktop.fontFeatureSettings.navigation};

    --text-transform-header: ${({ theme }) => theme.fonts.desktop.transforms.headers};
    --text-transform-altHeader: ${({ theme }) => theme.fonts.desktop.transforms.altHeaders};
    --text-transform-body: ${({ theme }) => theme.fonts.desktop.transforms.body};
    --text-transform-overline: ${({ theme }) => theme.fonts.desktop.transforms.overline};
    --text-transform-subtitle ${({ theme }) => theme.fonts.desktop.transforms.subtitles};

    --word-break-header: ${({ theme }) => theme.fonts.desktop.wordBreak.headers};
    --word-break-altHeader: ${({ theme }) => theme.fonts.desktop.wordBreak.altHeaders};
    --word-break-body: ${({ theme }) => theme.fonts.desktop.wordBreak.body};
    --word-break-overline: ${({ theme }) => theme.fonts.desktop.wordBreak.overline};
    --word-break-subtitle: ${({ theme }) => theme.fonts.desktop.wordBreak.subtitles};

    --font-family-navigation: ${({ theme }) => theme.fonts.desktop.families.navigation};
    --font-size-navigation: ${({ theme }) => theme.fonts.desktop.sizes.navigation};
    --font-weight-navigation: ${({ theme }) => theme.fonts.desktop.weights.navigation};
    --line-height-navigation: ${({ theme }) => theme.fonts.desktop.lineHeights.navigation};
    --text-transform-navigation: ${({ theme }) => theme.fonts.desktop.transforms.navigation};
    --letter-spacing-navigation: ${({ theme }) => theme.fonts.desktop.letterSpacing.navigation};

    --navigation-small-gap: ${({ theme }) => theme.spacing[4]};
    --navigation-middle-gap: ${({ theme }) => theme.spacing[5]};
    --navigation-large-gap: ${({ theme }) => theme.spacing[6]};
    --navigation-xlarge-gap: ${({ theme }) => theme.spacing[9]};
    --navigation-large-margin: ${({ theme }) => theme.spacing[15]};

    --rotator-button-width: ${({ theme }) => theme.spacing[15]};
    --rotator-button-icon-width: ${({ theme }) => theme.spacing[9]};

    --font-size-search: ${({ theme }) => theme.fonts.desktop.sizes.search};
    --font-family-search: ${({ theme }) => theme.fonts.desktop.families.search};

    --grid-margin: ${({ theme }) => theme.grid.desktop.margin};
    --grid-outer-margin: ${({ theme }) => theme.grid.desktop.outerMargin};
    --grid-template-columns: calc(${({ theme }) => theme.grid.desktop.outerMargin} - ${({ theme }) =>
  theme.grid.desktop.gap}) calc(${({ theme }) => theme.grid.desktop.margin} - ${({ theme }) =>
  theme.grid.desktop.outerMargin} - ${({ theme }) => theme.grid.desktop.gap}) repeat(12, minmax(0,1fr)) calc(${({
  theme,
}) => theme.grid.desktop.margin} - ${({ theme }) => theme.grid.desktop.outerMargin} - ${({ theme }) =>
  theme.grid.desktop.gap}) calc(${({ theme }) => theme.grid.desktop.outerMargin} - ${({ theme }) =>
  theme.grid.desktop.gap});
    --grid-column-gap: ${({ theme }) => theme.grid.desktop.gap};
    --grid-margin: 0;

    --footer-height: ${({ theme }) => theme.footer.desktop.height};
    --footer-padding-top: ${({ theme }) => theme.footer.desktop.paddingTop};
    --footer-padding-bottom: ${({ theme }) => theme.footer.desktop.paddingBottom};
    --footer-items-gap: ${({ theme }) => theme.footer.desktop.itemsGap};
    --footer-media-gap: ${({ theme }) => theme.footer.desktop.mediaGap};
    --footer-media-icon-width: ${({ theme }) => theme.footer.desktop.mediaIconWidth};
    --footer-media-icon-height: ${({ theme }) => theme.footer.desktop.mediaIconHeight};
    --footer-vertical-spacing-lg: ${({ theme }) => theme.footer.desktop.verticalSpacingLarge};
    --footer-vertical-spacing-sm: ${({ theme }) => theme.footer.desktop.verticalSpacingSmall};

    --text-link-underline-offset: ${({ theme }) => theme.spacing[2]};

    --cards-spacing-stack: ${({ theme }) => theme.spacing[10]};

    --editorial-subtitle-margin-bottom: ${({ theme }) => theme.spacing[4]};
    --editorial-margin-between: ${({ theme }) => theme.spacing[6]};
    --editorial-spacing-hover: ${({ theme }) => theme.spacing[1]};
    --anchor-tabs-height: 70px;
    

    @media ${devices.mobile} {

      --font-size-header-1: ${({ theme }) => theme.fonts.mobile.sizes.headers[1]};
      --font-weight-header-1: ${({ theme }) => theme.fonts.mobile.weights.headers[1]};
      --letter-spacing-header-1: ${({ theme }) => theme.fonts.mobile.letterSpacing.headers[1]};
      --line-height-header-1: ${({ theme }) => theme.fonts.mobile.lineHeights.headers[1]};
  
      --font-size-header-2: ${({ theme }) => theme.fonts.mobile.sizes.headers[2]};
      --font-weight-header-2: ${({ theme }) => theme.fonts.mobile.weights.headers[2]};
      --letter-spacing-header-2: ${({ theme }) => theme.fonts.mobile.letterSpacing.headers[2]};
      --line-height-header-2: ${({ theme }) => theme.fonts.mobile.lineHeights.headers[2]};
  
      --font-size-header-3: ${({ theme }) => theme.fonts.mobile.sizes.headers[3]};
      --font-weight-header-3: ${({ theme }) => theme.fonts.mobile.weights.headers[3]};
      --letter-spacing-header-3: ${({ theme }) => theme.fonts.mobile.letterSpacing.headers[3]};
      --line-height-header-3: ${({ theme }) => theme.fonts.mobile.lineHeights.headers[3]};
  
      --font-size-header-4: ${({ theme }) => theme.fonts.mobile.sizes.headers[4]};
      --font-weight-header-4: ${({ theme }) => theme.fonts.mobile.weights.headers[4]};
      --letter-spacing-header-4: ${({ theme }) => theme.fonts.mobile.letterSpacing.headers[4]};
      --line-height-header-4: ${({ theme }) => theme.fonts.mobile.lineHeights.headers[4]};
  
      --font-size-header-5: ${({ theme }) => theme.fonts.mobile.sizes.headers[5]};
      --font-weight-header-5: ${({ theme }) => theme.fonts.mobile.weights.headers[5]};
      --letter-spacing-header-5: ${({ theme }) => theme.fonts.mobile.letterSpacing.headers[5]};
      --line-height-header-5: ${({ theme }) => theme.fonts.mobile.lineHeights.headers[5]};
  
      --font-size-header-6: ${({ theme }) => theme.fonts.mobile.sizes.headers[6]};
      --font-weight-header-6: ${({ theme }) => theme.fonts.mobile.weights.headers[6]};
      --letter-spacing-header-6: ${({ theme }) => theme.fonts.mobile.letterSpacing.headers[6]};
      --line-height-header-6: ${({ theme }) => theme.fonts.mobile.lineHeights.headers[6]};
  
      --font-size-altHeader-3: ${({ theme }) => theme.fonts.mobile.sizes.altHeaders[3]};
      --font-weight-altHeader-3: ${({ theme }) => theme.fonts.mobile.weights.altHeaders[3]};
      --letter-spacing-altHeader-3: ${({ theme }) => theme.fonts.mobile.letterSpacing.altHeaders[3]};
      --line-height-altHeader-3: ${({ theme }) => theme.fonts.mobile.lineHeights.altHeaders[3]};
  
      --font-size-altHeader-4: ${({ theme }) => theme.fonts.mobile.sizes.altHeaders[4]};
      --font-weight-altHeader-4: ${({ theme }) => theme.fonts.mobile.weights.altHeaders[4]};
      --letter-spacing-altHeader-4: ${({ theme }) => theme.fonts.mobile.letterSpacing.altHeaders[4]};
      --line-height-altHeader-4: ${({ theme }) => theme.fonts.mobile.lineHeights.altHeaders[4]};
  
      --font-size-altHeader-5: ${({ theme }) => theme.fonts.mobile.sizes.altHeaders[5]};
      --font-weight-altHeader-5: ${({ theme }) => theme.fonts.mobile.weights.altHeaders[5]};
      --letter-spacing-altHeader-5: ${({ theme }) => theme.fonts.mobile.letterSpacing.altHeaders[5]};
      --line-height-altHeader-5: ${({ theme }) => theme.fonts.mobile.lineHeights.altHeaders[5]};
  
      --font-size-altHeader-6: ${({ theme }) => theme.fonts.mobile.sizes.altHeaders[6]};
      --font-weight-altHeader-6: ${({ theme }) => theme.fonts.mobile.weights.altHeaders[6]};
      --letter-spacing-altHeader-6: ${({ theme }) => theme.fonts.mobile.letterSpacing.altHeaders[6]};
      --line-height-altHeader-6: ${({ theme }) => theme.fonts.mobile.lineHeights.altHeaders[6]};
  
      --font-size-overline-1: ${({ theme }) => theme.fonts.mobile.sizes.overline[1]};
      --font-weight-overline-1: ${({ theme }) => theme.fonts.mobile.weights.overline[1]};
      --letter-spacing-overline-1: ${({ theme }) => theme.fonts.mobile.letterSpacing.overline[1]};
      --line-height-overline-1: ${({ theme }) => theme.fonts.mobile.lineHeights.overline[1]};
  
      --font-size-overline-2: ${({ theme }) => theme.fonts.mobile.sizes.overline[2]};
      --font-weight-overline-2: ${({ theme }) => theme.fonts.mobile.weights.overline[2]};
      --letter-spacing-overline-2: ${({ theme }) => theme.fonts.mobile.letterSpacing.overline[2]};
      --line-height-overline-2: ${({ theme }) => theme.fonts.mobile.lineHeights.overline[2]};
  
      --font-size-body-1: ${({ theme }) => theme.fonts.mobile.sizes.body[1]};
      --font-weight-body-1: ${({ theme }) => theme.fonts.mobile.weights.body[1]};
      --letter-spacing-body-1: ${({ theme }) => theme.fonts.mobile.letterSpacing.body[1]};
      --line-height-body-1: ${({ theme }) => theme.fonts.mobile.lineHeights.body[1]};
  
      --font-size-body-2: ${({ theme }) => theme.fonts.mobile.sizes.body[2]};
      --font-weight-body-2: ${({ theme }) => theme.fonts.mobile.weights.body[2]};
      --letter-spacing-body-2: ${({ theme }) => theme.fonts.mobile.letterSpacing.body[2]};
      --line-height-body-2: ${({ theme }) => theme.fonts.mobile.lineHeights.body[2]};
  
      --font-size-body-3: ${({ theme }) => theme.fonts.mobile.sizes.body[3]};
      --font-weight-body-3: ${({ theme }) => theme.fonts.mobile.weights.body[3]};
      --letter-spacing-body-3: ${({ theme }) => theme.fonts.mobile.letterSpacing.body[3]};
      --line-height-body-3: ${({ theme }) => theme.fonts.mobile.lineHeights.body[3]};
  
      --font-size-subtitle-1: ${({ theme }) => theme.fonts.mobile.sizes.subtitles[1]};
      --font-weight-subtitle-1: ${({ theme }) => theme.fonts.mobile.weights.subtitles[1]};
      --letter-spacing-subtitle-1: ${({ theme }) => theme.fonts.mobile.letterSpacing.subtitles[1]};
      --line-height-subtitle-1: ${({ theme }) => theme.fonts.mobile.lineHeights.subtitles[1]};
      
      --font-size-subtitle-2: ${({ theme }) => theme.fonts.mobile.sizes.subtitles[2]};
      --font-weight-subtitle-2: ${({ theme }) => theme.fonts.mobile.weights.subtitles[2]};
      --letter-spacing-subtitle-2: ${({ theme }) => theme.fonts.mobile.letterSpacing.subtitles[2]};
      --line-height-subtitle-2: ${({ theme }) => theme.fonts.mobile.lineHeights.subtitles[2]};

      --font-size-title-description: ${({ theme }) => theme.fonts.mobile.sizes.titleDescription};
      --line-height-title-description: ${({ theme }) => theme.fonts.mobile.lineHeights.titleDescription};
      --line-height-people-listing-gap: ${({ theme }) => theme.spacing[4]};

      --font-size-search: ${({ theme }) => theme.fonts.mobile.sizes.search};
      --font-family-search: ${({ theme }) => theme.fonts.mobile.families.search};

      --font-family-header: ${({ theme }) => theme.fonts.mobile.families.headers};
      --font-family-altHeader: ${({ theme }) => theme.fonts.mobile.families.altHeaders};
      --font-family-body: ${({ theme }) => theme.fonts.mobile.families.body};
      --font-family-body-italics: ${({ theme }) => theme.fonts.mobile.families.bodyItalics};
      --font-family-buttons: ${({ theme }) => theme.fonts.mobile.families.buttons};
      --font-family-overline: ${({ theme }) => theme.fonts.mobile.families.overline};
      --font-family-subtitle: ${({ theme }) => theme.fonts.mobile.families.subtitles};
      --font-family-people-listing-role: ${({ theme }) => theme.fonts.mobile.families.peopleListingRole};
      --font-family-people-listing-name: ${({ theme }) => theme.fonts.mobile.families.body};
  
      --font-feature-settings-header: ${({ theme }) => theme.fonts.mobile.fontFeatureSettings.headers};
      --font-feature-settings-altHeader: ${({ theme }) => theme.fonts.mobile.fontFeatureSettings.altHeaders};
      --font-feature-settings-overline: ${({ theme }) => theme.fonts.mobile.fontFeatureSettings.overline};
      --font-feature-settings-subtitle: ${({ theme }) => theme.fonts.mobile.fontFeatureSettings.subtitles};
      --font-feature-settings-body: ${({ theme }) => theme.fonts.mobile.fontFeatureSettings.body};
      --font-feature-settings-navigation: ${({ theme }) => theme.fonts.mobile.fontFeatureSettings.navigation};
  
      --text-transform-header: ${({ theme }) => theme.fonts.mobile.transforms.headers};
      --text-transform-altHeader: ${({ theme }) => theme.fonts.mobile.transforms.altHeaders};
      --text-transform-body: ${({ theme }) => theme.fonts.mobile.transforms.body};
      --text-transform-overline: ${({ theme }) => theme.fonts.mobile.transforms.overline};
      --text-transform-subtitle ${({ theme }) => theme.fonts.mobile.transforms.subtitles};
  
      --word-break-header: ${({ theme }) => theme.fonts.mobile.wordBreak.headers};
      --word-break-altHeader: ${({ theme }) => theme.fonts.mobile.wordBreak.altHeaders};
      --word-break-body: ${({ theme }) => theme.fonts.mobile.wordBreak.body};
      --word-break-overline: ${({ theme }) => theme.fonts.mobile.wordBreak.overline};
      --word-break-subtitle: ${({ theme }) => theme.fonts.mobile.wordBreak.subtitles};
  
      --grid-template-columns: calc(${({ theme }) => theme.grid.mobile.margin} - ${({ theme }) =>
  theme.grid.mobile.gap}) repeat(12, minmax(0, 1fr)) calc(${({ theme }) => theme.grid.mobile.margin} - ${({ theme }) =>
  theme.grid.mobile.gap});
      --grid-column-gap: ${({ theme }) => theme.grid.mobile.gap};
      --grid-margin: ${({ theme }) => theme.grid.mobile.margin};

      --footer-height: ${({ theme }) => theme.footer.mobile.height};
      --footer-padding-top: ${({ theme }) => theme.footer.mobile.paddingTop};
      --footer-padding-bottom: ${({ theme }) => theme.footer.mobile.paddingBottom};
      --footer-items-gap: ${({ theme }) => theme.footer.mobile.itemsGap};
      --footer-media-gap: ${({ theme }) => theme.footer.mobile.mediaGap};
      --footer-media-icon-width: ${({ theme }) => theme.footer.mobile.mediaIconWidth};
      --footer-media-icon-height: ${({ theme }) => theme.footer.mobile.mediaIconHeight};
      --footer-vertical-spacing-lg: ${({ theme }) => theme.footer.mobile.verticalSpacingLarge};
      --footer-vertical-spacing-sm: ${({ theme }) => theme.footer.mobile.verticalSpacingSmall};

      --anchor-tabs-height: 60px;
      --rotator-button-width: ${({ theme }) => theme.spacing[10]};
      --rotator-button-icon-width: ${({ theme }) => theme.spacing[6]};
    }

    @media ${devices.tablet} {
      --grid-template-columns: calc(${({ theme }) => theme.grid.tablet.margin} - ${({ theme }) =>
  theme.grid.tablet.gap}) repeat(12, minmax(0, 1fr)) calc(${({ theme }) => theme.grid.tablet.margin} - ${({ theme }) =>
  theme.grid.tablet.gap});
      --grid-column-gap: ${({ theme }) => theme.grid.tablet.gap};
      --grid-margin: ${({ theme }) => theme.grid.tablet.margin};
      --rotator-button-width: ${({ theme }) => theme.spacing[10]};
      --rotator-button-icon-width: ${({ theme }) => theme.spacing[6]};

      --font-size-header-6: ${({ theme }) => theme.fonts.tablet.sizes.headers[6]};

      --font-size-altHeader-4: ${({ theme }) => theme.fonts.tablet.sizes.altHeaders[4]};

      --font-size-body-1: ${({ theme }) => theme.fonts.tablet.sizes.body[1]};
    }

    @media ${devices.desktop} {
      
    }

    @media ${devices.largeDesktop} {
      
    }
  }
`;

export default GlobalStyles;
